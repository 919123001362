import React, { useContext, useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';

//MuiIcons

//MuiComponents
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

//Assets

// import planBuildService from '../../../../services/planBuild-service';

//Our components
// import { thisUserContext } from '../../nav-container/NavContainer';
// import OurDataGrid from '../../DataGrid/OurDataGrid';
import InfinityLoader from '../../../UH-loading-animation/InfinityLoader';

export default function WindowRowGraph(props) {
  let plan = props.plan;
  let windows = props.windows;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        padding: '10px',
        position: 'relative',
      }}
    >
      {windows.map &&
        windows.map((window, index) => {
          const startDate = new Date(window.OpenEnrollmentStart);
          const endDate = new Date(window.OpenEnrollmentEnd);
          const nextWindow = windows[index + 1];
          const nextStartDate = nextWindow
            ? new Date(nextWindow.OpenEnrollmentStart)
            : null;

          const overlap = nextStartDate && endDate > nextStartDate;
          const totalDuration =
            (new Date(windows[windows.length - 1].OpenEnrollmentEnd) -
              new Date(windows[0].OpenEnrollmentStart)) /
            (1000 * 60 * 60 * 24);
          const overlapWidth = overlap
            ? ((endDate - nextStartDate) / (endDate - startDate)) * 100
            : 0;

          return (
            <Tooltip
              key={index}
              title={
                !overlap
                  ? `${window.OpenEnrollmentStart} - ${window.OpenEnrollmentEnd}`
                  : `this window ends ${
                      window.OpenEnrollmentEnd
                    } and overlaps with the next window starting ${
                      nextWindow.OpenEnrollmentStart
                    } by ${
                      Math.floor(
                        (nextStartDate - endDate) / (1000 * 60 * 60 * 24)
                      ) * -1
                    } days`
              }
              followCursor
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: `${
                    ((startDate - new Date(windows[0].OpenEnrollmentStart)) /
                      (1000 * 60 * 60 * 24) /
                      totalDuration) *
                    100
                  }%`,
                  height: '20px',
                  // width: !overlap
                  //   ? `${
                  //       ((endDate - startDate) /
                  //         (1000 * 60 * 60 * 24) /
                  //         totalDuration) *
                  //       100
                  //     }%`
                  //   : `${
                  //       ((nextStartDate - startDate) /
                  //         (1000 * 60 * 60 * 24) /
                  //         totalDuration) *
                  //       100
                  //     }%`,
                  width: `${
                    ((endDate - startDate) /
                      (1000 * 60 * 60 * 24) /
                      totalDuration) *
                    100
                  }%`,
                  display: 'flex',
                  top: !overlap ? '0px' : '-10px',
                }}
              >
                <Box
                  sx={{
                    backgroundColor: 'lightblue',
                    height: '100%',
                    width: `${100 - overlapWidth}%`,
                    border: '1px solid black',
                    borderRight: overlap ? 'none' : '1px solid black',
                    opacity: 0.8,
                    zIndex: 3,
                  }}
                />
                {overlap && (
                  <Box
                    sx={{
                      backgroundColor: 'lightcoral',
                      height: '100%',
                      width: `${overlapWidth}%`,
                      border: '1px solid black',
                      borderLeft: 'none',
                      opacity: 0.8,
                      zIndex: 4,
                    }}
                  />
                )}
              </Box>
            </Tooltip>
          );
        })}
    </Box>
  );
}
