import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//Mui Components
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Typography } from '@mui/material';

//Our Components
import PendingChangesWarning from './PendingChangesWarning';
import PropTypes from 'prop-types';
import CancelAcLine from './CancelAcLine';
//utils
import { formatUTCToShortDate } from '../../../../../../../utils';

//shared styles -- moved most styles over
import acDrawerSharedStyles from './acDrawerSharedStyles';

export default function ViewAcLine({
  plan, // array of line(s) of coverage
  onClose,
  handleModificationTypeChange,
  findSingleLineDisplayName,
  ...props
}) {
  const { id: memberId } = useParams();
  const [hasPendingWarning, setHasPendingWarning] = useState(false);
  const plans = Array.isArray(plan) ? plan : plan ? [plan] : [];
  const planData = plans[0];
  const { lineOfCoverage } = planData || {};

  //Checks if theres a pending warning
  useEffect(() => {
    setHasPendingWarning(
      !!lineOfCoverage?.futureActiveCoverageId || !!lineOfCoverage?.isCancelled
    );
  }, [lineOfCoverage]);

  if (!plan || plan.length === 0) {
    return <Typography>No plan data available</Typography>;
  }

  const isLaddPlan = Array.isArray(plan)
    ? plan.some((item) =>
        item.lineOfCoverage?.displayName?.toLowerCase().includes('ladd')
      )
    : plan?.lineOfCoverage?.displayName?.toLowerCase().includes('ladd');

  return (
    <Box sx={acDrawerSharedStyles.componentContainer}>
      {/* All other content */}
      <Typography variant="h6" sx={acDrawerSharedStyles.sectiontitleView}>
        {isLaddPlan ? 'Manage Coverage' : 'Member Coverage'}
        {/* noticed that if plan is cancelled pencil is there for a brief moment needs to disapear in that case -- fix */}
        <Box
          component="span"
          onClick={() =>
            !hasPendingWarning && props?.allowModify && handleModificationTypeChange('edit')
          }
          sx={{
            ...acDrawerSharedStyles.editIconView,
            color: hasPendingWarning
              ? 'text.disabled'
              : acDrawerSharedStyles.editIconView.color,
            cursor: hasPendingWarning
              ? 'default'
              : acDrawerSharedStyles.editIconView.cursor,
            pointerEvents: hasPendingWarning ? 'none' : 'auto',
            '&:hover': {
              color: hasPendingWarning
                ? 'text.disabled'
                : acDrawerSharedStyles.editIconView['&:hover'].color,
            },
          }}
        >
          <EditIcon />
        </Box>
      </Typography>

      <Box sx={acDrawerSharedStyles.pendingChangesView}>
        <PendingChangesWarning
          plan={plan}
          memberId={memberId || 'N/A'}
          planId={lineOfCoverage?.planId || 'N/A'}
          displayName={lineOfCoverage?.displayName || 'Unknown'}
          onModifyClick={() => handleModificationTypeChange('edit')}
          onCancelChanges={() => console.log('Cancel clicked')} //need to think on this logic more - likely disable for this sprint MVP
          handleModificationTypeChange={handleModificationTypeChange}
          findSingleLineDisplayName={findSingleLineDisplayName}
          allowModify={props?.allowModify}
        />
      </Box>

      <Box sx={acDrawerSharedStyles.bodyContainer}>
        {isLaddPlan ? (
          <LaddandCiPlanDetails
            plan={plan}
            findSingleLineDisplayName={findSingleLineDisplayName}
          />
        ) : (
          <OtherPlansDetails
            plan={plan}
            findSingleLineDisplayName={findSingleLineDisplayName}
          />
        )}
      </Box>

      {/* Cancel Button */}
      <Box sx={acDrawerSharedStyles.buttonContainerCancel}>
        <Button
          variant="outlined"
          color="error"
          onClick={() => handleModificationTypeChange('cancel')}
          sx={acDrawerSharedStyles.cancelButtonStyle}
          disabled={plan[0]?.lineOfCoverage?.isCancelled || !props?.allowModify}
        >
          Cancel Line of Coverage
        </Button>
      </Box>
    </Box>
  );
}

// Component for LADD and CI Plan Details
function LaddandCiPlanDetails({ plan, findSingleLineDisplayName }) {
  if (!Array.isArray(plan) || plan.length === 0) return null;

  const sortedPlan = [...plan].sort((a, b) => {
    const order = { Member: 0, Spouse: 1, Dependent: 2 };
    const aPriority =
      order[
        findSingleLineDisplayName(a?.lineOfCoverage?.displayName || '').trim()
      ] ?? 3;
    const bPriority =
      order[
        findSingleLineDisplayName(b?.lineOfCoverage?.displayName || '').trim()
      ] ?? 3;
    return aPriority - bPriority;
  });

  // Calculate overall effective date and premium
  const overallEffectiveDate = sortedPlan
    .map((coverage) => new Date(coverage?.lineOfCoverage?.effectiveStartDate))
    .sort((a, b) => a - b)[0];
  const formattedEffectiveDate = overallEffectiveDate
    ? formatUTCToShortDate(overallEffectiveDate.toISOString())
    : 'N/A';

  const overallTotalPremium = sortedPlan.reduce(
    (total, coverage) => total + (coverage?.lineOfCoverage?.premiumAmount || 0),
    0
  );

  return (
    <Box>
      {/* Benefit Amount Section */}
      <Box sx={acDrawerSharedStyles.laddbenefitAmountWrapper}>
        <Typography sx={acDrawerSharedStyles.labelView}>
          Benefit Amount
        </Typography>
        <Box sx={acDrawerSharedStyles.laddbenefitNameView}>
          {sortedPlan.map((coverage, index) => {
            const lineOfCoverage = coverage?.lineOfCoverage || {};
            const displayName = findSingleLineDisplayName(
              lineOfCoverage?.displayName || 'N/A'
            );
            const benefitAmount = lineOfCoverage?.benefitAmount
              ? `$${lineOfCoverage.benefitAmount.toLocaleString()}`
              : 'N/A';
            const monthlyCost = lineOfCoverage?.premiumAmount
              ? `$${lineOfCoverage.premiumAmount.toFixed(2)}`
              : 'N/A';

            return (
              <Typography
                key={index}
                sx={acDrawerSharedStyles.laddbenefitAmountView}
              >
                <Box sx={{ flex: 1 }}>{displayName}</Box>
                <Box sx={{ textAlign: 'right' }}>
                  - {benefitAmount} - {monthlyCost}
                </Box>
              </Typography>
            );
          })}
        </Box>
      </Box>

      {/* Overall Effective Date and Total Premium */}
      <Box sx={acDrawerSharedStyles.effectiveDateView}>
        <Typography sx={acDrawerSharedStyles.labelView}>
          Effective Date: {formattedEffectiveDate}
        </Typography>
        <Box sx={acDrawerSharedStyles.priceContainer}>
          <Typography sx={acDrawerSharedStyles.priceView}>
            ${overallTotalPremium.toFixed(2)}
          </Typography>
          <Typography sx={acDrawerSharedStyles.perMonthText}>
            / per month
          </Typography>
        </Box>
      </Box>
      {/* Future Sprint: Beneficiary Details */}
      {/* <BeneficiaryDetails plan={plan} /> */}
    </Box>
  );
}

// Component for Other Plans Details LTD, STD, Cancer, ADD, Accident etc.
function OtherPlansDetails({ plan, findSingleLineDisplayName }) {
  // Ensure valid data exists
  if (!plan || (!plan.lineOfCoverage && !Array.isArray(plan))) return null;
  const plans = Array.isArray(plan) ? plan : [plan];

  return (
    <>
      {plans.map((coverage, index) => (
        <Box key={index} sx={{ marginBottom: '20px' }}>
          {/* Benefit Amount */}
          {coverage?.lineOfCoverage?.benefitAmount > 0 && (
            <Box sx={acDrawerSharedStyles.memberinfolinesView}>
              <Typography sx={acDrawerSharedStyles.labelView}>
                Benefit Amount
              </Typography>
              <Typography sx={acDrawerSharedStyles.valueView}>
                {coverage?.lineOfCoverage?.displayName === 'LTD'
                  ? `$${coverage.lineOfCoverage.benefitAmount.toLocaleString(
                      undefined,
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )} monthly`
                  : coverage?.lineOfCoverage?.displayName === 'STD'
                  ? `$${coverage.lineOfCoverage.benefitAmount.toLocaleString(
                      undefined,
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )} weekly`
                  : `$${coverage.lineOfCoverage.benefitAmount.toLocaleString(
                      undefined,
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}`}
              </Typography>
            </Box>
          )}

          {/* Elimination Period */}
          {coverage?.lineOfCoverage?.eliminationPeriod > 0 && (
            <Box sx={acDrawerSharedStyles.memberinfolinesView}>
              <Typography sx={acDrawerSharedStyles.labelView}>
                Elimination Period
              </Typography>
              <Typography sx={acDrawerSharedStyles.valueView}>
                {coverage.lineOfCoverage.eliminationPeriod}{' '}
                {coverage.lineOfCoverage.eliminationPeriodTypeFriendly ||
                  'days'}
              </Typography>
            </Box>
          )}

          {/* Benefit Duration */}
          {coverage?.lineOfCoverage?.benefitDuration > 0 && (
            <Box sx={acDrawerSharedStyles.memberinfolinesView}>
              <Typography sx={acDrawerSharedStyles.labelView}>
                Benefit Duration
              </Typography>
              <Typography sx={acDrawerSharedStyles.valueView}>
                {coverage.lineOfCoverage.benefitDuration}{' '}
                {coverage.lineOfCoverage.benefitDurationTypeFriendly || 'years'}
              </Typography>
            </Box>
          )}

          {/* Offset */}
          {coverage?.lineOfCoverage?.benefitOffsets &&
            coverage.lineOfCoverage.benefitOffsets.trim() !== '' && (
              <Box sx={acDrawerSharedStyles.offsetBox}>
                <Typography sx={acDrawerSharedStyles.offsetLabel}>
                  Offset
                </Typography>
                <Typography sx={acDrawerSharedStyles.offsetValue}>
                  {coverage.lineOfCoverage.benefitOffsets}
                </Typography>
              </Box>
            )}

          {/* Covered Lives */}
          {coverage?.lineOfCoverage?.displayName?.includes('LADD') ||
          coverage?.lineOfCoverage?.displayName?.includes('CI') ? (
            <Box sx={acDrawerSharedStyles.memberinfolinesView}>
              <Typography sx={acDrawerSharedStyles.labelView}>
                Covered Lives
              </Typography>
              <Typography sx={acDrawerSharedStyles.valueView}>
                {findSingleLineDisplayName(coverage.lineOfCoverage.displayName)}
              </Typography>
            </Box>
          ) : (
            coverage?.lineOfCoverage?.personType &&
            coverage.lineOfCoverage.personType.trim() &&
            coverage.lineOfCoverage.displayName !== 'LTD' &&
            coverage.lineOfCoverage.displayName !== 'STD' && (
              <Box sx={acDrawerSharedStyles.memberinfolinesView}>
                <Typography sx={acDrawerSharedStyles.labelView}>
                  Covered Lives
                </Typography>
                <Typography sx={acDrawerSharedStyles.valueView}>
                  {coverage.lineOfCoverage.personType}
                </Typography>
              </Box>
            )
          )}

          {/* Effective Date & Monthly Cost */}
          {coverage?.lineOfCoverage?.effectiveStartDate &&
            coverage?.lineOfCoverage?.premiumAmount > 0 && (
              <Box sx={acDrawerSharedStyles.effectiveDateView}>
                <Typography sx={acDrawerSharedStyles.labelView}>
                  Effective Date:{' '}
                  {formatUTCToShortDate(
                    coverage.lineOfCoverage.effectiveStartDate
                  )}
                </Typography>
                <Box sx={acDrawerSharedStyles.priceContainer}>
                  <Typography sx={acDrawerSharedStyles.priceView}>
                    ${coverage.lineOfCoverage.premiumAmount.toFixed(2)}
                  </Typography>
                  <Typography sx={acDrawerSharedStyles.perMonthText}>
                    / per month
                  </Typography>
                </Box>
              </Box>
            )}
        </Box>
      ))}
    </>
  );
}

// Prop Validations
ViewAcLine.propTypes = {
  plan: PropTypes.shape({
    lineOfCoverage: PropTypes.shape({
      benefitAmount: PropTypes.number,
      benefitOffsets: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      benefitDuration: PropTypes.number,
      benefitDurationTypeFriendly: PropTypes.string,
      eliminationPeriod: PropTypes.number,
      eliminationPeriodTypeFriendly: PropTypes.string,
      effectiveStartDate: PropTypes.string,
      premiumAmount: PropTypes.number,
      personType: PropTypes.string,
    }).isRequired,
    primaryBeneficiaries: PropTypes.arrayOf(
      PropTypes.shape({
        percentage: PropTypes.number,
        name: PropTypes.string,
        relationship: PropTypes.string,
      })
    ),
    contingentBeneficiaries: PropTypes.arrayOf(
      PropTypes.shape({
        percentage: PropTypes.number,
        name: PropTypes.string,
        relationship: PropTypes.string,
      })
    ),
  }).isRequired,
  onClose: PropTypes.func,
  handleModificationTypeChange: PropTypes.func.isRequired,
  findSingleLineDisplayName: PropTypes.func.isRequired,
};
