import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//MuiIcons

//MuiComponents
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//Assets

import planBuildService from '../../../../services/planBuild-service';

//Our components
import { thisUserContext } from '../../nav-container/NavContainer';
// import OurDataGrid from '../../DataGrid/OurDataGrid';
import InfinityLoader from '../../../UH-loading-animation/InfinityLoader';
import WindowRow from './WindowRow';

export default function WindowManagement(props) {
  let history = useHistory();

  let myContext = useContext(thisUserContext);
  const [thisUser, setThisUser] = useState();
  const [permissions, setPermissions] = useState();

  let getThisUser = async () => {
    setThisUser(myContext.thisUser);
    setPermissions(myContext?.permissionsEnabler);
  };

  useEffect(() => {
    if (myContext?.thisUser && thisUser == null) getThisUser();
  }, [myContext?.thisUser]);

  const [groupPlans, setGroupPlans] = useState([]);
  const getGroupPlans = async () => {
    const results = await planBuildService.getAllGroupPlans();
    if (results) {
      // let parsedResults = {...results}
      // for (var result in results){
      //   parsedResults[result].enrollmentWindows = JSON.parse(parsedResults[result].enrollmentWindows)
      // }
      // parsedResults.forEach((result) => {
      //   result.enrollmentWindows = JSON.parse(result.enrollmentWindows)
      // });
      //   enrollmentWindows: JSON.parse(results.enrollmentWindows),
      // }
      setGroupPlans(results);
    }
  };
  useEffect(() => {
    getGroupPlans();
  }, []);

  // const [groupPlanRows, setGroupPlanRows] = useState([]);
  // const buildGroupPlanRows = async () => {
  //   let _rows = [];
  //   try {
  //     // const results = await planBuildService.getAllGroupPlans();
  //     if (groupPlans?.length > 0) {
  //       // setGroupPlans(groupPlans);
  //       groupPlans.forEach((resultObj) => {
  //         let { id, subdomain, title, isEnrollmentOpen, description } =
  //           resultObj;
  //         let builtRow = {
  //           id: resultObj?.id,
  //           title: resultObj?.name,
  //           subdomain: resultObj?.subdomain,
  //           isEnrollmentOpen: resultObj?.isEnrollmentOpen,
  //           description: resultObj?.description,
  //         };
  //         _rows.push(builtRow);
  //       });
  //       setGroupPlanRows(_rows);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(async () => {
  //   if (groupPlans?.length > 0) buildGroupPlanRows();
  // }, [groupPlans?.length]);

  // const groupPlanColumns = [
  //   {
  //     field: 'id',
  //     headerName: 'ID',
  //     flex: 20,
  //     renderCell: (params) => {
  //       return <div style={{ display: 'block' }}>{params.value}</div>;
  //     },
  //   },
  //   {
  //     field: 'title',
  //     headerName: 'Title',
  //     flex: 100,
  //     // valueGetter: (params) => {
  //     //    return params.row.enrollmentId
  //     // },
  //     renderCell: (params) => {
  //       return <div style={{ display: 'block' }}>{params.value}</div>;
  //     },
  //   },
  //   {
  //     field: 'subdomain',
  //     headerName: 'Enrollment URL',
  //     flex: 100,
  //     headerClassName: 'hide-right-sep',
  //     renderCell: (params) => {
  //       return (
  //         params.value && <div>https://{params.value}.unionuhb.com/enroll</div>
  //       );
  //     },
  //   },
  //   {
  //     field: 'isEnrollmentOpen',
  //     headerName: 'Enrollment Status',
  //     flex: 100,
  //     renderCell: (params) => {
  //       return (
  //         <Chip
  //           sx={{
  //             backgroundColor: !params.value
  //               ? 'action.selected'
  //               : 'background.activeWorking',
  //             color: !params.value ? 'text.primary' : 'primary.activeChip',
  //             fontWeight: '500',
  //             fontFamily: 'Archivo',
  //             fontSize: '.9rem',
  //           }}
  //           label={
  //             !params.value ? 'Not In Open Enrollment' : 'In Open Enrollment'
  //           }
  //         />
  //       );
  //     },
  //   },
  // ];

  // //THIS OVERRIDES THE NO ROWS DATAGRID COMPONENT
  // function NoGroupPlanRows(props) {
  //   return (
  //     <div style={{ backgroundColor: '', height: '100%', textAlign: 'center' }}>
  //       <Box
  //         // sx={{ backgroundColor: 'primary.light' }}
  //         style={{
  //           margin: '54px auto 13px',
  //           width: '58px',
  //           height: '58px',
  //           borderRadius: 100,
  //           display: 'flex',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //         }}
  //       ></Box>
  //       <InfinityLoader
  //         style={{
  //           zIndex: 5,
  //           height: '41px',
  //         }}
  //       />
  //       {/* <div style={{ marginBottom: '19px' }}>No Rows!</div> */}
  //     </div>
  //   );
  // }
  // //THIS OVERRIDES THE NO RESULTS DATAGRID COMPONENT
  // function NoGroupPlanResults(props) {
  //   return (
  //     <div
  //       style={{
  //         backgroundColor: '',
  //         height: '100%',
  //         textAlign: 'center',
  //         zIndex: 5,
  //       }}
  //     >
  //       <Box
  //         sx={{ backgroundColor: 'primary.light' }}
  //         style={{
  //           margin: '54px auto 13px',
  //           width: '58px',
  //           height: '58px',
  //           borderRadius: 100,
  //           display: 'flex',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //         }}
  //       >
  //         No Results!
  //       </Box>
  //     </div>
  //   );
  // }

  // //   Drawer Logic
  // //NEW MEMBER DRAWER
  // const [drawerOpen, setDrawerOpen] = useState(false);

  // const handleRowClick = (e) => {
  //   history.push(`/planmanagement/${e.id}`, { id: e.id });
  // };

  return (
    <>
      <Box
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
          borderColor: 'background.border',
        }}
        style={{ height: 'calc(100vh - 56px)', padding: '20px 40px' }}
      >
        <Typography variant="h4" paddingBottom="10px">
          Window Management
        </Typography>
        <Box sx={{ overflowY: 'scroll', height: 'calc(100% - 90px)' }}>
          {groupPlans?.length > 0 &&
            groupPlans.map((plan) => (
              <WindowRow
                {...props}
                plan={plan}
                enrollmentWindows={plan.enrollmentWindows}
              />
            ))}
        </Box>
      </Box>
    </>
  );
}
