import React, { useState, useEffect } from 'react';

//services
import activeCoverageService from '../../../../../../../../services/activeCoverage-service';

// Assets

//Mui Components
import {
  Alert,
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

//Mui icons

//Our Components
import EditMultipleAcLinesSection from './EditMultipleAcLinesSection';
import EditCoverageEffectiveDateStepper from '../EditCoverageEffectiveDateStepper';

//utils
import {
  formatCurrency,
  formatUTCToShortDate,
} from '../../../../../../../utils';

//shared styles
import acDrawerSharedStyles from './acDrawerSharedStyles';

//component styles
const componentStyles = {
  offsetRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0 0 0 ',
  },
  offsetLabel: {
    fontWeight: 600,
  },
  offsetDescription: {
    margin: '0 0 0 8px',
  },
};

export default function EditAcLine(props) {
  //local state of coverage line(s) selected for cancellation - adding additional property to each line to be able to filter out the lines that are not selected for cancellation in instances where more than one line is displayed

  /////////////// SINGLE LINE EDIT LOGIC //////////////////////////
  const [lineToEdit, setLineToEdit] = useState([]);
  useEffect(() => {
    if (props?.activeCoverageDrawerOpen?.lineSelected?.length > 0) {
      //add editInfo object to line selected to track changes
      let _updatedLinesSelected =
        props?.activeCoverageDrawerOpen?.lineSelected.map((line) => {
          return {
            ...line,
            editInfo: {
              isElected: true,
              planOptionId: line?.lineOfCoverage?.planOptionId,
              benefitAmount: line?.lineOfCoverage?.benefitAmount,
              premiumAmount: line?.lineOfCoverage?.premiumAmount,
              isUpdated: false,
              benefitDuration: line?.lineOfCoverage?.benefitDuration,
              eliminationPeriod: line?.lineOfCoverage?.eliminationPeriod,
              personTypeId: line?.lineOfCoverage?.personTypeId,
              activeCoverageId: line?.lineOfCoverage?.id,
            },
          };
        });
      setLineToEdit(_updatedLinesSelected);
    }
  }, [props?.activeCoverageDrawerOpen?.lineSelected]);

  //handle edit general coverage info
  const handleEditCoverageInfo = (e, type) => {
    let planOptionId = e.target.value;
    let selectedMatchingOption = lineToEdit[0]?.options?.options.find(
      (option) => option.memberOptionId == planOptionId
    );
    //update state
    let _updatedCoverage = lineToEdit.map((line) => {
      return {
        ...line,
        editInfo: {
          ...line.editInfo,
          planOptionId: planOptionId,
          benefitAmount: selectedMatchingOption?.benefitAmount,
          premiumAmount: selectedMatchingOption?.memberPremiumAmount,
          isUpdated: true,
          benefitDuration: selectedMatchingOption?.benefitDuration,
          eliminationPeriod: selectedMatchingOption?.eliminationPeriod,
          personTypeId: selectedMatchingOption?.personTypeId,
        },
      };
    });
    setLineToEdit(_updatedCoverage);
  };

  //update edit date - commented out for now as we are only allowing edits to go into effect at the start of the next month
  const handleEditDateChange = (e) => {
    let date = e.target.value;
    props?.setActiveCoverageDrawerOpen((prevState) => ({
      ...prevState,
      dateOfEdit: date,
    }));
  };

  const checkIfLineIsAccAddCanOrHosp = () => {
    let _isAccAddCanOrHosp = false;
    lineToEdit?.forEach((line) => {
      if (
        line?.lineOfCoverage?.calculatedPlanTypeId &&
        (line?.lineOfCoverage?.calculatedPlanTypeId === 3 ||
          line?.lineOfCoverage?.calculatedPlanTypeId === 7 ||
          line?.lineOfCoverage?.calculatedPlanTypeId === 8 ||
          line?.lineOfCoverage?.calculatedPlanTypeId === 9)
      ) {
        _isAccAddCanOrHosp = true;
      }
    });
    setIsAccAddCanOrHosp(_isAccAddCanOrHosp);
  };

  const [isAccAddCanOrHosp, setIsAccAddCanOrHosp] = useState(false);

  useEffect(() => {
    if (lineToEdit?.length > 0) checkIfLineIsAccAddCanOrHosp();
  }, [lineToEdit[0]?.lineOfCoverage?.calculatedPlanTypeId]);

  //// ELIMINATION PERIOD & BENEFIT DURATION EDIT LOGIC - some plans will have more than one option for these /////

  const [eliminationPeriodTypes, setEliminationPeriodTypes] = useState([]);
  const [benefitDurTypes, setBenefitDurTypes] = useState([]);
  //selected elimination period
  const [selectedEliminationPeriod, setSelectedEliminationPeriod] =
    useState(null);
  // selected benefit duration
  const [selectedBenefitDuration, setSelectedBenefitDuration] = useState();

  //get only unique values of elimination period from member options excluding duplicates and 0
  const getUniqueEliminationPeriodList = () => {
    let elimPeriodInfo = [];
    for (let option of lineToEdit[0]?.options?.options) {
      if (option?.eliminationPeriod && option?.eliminationPeriod > 0) {
        let object = {
          eliminationPeriod: option.eliminationPeriod,
          type: option.eliminationPeriodType,
          typeId: option.eliminationPeriodTypeId,
        };
        elimPeriodInfo.push(object);
      }
    }

    let uniqueElimPeriodArr = [
      ...new Set(elimPeriodInfo.map((obj) => obj.eliminationPeriod)),
    ];

    let uniqueElimPeriodOptions = uniqueElimPeriodArr.map(
      (eliminationPeriod) => {
        return elimPeriodInfo.find(
          (obj) => obj.eliminationPeriod === eliminationPeriod
        );
      }
    );

    setEliminationPeriodTypes(uniqueElimPeriodOptions);
    setSelectedEliminationPeriod(uniqueElimPeriodOptions[0]?.eliminationPeriod);
  };

  // get only unique values of benefit duration from member options excluding duplicates and 0
  const getUniqueBenefitDurationList = () => {
    let benefitDurationInfo = [];

    for (let option of lineToEdit[0]?.options?.options) {
      if (option?.benefitDuration > 0) {
        let object = {
          duration: option.benefitDuration,
          type: option.benefitDurationType,
          typeId: option.benefitDurationTypeId,
        };
        benefitDurationInfo.push(object);
      }
    }

    let uniqueBenefitDurList = [
      ...new Set(benefitDurationInfo.map((obj) => obj.duration)),
    ];

    let uniqueBenefitDurOptions = uniqueBenefitDurList.map((duration) => {
      return benefitDurationInfo.find((obj) => obj.duration === duration);
    });

    //set member benefit duration options off of unique options only
    setBenefitDurTypes(uniqueBenefitDurOptions);
  };

  //only get benefit duration and elimination period options if non-ladd or ci plan
  useEffect(() => {
    if (
      lineToEdit[0]?.options?.options &&
      !lineToEdit[0]?.lineOfCoverage?.displayName?.includes('LADD' || 'CI')
    ) {
      getUniqueEliminationPeriodList();
      getUniqueBenefitDurationList();
    }
  }, [lineToEdit[0]?.options]);

  //   //EDIT benefit duration or elimination period for incremental plans

  const handleEditBenefitDuration = (e) => {
    let value = e.target.value;
    let selectedMatchingOption = lineToEdit[0]?.options?.options.find(
      (option) => option.benefitDuration == value
    );
    if (selectedMatchingOption) {
      let _updatedCoverage = lineToEdit?.map((line) => {
        return {
          ...line,
          editInfo: {
            ...line.editInfo,
            planOptionId: selectedMatchingOption.memberOptionId,
            benefitAmount: selectedMatchingOption.benefitAmount,
            premiumAmount: selectedMatchingOption.memberPremiumAmount,
            isUpdated: true,
            benefitDuration: value,
            eliminationPeriod: selectedMatchingOption.eliminationPeriod,
            personTypeId: selectedMatchingOption.personTypeId,
          },
        };
      });
      setLineToEdit(_updatedCoverage);
    }
  };

  const handleEditEliminationPeriod = (e) => {
    let value = e.target.value;
    let selectedMatchingOption = lineToEdit[0]?.options?.options.find(
      (option) => option.eliminationPeriod == value
    );
    let _updatedCoverage = lineToEdit.map((line) => {
      return {
        ...line,
        editInfo: {
          ...line.editInfo,
          planOptionId: selectedMatchingOption.memberOptionId,
          benefitAmount: selectedMatchingOption.benefitAmount,
          premiumAmount: selectedMatchingOption.memberPremiumAmount,
          isUpdated: true,
          benefitDuration: selectedMatchingOption.eliminationPeriod,
          eliminationPeriod: value,
          personTypeId: selectedMatchingOption.personTypeId,
        },
      };
    });
    setLineToEdit(_updatedCoverage);
  };

  const getCoveredLivesAdditionalInfo = (planOptionId) => {
    let memberIsSelected;
    let memberAndSpouseIsSelected;
    let memberAndDependentsIsSelected;
    let familyIsSelected;
    let _selectedOption;

    for (let option of lineToEdit[0]?.options?.options) {
      if (option?.memberOptionId == planOptionId) {
        memberIsSelected = true;
      }
      if (option?.memberAndSpouseOptionId == planOptionId)
        memberAndSpouseIsSelected = true;
      if (option?.memberAndDependentsOptionId == planOptionId)
        memberAndDependentsIsSelected = true;
      if (option?.familyOptionId == planOptionId) familyIsSelected = true;
      //find selected option from true statement(s) above and set - should only be a single option selected
      if (
        memberIsSelected ||
        memberAndSpouseIsSelected ||
        memberAndDependentsIsSelected ||
        familyIsSelected
      ) {
        _selectedOption = option;
        break;
      }
    }
    // console.log('selected option', _selectedOption);

    let premiumAmount = 0;
    let _personTypeId = 0;
    if (memberIsSelected) {
      premiumAmount = _selectedOption?.memberPremiumAmount;
      _personTypeId = 0;
    } else if (memberAndSpouseIsSelected) {
      premiumAmount = _selectedOption?.memberAndSpousePremiumAmount;
      _personTypeId = 1;
    } else if (memberAndDependentsIsSelected) {
      premiumAmount = _selectedOption?.memberAndDependentsPremiumAmount;
      _personTypeId = 2;
    } else if (familyIsSelected) {
      premiumAmount = _selectedOption?.familyPremiumAmount;
      _personTypeId = 3;
    }

    //return object with updated personType and premium amount
    let _personTypeMatch = {
      premiumAmount: premiumAmount,
      personTypeId: _personTypeId,
      option: _selectedOption,
    };
    return _personTypeMatch;
  };

  //Edit local state value of plan
  let handleCoveredLivesChange = (e, personType) => {
    let planOptionId = e.target.value;

    //returns object of new personTypeId and premium amount
    let _updatedPersonTypeAndPremium =
      getCoveredLivesAdditionalInfo(planOptionId);
    //find corresponding personTypeId
    //update state
    let _updatedCoverage = lineToEdit.map((line) => {
      return {
        ...line,
        editInfo: {
          ...line.editInfo,
          planOptionId: planOptionId,
          benefitAmount: _updatedPersonTypeAndPremium.option?.benefitAmount,
          premiumAmount: _updatedPersonTypeAndPremium.premiumAmount,
          isUpdated: true,
          personTypeId: _updatedPersonTypeAndPremium.personTypeId,
        },
      };
    });
    console.log('updated coverage', _updatedCoverage);
    setLineToEdit(_updatedCoverage);
  };

  //Update coverage info in db for single line
  const updateSingleLine = async () => {
    props?.setLoading(true);
    let editedLineObj = lineToEdit[0]?.editInfo;
    //build copy of plan object to send w/ new benefit amount, planOptionId, personTypeId, premium amount
    //update accident, add, cancer, hospital plans premium amount and personTypeId conditionally
    let _updatedLineObj = {
      ...lineToEdit[0]?.lineOfCoverage,
      planOptionId: editedLineObj?.planOptionId,
      benefitAmount: editedLineObj?.benefitAmount,
      premiumAmount: editedLineObj?.premiumAmount,
      benefitDuration: editedLineObj?.benefitDuration,
      eliminationPeriod: editedLineObj?.eliminationPeriod,
      personTypeId: editedLineObj?.personTypeId,
    };

    //update line of coverage in db if benefit amount > 0 OR plan is a acc, add, can, or hosp plan (these have benefit amount=0 BUT need to check premium amount to determine if decline option selected), else oopen cancel coverage drawer
    if (
      _updatedLineObj.benefitAmount > 0 ||
      (isAccAddCanOrHosp && _updatedLineObj.premiumAmount > 0)
    ) {
      let response;
      try {
        response = await activeCoverageService.updateActiveCoverageForMember(
          _updatedLineObj,
          props?.activeCoverageDrawerOpen?.dateOfEdit
        );
      } catch (error) {
        console.error('error updating plan', error);
      }
      //refresh active coverages and payments
      if (response) {
        await props?.getActiveCoverage(lineToEdit[0]?.lineOfCoverage?.memberId);
        await props?.getPaymentSchedule();
        props?.handleModificationTypeChange('view'); //exit EDIT section and return to view section
      }
    }
    props?.setLoading(false);
  };

  /////////////////////////// MULTIPLE lines of coverage per type of coverage (LADD and CI ) data //////////////////////////
  const [multiLinesToEdit, setMultiLinesToEdit] = useState([]);
  const [linesToAutoCancel, setLinesToAutoCancel] = useState([]);
  const [totalMultiLinePremium, setTotalMultiLinePremium] = useState(0);
  useEffect(() => {
    if (
      props?.activeCoverageDrawerOpen?.lineSelected?.length > 0 &&
      props?.activeCoverageDrawerOpen?.lineSelected?.length > 0 &&
      props?.activeCoverageDrawerOpen?.lineSelected[0]?.lineOfCoverage?.displayName?.includes(
        'LADD' || 'CI'
      )
    ) {
      let _updatedLinesSelected =
        props?.activeCoverageDrawerOpen?.lineSelected.map((line) => {
          return {
            ...line,
            editInfo: {
              isElected: true,
              benefitAmount: line?.lineOfCoverage?.benefitAmount,
              premiumAmount: line?.lineOfCoverage?.premiumAmount,
              isUpdated: false,
              personTypeId: line?.lineOfCoverage?.personTypeId,
              activeCoverageId: line?.lineOfCoverage?.id,
              benefitOffsets: line?.lineOfCoverage?.benefitOffsets,
              //for ci, may need to add more fields
            },
          };
        });

      //SET LADD SELECTIONS
      setMultiLinesToEdit(_updatedLinesSelected);

      //get total premium amount for combined lines
      let total = getTotalPremium(_updatedLinesSelected);
      setTotalMultiLinePremium(total);
    }
  }, [props?.activeCoverageDrawerOpen?.lineSelected]);

  //get total premium amount for multiple lines array
  const getTotalPremium = (lineOfCoverageArray) => {
    if (!lineOfCoverageArray) return 0;
    let total = 0;
    lineOfCoverageArray?.forEach((line) => {
      if (line?.editInfo?.premiumAmount > 0) {
        total += line?.editInfo?.premiumAmount;
      }
    });
    return total;
  };

  //UPDATE BENEFIT AMOUNTS IN DB
  const updateMultipleLines = async () => {
    props?.setLoading(true);

    let updateCount = 0; // Counter to track how many updates have completed
    const totalLines = multiLinesToEdit.length; 

    for (let line of multiLinesToEdit) {
      try {
        if (
          (line?.editInfo?.isUpdated ||
            (line?.lineOfCoverage?.futureActiveCoverageId > 0 &&
              line?.lineOfCoverage?.futurePremiumAmount !==
                line?.lineOfCoverage?.premiumAmount)) &&
          line?.editInfo?.premiumAmount > 0
        ) {
          let _updatedLine = {
            ...line?.lineOfCoverage,
            benefitAmount: line?.editInfo?.benefitAmount,
            premiumAmount: line?.editInfo?.premiumAmount,
          };


          await activeCoverageService.updateActiveCoverageForMember(
            _updatedLine,
            props?.activeCoverageDrawerOpen?.dateOfEdit
          );
        } else if (
          line?.editInfo?.isUpdated &&
          line?.editInfo?.premiumAmount === 0
        ) {
          await activeCoverageService.cancelActiveCoverageForMember(
            line?.lineOfCoverage?.id,
            props?.reasonForCancelation,
            props?.dateOfCancelation
          );
        }
      } catch (error) {
        console.error('Error updating/canceling line of coverage', error);
      }

      updateCount++; 

      // If all updates are complete, refresh
      if (updateCount === totalLines) {
        setTimeout(() => {
          props?.setLoading(false);
          props?.getActiveCoverage(
            props?.activeCoverageDrawerOpen?.lineSelected[0]?.lineOfCoverage
              ?.memberId
          );
          props?.getPaymentSchedule();
          props?.handleModificationTypeChange('view'); // Exit EDIT section and return to view section
        }, 2000);
      }
    }
  };

  /////SUBMIT EDIT to DB
  const submitEdit = () => {
    //check if ladd/CI or other plan type
    if (
      props?.activeCoverageDrawerOpen?.lineSelected[0]?.lineOfCoverage?.displayName?.includes(
        'LADD' || 'CI'
      )
    ) {
      updateMultipleLines();
    } else {
      updateSingleLine();
    }
  };

  return (
    <>
      <Box sx={acDrawerSharedStyles.componentContainer}>
        <Typography variant="h6">Edit Coverage</Typography>

        <Box sx={acDrawerSharedStyles.bodyContainer}>
          <Box sx={acDrawerSharedStyles.editInputContainer}>
            {/* Benefit Amount*/}
            {/* input for benefit amount for non hospital, cancer, accident, and add plans */}
            {props?.activeCoverageDrawerOpen?.lineSelected[0]?.lineOfCoverage
              ?.benefitAmount > 0 &&
              !isAccAddCanOrHosp && (
                <>
                  {' '}
                  {props?.activeCoverageDrawerOpen?.lineSelected[0]?.lineOfCoverage?.displayName?.includes(
                    'LADD' || 'CI'
                  ) ? (
                    <EditMultipleAcLinesSection
                      multiLinesToEdit={multiLinesToEdit}
                      setMultiLinesToEdit={setMultiLinesToEdit}
                      findSingleLineDisplayName={
                        props?.findSingleLineDisplayName
                      }
                      totalMultiLinePremium={totalMultiLinePremium}
                      setTotalMultiLinePremium={setTotalMultiLinePremium}
                      getTotalPremium={getTotalPremium}
                      setLinesToAutoCancel={setLinesToAutoCancel}
                    />
                  ) : (
                    <FormControl fullWidth>
                      <InputLabel id="benefitAmount">Benefit Amount</InputLabel>
                      <Select
                        disabled={isAccAddCanOrHosp}
                        label="Benefit Amount"
                        sx={acDrawerSharedStyles.inputTextField}
                        value={lineToEdit[0]?.editInfo?.planOptionId || ''}
                        onChange={(e) => handleEditCoverageInfo(e)}
                      >
                        {lineToEdit[0]?.options?.options &&
                          lineToEdit[0]?.options?.options?.map((option) => {
                            if (
                              option?.benefitDuration ==
                                lineToEdit[0]?.editInfo?.benefitDuration &&
                              option?.eliminationPeriod ==
                                lineToEdit[0]?.editInfo?.eliminationPeriod &&
                              !option.isDecline
                              //commenting out temporarily - need direction - do we want to prevent agents from increasing benefit amount?
                              // &&
                              // option?.benefitAmount <=
                              //   lineToEdit[0]?.lineOfCoverage?.benefitAmount //don't allow selection over current benefit amount
                            ) {
                              return (
                                <MenuItem
                                  key={option.memberOptionId}
                                  value={option.memberOptionId}
                                >
                                  {formatCurrency(option?.benefitAmount || 0)}{' '}
                                  {option?.benefitTypeFriendly?.toLowerCase() !==
                                  'fixed'
                                    ? option?.benefitTypeFriendly
                                    : ''}
                                  {option?.memberPremiumAmount
                                    ? ` (${formatCurrency(
                                        option?.memberPremiumAmount
                                      )})`
                                    : ''}
                                </MenuItem>
                              );
                            }
                          })}
                      </Select>
                    </FormControl>
                  )}
                </>
              )}
            {/* Benefit-Amount view for add, accident, cancer, and hospital plans*/}
            {props?.activeCoverageDrawerOpen?.lineSelected[0]?.lineOfCoverage
              ?.benefitAmount > 0 &&
              isAccAddCanOrHosp && (
                <FormControl fullWidth>
                  <InputLabel id="benefitAmount">Benefit Amount</InputLabel>
                  <Select
                    disabled={isAccAddCanOrHosp}
                    label="Benefit Amount"
                    sx={acDrawerSharedStyles.inputTextField}
                    value={lineToEdit[0]?.editInfo?.planOptionId || ''}
                    onChange={(e) =>
                      handleEditCoverageInfo(e, 'nonIncremental')
                    }
                  >
                    {lineToEdit[0]?.options?.options?.length > 0 &&
                      lineToEdit[0]?.options?.options
                        ?.sort((a, b) => a.displayOrder - b.displayOrder)
                        .map((option) => {
                          if (!option.isDecline) {
                            let itemList = [];
                            if (option.memberPremiumAmount > 0)
                              itemList.push(
                                <MenuItem
                                  value={option.memberOptionId}
                                  key={option.memberOptionId}
                                >
                                  Member{' '}
                                  {option?.explanationOfBenefits ===
                                  'AD&D Coverage'
                                    ? ` (${option?.name}) `
                                    : ''}
                                </MenuItem>
                              );
                            if (option.memberAndSpousePremiumAmount > 0)
                              itemList.push(
                                <MenuItem
                                  value={option.memberAndSpouseOptionId}
                                  key={option.memberAndSpouseOptionId}
                                >
                                  Member and Spouse{' '}
                                  {option?.explanationOfBenefits ===
                                  'AD&D Coverage'
                                    ? ` (${option?.name}) `
                                    : ''}
                                </MenuItem>
                              );
                            if (option.memberAndDependentsPremiumAmount > 0)
                              itemList.push(
                                <MenuItem
                                  value={option.memberAndDependentsOptionId}
                                  key={option.memberAndDependentsOptionId}
                                >
                                  Member and Dependent{' '}
                                  {option?.explanationOfBenefits ===
                                  'AD&D Coverage'
                                    ? ` (${option?.name}) `
                                    : ''}
                                </MenuItem>
                              );
                            if (option.familyPremiumAmount > 0)
                              itemList.push(
                                <MenuItem
                                  value={option.familyOptionId}
                                  key={option.familyOptionId}
                                >
                                  Family{' '}
                                  {option?.explanationOfBenefits ===
                                  'AD&D Coverage'
                                    ? ` (${option?.name}) `
                                    : ''}
                                </MenuItem>
                              );
                            return itemList.map((item) => item);
                          }
                        })}
                  </Select>
                </FormControl>
              )}

            {/* Benefit Duration*/}
            {lineToEdit[0]?.lineOfCoverage?.benefitDuration &&
            benefitDurTypes?.length > 0 ? (
              <FormControl fullWidth>
                <InputLabel id="benefitDuration">Benefit Duration</InputLabel>
                <Select
                  variant="outlined"
                  labelId="benefitDuration"
                  label="Benefit Duration"
                  disabled={benefitDurTypes?.length < 2}
                  sx={acDrawerSharedStyles.inputTextField}
                  value={lineToEdit[0]?.editInfo?.benefitDuration || ''}
                  onChange={(e) => handleEditBenefitDuration(e)}
                >
                  {benefitDurTypes &&
                    benefitDurTypes?.map((option) => {
                      return (
                        <MenuItem key={option.duration} value={option.duration}>
                          {option.duration} {option.type}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            ) : null}

            {/* Elimination Period*/}
            {lineToEdit[0]?.lineOfCoverage?.eliminationPeriod &&
            eliminationPeriodTypes?.length > 0 ? ( //multiple elim periods
              <FormControl fullWidth>
                <InputLabel id="eliminationPeriod">
                  Elimination Period
                </InputLabel>
                <Select
                  variant="outlined"
                  labelId="eliminationPeriod"
                  label="Elimination Period"
                  disabled={eliminationPeriodTypes?.length < 2}
                  sx={acDrawerSharedStyles.inputTextField}
                  value={lineToEdit[0]?.editInfo?.eliminationPeriod || ''}
                  onChange={(e) => handleEditEliminationPeriod(e)}
                >
                  {eliminationPeriodTypes &&
                    eliminationPeriodTypes.map((option) => {
                      return (
                        <MenuItem
                          key={option.eliminationPeriod}
                          value={option.eliminationPeriod}
                        >
                          {option.eliminationPeriod} {option.type}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            ) : null}

            {/* Covered Lives - show for accident, add, hospital, and cancer plans */}
            {isAccAddCanOrHosp && (
              <FormControl fullWidth>
                <InputLabel id="coveredLives">Covered Lives</InputLabel>
                <Select
                  label="Covered Lives"
                  labelId="coveredLives"
                  sx={acDrawerSharedStyles.inputTextField}
                  value={lineToEdit[0]?.editInfo?.planOptionId}
                  onChange={(e) => handleCoveredLivesChange(e)}
                >
                  {lineToEdit[0]?.options?.options?.length > 0 &&
                    lineToEdit[0]?.options?.options
                      ?.sort((a, b) => a.displayOrder - b.displayOrder)
                      .map((option) => {
                        if (!option.isDecline) {
                          let itemList = [];
                          if (option.memberPremiumAmount > 0)
                            itemList.push(
                              <MenuItem
                                value={option.memberOptionId}
                                key={option.memberOptionId}
                              >
                                Member{' '}
                                {option?.explanationOfBenefits ===
                                'AD&D Coverage'
                                  ? ` (${option?.name}) `
                                  : ''}
                                {formatCurrency(
                                  option?.memberPremiumAmount || 0
                                )}
                              </MenuItem>
                            );
                          if (option.memberAndSpousePremiumAmount > 0)
                            itemList.push(
                              <MenuItem
                                value={option.memberAndSpouseOptionId}
                                key={option.memberAndSpouseOptionId}
                              >
                                Member and Spouse{' '}
                                {option?.explanationOfBenefits ===
                                'AD&D Coverage'
                                  ? ` (${option?.name}) `
                                  : ''}
                                {formatCurrency(
                                  option?.memberAndSpousePremiumAmount || 0
                                )}
                              </MenuItem>
                            );
                          if (option.memberAndDependentsPremiumAmount > 0)
                            itemList.push(
                              <MenuItem
                                value={option.memberAndDependentsOptionId}
                                key={option.memberAndDependentsOptionId}
                              >
                                Member and Dependent{' '}
                                {option?.explanationOfBenefits ===
                                'AD&D Coverage'
                                  ? ` (${option?.name}) `
                                  : ''}
                                {formatCurrency(
                                  option?.memberAndDependentsPremiumAmount || 0
                                )}
                              </MenuItem>
                            );
                          if (option.familyPremiumAmount > 0)
                            itemList.push(
                              <MenuItem
                                value={option.familyOptionId}
                                key={option.familyOptionId}
                              >
                                Family{' '}
                                {option?.explanationOfBenefits ===
                                'AD&D Coverage'
                                  ? ` (${option?.name}) `
                                  : ''}
                                {formatCurrency(
                                  option?.familyPremiumAmount || 0
                                )}
                              </MenuItem>
                            );
                          return itemList.map((item) => item);
                        }
                      })}
                </Select>
              </FormControl>
            )}
            {/* Changes Effective selector */}
            <TextField
              disabled //temp disabled while we currently only allow edits to go into effect start of next month
              select
              fullWidth
              sx={acDrawerSharedStyles.inputTextField}
              id="dateOfEdit"
              label="Changes Effective"
              value={props?.activeCoverageDrawerOpen?.dateOfEdit || ''}
              onChange={(e) => handleEditDateChange(e)}
              InputLabelProps={{ shrink: true }}
            >
              {props?.editCoverageDateSelectOptions?.length > 0 &&
                props?.editCoverageDateSelectOptions?.map((date) => {
                  return (
                    <MenuItem key={date.value} value={date.value}>
                      {`${date.name} (${date.displayDate})`}
                    </MenuItem>
                  );
                })}
            </TextField>

            {/* Benefit Offset*/}
            {lineToEdit[0]?.lineOfCoverage?.benefitOffsets &&
            lineToEdit[0]?.lineOfCoverage?.benefitOffsets !== '' &&
            lineToEdit[0]?.lineOfCoverage?.benefitOffsets !== '.' ? (
              <Box sx={componentStyles.offsetRow}>
                <Typography sx={componentStyles.offsetLabel}>
                  Offset:
                </Typography>
                <Typography sx={componentStyles.offsetDescription}>
                  {lineToEdit[0]?.lineOfCoverage?.benefitOffsets}
                </Typography>
              </Box>
            ) : null}

            {/* Premium priceContainer */}
            <Box
              sx={{ ...acDrawerSharedStyles.priceContainer, marginTop: '6px' }}
            >
              <Typography sx={acDrawerSharedStyles.priceView}>
                $
                {props?.activeCoverageDrawerOpen?.lineSelected[0]?.lineOfCoverage?.displayName?.includes(
                  'LADD' || 'CI'
                )
                  ? totalMultiLinePremium.toFixed(2)
                  : lineToEdit[0]?.editInfo?.premiumAmount?.toFixed(2)}
              </Typography>
              <Typography sx={acDrawerSharedStyles.perMonthText}>
                / per month
              </Typography>
            </Box>
          </Box>

          {/* Buttons */}
          <Box sx={acDrawerSharedStyles.buttonContainer}>
            <Button
              sx={{ marginRight: '10px' }}
              onClick={
                () => props?.handleModificationTypeChange('view') //exit EDIT section and return to view section
              }
            >
              CANCEL
            </Button>
            <Button variant="contained" onClick={submitEdit}>
              SAVE CHANGES
            </Button>
          </Box>
          {linesToAutoCancel?.length > 0 && (
            <Alert fullWidth severity="warning" sx={{ marginTop: '20px' }}>
              <Box>
                <Typography>
                  {`Due to lowering member coverage, ${
                    linesToAutoCancel?.length > 1 ? 'these lines' : 'this line'
                  } will be automatically cancelled:` ||
                    ''}
                </Typography>
                <Box sx={{ margin: '20px' }}>
                  {linesToAutoCancel?.length > 0 &&
                    linesToAutoCancel?.map((line) => {
                      return (
                        <Typography key={line?.lineOfCoverage?.id}>
                          -{' '}
                          {props?.findSingleLineDisplayName(
                            line?.lineOfCoverage?.displayName
                          )}{' '}
                          coverage
                        </Typography>
                      );
                    })}
                </Box>
              </Box>
            </Alert>
          )}
        </Box>
      </Box>
    </>
  );
}
