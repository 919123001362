import React, { useState, useEffect } from 'react';

//services
import activeCoverageService from '../../../../../../../../services/activeCoverage-service';

// Assets

//Mui Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Box, Typography } from '@mui/material';

//Mui icons

//Our Components
import CancelMultipleAcLinesSelection from './CancelMultipleAcLinesSelection';

//utils

//shared styles
import acDrawerSharedStyles from './acDrawerSharedStyles';

export default function CancelAcLine(props) {
  //local state of coverage line(s) selected for cancellation - adding additional property to each line to be able to filter out the lines that are not selected for cancellation in instances where more than one line is displayed
  const [lineToCancel, setLineToCancel] = useState([]);
  useEffect(() => {
    if (props?.activeCoverageDrawerOpen?.lineSelected?.length > 0) {
      let _updatedLineSelected =
        props?.activeCoverageDrawerOpen?.lineSelected.map((line) => {
          return {
            ...line,
            lineOfCoverage: {
              ...line.lineOfCoverage,
              cancelLineOfCoverage: true,
            },
          };
        });
      setLineToCancel(_updatedLineSelected);
    }
  }, [props?.activeCoverageDrawerOpen?.lineSelected]);

  //update line selected if more than one exist - used on ladd or ci (coverge w/ multiple lines)
  const handleUpdateLineSelected = (coverage) => {
    //check if member coverage is currently selected and a different line is trying to be de-selected
    //update selected line to cancelLineOfCoverage: true
    let _updatedLineSelected = lineToCancel.map((line) => {
      if (line?.lineOfCoverage?.id === coverage.id) {
        return {
          ...line,
          lineOfCoverage: {
            ...line?.lineOfCoverage,
            cancelLineOfCoverage: !line?.lineOfCoverage?.cancelLineOfCoverage,
          },
        };
      } else {
        return line;
      }
    });

    //if coverage selected is member AND selection is true OR member coverage is already selected, set all lines to true
    let _memberSelected = _updatedLineSelected.find(
      (line) => line?.lineOfCoverage?.calculatedPlanTypeId === 4
    );
    if (
      (coverage?.calculatedPlanTypeId === 4 &&
        !coverage?.cancelLineOfCoverage) ||
      _memberSelected?.lineOfCoverage?.cancelLineOfCoverage
    ) {
      _updatedLineSelected = _updatedLineSelected.map((line) => {
        return {
          ...line,
          lineOfCoverage: {
            ...line?.lineOfCoverage,
            cancelLineOfCoverage: true,
          },
        };
      });
    }

    setLineToCancel(_updatedLineSelected);
  };

  //submit cancelation
  const submitCancellation = async () => {
    props?.setLoading(true);

    if (lineToCancel?.length > 0) {
      //RO DO: add in logic for CI plans to match ladd logic w/ calculatedPlanTypeId
      let _memberLaddSelected = lineToCancel.find(
        (line) =>
          line?.lineOfCoverage?.calculatedPlanTypeId === 4 &&
          line?.lineOfCoverage?.cancelLineOfCoverage &&
          !line?.lineOfCoverage?.isCancelled
      );
      //if member ladd or member CI (coming soon) selected to cancel, hit api with member only as back-end will cancel all existing ladd (member, spouse, and/or dependent)
      if (_memberLaddSelected) {
        try {
          //cancel coverage
          await activeCoverageService.cancelActiveCoverageForMember(
            _memberLaddSelected?.lineOfCoverage?.id,
            props?.reasonForCancelation,
            props?.dateOfCancelation
          );
        } catch (error) {
          console.error('error canceling member ladd line of coverage', error);
        }
      } else {
        //cancel each line selected if not already pending cancellation
        for (const coverage of lineToCancel) {
          if (
            coverage?.lineOfCoverage?.cancelLineOfCoverage &&
            !coverage?.lineOfCoverage?.isCancelled
          ) {
            try {
              // Cancel coverage
              await activeCoverageService.cancelActiveCoverageForMember(
                coverage?.lineOfCoverage?.id,
                props?.reasonForCancelation,
                props?.dateOfCancelation
              );
            } catch (error) {
              console.error('Error canceling single line of coverage', error);
            }
          }
        }
      }
    }
    //*****make post cancel submit return to view */

    //following cancel coverage, refresh data
    setTimeout(() => {
      props?.getPaymentSchedule
        ? props?.getPaymentSchedule()
        : console.log('no getPaymentSchedule method');
      props?.getActiveCoverage
        ? props?.getActiveCoverage(lineToCancel[0]?.lineOfCoverage?.memberId)
        : console.log('no getActiveCoverage method');
      props?.getMemberHistory
        ? props?.getMemberHistory()
        : console.log('no getMemberHistory method');
      //TO DO: once we roll-out full drawer, need to add in update modification type back to 'view' after cancel complete
      props?.setLoading(false);
      props?.handleModificationTypeChange('view'); //exit EDIT section and return to view section
    }, 2000);
  };

  //cancel all coverage flow when only one line of coverage exists
  const handleCancelAllCoverageFlow = (e) => {
    if (props?.activePlansNotPendingCancel?.length === 1) {
      //open cancel all coverage drawer
      props?.handleOpenCancelCoverageDrawer(e, 'cancelAllCoverage');
      //set modification type to 'veiw' in ActiveCoverageDrawer
      props?.handleModificationTypeChange('view');
    }
  };

  return (
    <>
      <Box sx={acDrawerSharedStyles.componentContainer}>
        <Typography variant="h6">Cancel Coverage</Typography>

        <Box sx={acDrawerSharedStyles.bodyContainer}>
          <>
            <Typography sx={acDrawerSharedStyles.infoRow}>
              Policy #
              {props?.activeCoverageDrawerOpen?.lineSelected[0]?.lineOfCoverage
                ?.policyNumber || ''}
            </Typography>
            <Typography sx={acDrawerSharedStyles.infoRow}>
              Carrier:{' '}
              {props?.activeCoverageDrawerOpen?.lineSelected[0]?.lineOfCoverage
                ?.carrierName || ''}
            </Typography>
            {props?.renderEffectiveDateList(
              props?.activeCoverageDrawerOpen?.lineSelected
            )}
          </>

          {/* Checkbox Select section for coverage that has multiple lines like LADD or CI */}
          {props?.activeCoverageDrawerOpen?.lineSelected[0]?.lineOfCoverage?.displayName?.includes(
            'LADD' || 'CI'
          ) && (
            <>
              <CancelMultipleAcLinesSelection
                lineToCancel={lineToCancel}
                handleUpdateLineSelected={handleUpdateLineSelected}
                findSingleLineDisplayName={props?.findSingleLineDisplayName}
              />
            </>
          )}

          {/* Cancellation Date and Reason */}
          <Box sx={acDrawerSharedStyles.inputContainer}>
            <TextField
              disabled //temp disabled while we currently only allow cancelling at end of month- remove this and it will allow for 'end of last month' or 'end of current month' selection
              select
              fullWidth
              id="dateOfCancelation"
              sx={acDrawerSharedStyles.inputTextField}
              label="Cancellation Effective Date"
              value={props?.dateOfCancelation || ''}
              onChange={props?.handleCancelDateChange}
              InputLabelProps={{ shrink: true }}
            >
              {props?.cancelCoverageDateSelectionOptions?.length > 0 &&
                props?.cancelCoverageDateSelectionOptions?.map((date) => {
                  return (
                    <MenuItem key={date.value} value={date.value}>
                      {`${date.name} (${date.displayDate})`}
                    </MenuItem>
                  );
                })}
            </TextField>

            <TextField
              fullWidth
              id="reasonForCancelation"
              onChange={(e) => {
                props?.handleChangeReasonForCancelation(e);
              }}
              label="Reason for Cancellation"
              value={props?.reasonForCancelation || ''}
              select
            >
              {props?.cancellationReasons &&
                props?.cancellationReasons.map((reason) => {
                  return (
                    <MenuItem key={reason.id} value={reason.name}>
                      {reason.name}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Box>

          {/* Buttons */}
          <Box sx={acDrawerSharedStyles.buttonContainer}>
            <Button
              sx={{ marginRight: '10px' }}
              onClick={
                () => props?.handleModificationTypeChange('view') //exit cancel section and return to view section
              }
            >
              BACK
            </Button>
            <Button
              id="confirm-cancellation-button"
              variant="contained"
              disabled={
                props?.reasonForCancelation === '' ||
                !props?.reasonForCancelation
              }
              onClick={(e) =>
                props?.activePlansNotPendingCancel?.length === 1
                  ? handleCancelAllCoverageFlow(e)
                  : submitCancellation()
              }
            >
              CONFIRM CANCELLATION
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
