import React from 'react';

//Mui icons

//Mui Components
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

//utils
import { formatCurrency } from '../../../../../../../../utils';

//Our styles
import activeCoverageStyles from '../activeCoverageStyles';

export default function NonIncrementalStdAndLtdActiveCoverage(props) {
  return (
    <>
      {/* Benefit Amount Row */}
      {props?.plan?.benefitAmount && props?.plan?.benefitAmount > 0 ? (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <Typography variant="body1">Benefit Amount</Typography>
          {/* Non-edit view */}

          <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
            {formatCurrency(props?.plan?.benefitAmount)}
            {props?.plan?.benefitTypeFriendly &&
            props?.plan?.benefitTypeFriendly?.toLowerCase() !== 'fixed'
              ? ` ${props?.plan?.benefitTypeFriendly}`
              : ''}
          </Typography>
        </Box>
      ) : null}

      {/* Elimination Period Row*/}
      {props?.plan?.eliminationPeriod ? (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <Typography variant="body1">Elimination Period</Typography>

          <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
            {props?.plan?.eliminationPeriod}{' '}
            {props?.plan?.eliminationPeriodTypeFriendly
              ? ` ${props?.plan?.eliminationPeriodTypeFriendly}`
              : ''}
          </Typography>
        </Box>
      ) : null}

      {/* Benefit Duration Row*/}
      {props?.plan?.benefitDuration ? (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <Typography variant="body1">Benefit Duration</Typography>

          <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
            {props?.plan?.benefitDuration}
            {props?.plan?.benefitDurationTypeFriendly
              ? ` ${props?.plan?.benefitDurationTypeFriendly}`
              : ''}
          </Typography>
        </Box>
      ) : null}
    </>
  );
}
