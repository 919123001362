import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format, formatDistance, subMinutes } from 'date-fns';

//services
import ticketService from '../../../../../../services/ticket-service';
import optionService from '../../../../../../services/optionService';
import userService from '../../../../../../services/user-service';
import registerService from '../../../../../../services/register-service';

//Mui Components
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { Alert, Paper, Typography, Switch } from '@mui/material';
import { Box } from '@mui/system';

// Assets
import openSvg from '../../../../../../assets/images/openSvg.svg';
import closedSvg from '../../../../../../assets/images/closedSvg.svg';

//Mui icons
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

//Our Components
import { thisUserContext } from '../../../../nav-container/NavContainer';
import MemberMergeTool from '../../../../admin-tool-components/MemberMergeTool';
import FlexSnackbar from '../../../../../flex-snackbar/FlexSnackbar';

export default function MemberSettingsTab(props) {
  const [forgotState, setForgotState] = useState({
    verificationCode: (Math.round(Math.random(0) * 1000) + 1000).toString(),
    emailSent: false,
    email: props?.member?.email,
  });

  const sendResetLink = async () => {
    setForgotState({
      ...forgotState,
      emailSent: true,
    });

    props.setMessage({
      ...props.message,
      messageDisplay: true,
      autoHideDuration: 3000,
      backgroundColor: '',
      severity: 'success',
      message: 'Successfully Sent Email',
    });
    await registerService.forgotPasswordByMemberId(props.member.id);
  };

  const handleArchiveChange = async () => {
    props?.updateMember({
      ...props?.member,
      isArchived: !props?.member?.isArchived,
    });

    props.setMessage({
      ...props.message,
      messageDisplay: true,
      autoHideDuration: 3000,
      backgroundColor: '',
      severity: 'info',
      message: props?.member?.isArchived
        ? 'Member profile unarchived.'
        : 'Member profile archived.',
    });
  };

  const reloadMember = () => {
    props.getThisMember();
  };

  const [emailEnabled, setEmailEnabled] = useState(
    props?.member?.allowEmailContact
  );
  const [smsEnabled, setSmsEnabled] = useState(props?.member?.allowSMSContact);

  const handleCommunicationSettingsChange = (type) => {
    if (type === 'email') {
      setEmailEnabled((prev) => !prev);
      props.setMessage({
        ...props.message,
        messageDisplay: true,
        autoHideDuration: 3000,
        backgroundColor: '',
        severity: 'success',
        message: 'Successfully updated preferences',
      });
    }
    if (type === 'sms') {
      setSmsEnabled((prev) => !prev);
      props.setMessage({
        ...props.message,
        messageDisplay: true,
        autoHideDuration: 3000,
        backgroundColor: '',
        severity: 'success',
        message: 'Successfully updated preferences',
      });
    }
  };

  //update db with new preferences
  useEffect(() => {
    //check for changes to member preferences before updating in db
    let memberInfoChanged =
      props?.member?.allowEmailContact !== emailEnabled ||
      props?.member?.allowSMSContact !== smsEnabled;
    if (memberInfoChanged) {
      props?.updateMember({
        ...props?.member,
        allowEmailContact: emailEnabled,
        allowSMSContact: smsEnabled,
      });
    }
  }, [emailEnabled, smsEnabled]);

  return (
    <>
      <Box
        sx={{
          color: 'text.primary',
          ...props?.disabledProfileStyle,
        }}
        style={{ padding: '20px', height: 'calc(100vh - 104px)' }}
      >
        <div
          className="col"
          style={{
            paddingLeft: '20px',
            justifyContent: 'flex-start',
            paddingBottom: '20px',
          }}
        >
          <Typography variant="h4" sx={{ fontFamily: 'Poppins' }}>
            Settings
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: '20px' }}>
            Member specific settings.
          </Typography>
        </div>

        <Paper
          elevation={0}
          sx={{
            border: '1px solid',
            borderColor: 'background.border',
            backgroundColor: 'background.default',
            height: 'auto',
            padding: '20px',
            marginBottom: '20px',
          }}
        >
          <Typography variant="h5" sx={{ fontFamily: 'Poppins' }}>
            Account Settings
          </Typography>
          <Button
            variant="contained"
            sx={{ height: 50, width: 350, marginTop: '15px' }}
            onClick={sendResetLink}
            disabled={props?.profileDisabled}
          >
            <Typography>SEND PASSWORD RESET EMAIL</Typography>
          </Button>
        </Paper>

        <Paper
          elevation={0}
          sx={{
            border: '1px solid',
            borderColor: 'background.border',
            backgroundColor: 'background.default',
            height: 'auto',
            padding: '20px',
            marginBottom: '20px',
          }}
        >
          <Typography variant="h5" sx={{ fontFamily: 'Poppins' }}>
            Communication Settings
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: '20px' }}>
            Manage what kind of communications this user can recieve.
          </Typography>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}
          >
            <Box sx={{ flexDirection: 'column' }}>
              <Switch
                checked={emailEnabled}
                disabled={props?.profileDisabled}
                onChange={() => handleCommunicationSettingsChange('email')}
                className="archiveAlert"
                inputProps={{
                  'aria-label': 'Email Opt Status',
                  className: 'archiveAlert',
                }}
              />
            </Box>
            <Box sx={{ flexDirection: 'column' }}>
              <Typography variant="body1">
                Allow Email Communications
              </Typography>
              <Typography variant="body2">
                By opting out of email communications, this user will not
                receive marketing campaigns or in-progress communications for
                outstanding applications. Users cannot opt out of transactional
                emails, such as enrollment submissions, payment receipts, or
                payment failure communications.
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{ display: 'flex', flexDirection: 'row' }}
            className="archiveAlert"
          >
            <Box sx={{ flexDirection: 'column' }} className="archiveAlert">
              <Switch
                checked={smsEnabled}
                disabled={props?.profileDisabled}
                onChange={() => handleCommunicationSettingsChange('sms')}
                className="archiveAlert"
                inputProps={{
                  'aria-label': 'SMS Opt Status',
                  className: 'archiveAlert',
                }}
              />
            </Box>
            <Box sx={{ flexDirection: 'column' }}>
              <Typography variant="body1">Allow SMS Communications</Typography>

              <Typography variant="body2">
                By opting out of SMS communications, this user will not receive
                any SMS campaigns for marketing or transactional purposes.
              </Typography>
            </Box>
          </Box>
        </Paper>

        <Paper
          elevation={0}
          className="archiveAlert"
          sx={{
            border: '1px solid',
            borderColor: 'background.border',
            backgroundColor: 'background.default',
            height: 'auto',
            padding: '20px',
            marginBottom: '20px',
          }}
        >
          <Typography
            className="archiveAlert"
            variant="h5"
            sx={{ fontFamily: 'Poppins' }}
          >
            Archive
          </Typography>
          <Typography className="archiveAlert" variant="body2">
            Only agents with specific permission levels can archive or unarchive
            a member's profile, and once archived, the profile cannot be
            modified until it is restored.
          </Typography>
          <Button
            variant="outlined"
            className="archiveAlert"
            color="error"
            sx={{
              height: 50,
              width: 350,
              marginTop: '15px',
              color: '#ef5350',
              border: '1px solid #ef5350',
            }}
            disabled={!props?.permissionsEnabler?.archiveMember}
            onClick={handleArchiveChange}
          >
            {props?.member?.isArchived
              ? 'UNLOCK MEMBER PROFILE'
              : 'ARCHIVE MEMBER PROFILE'}
          </Button>
        </Paper>

        {/* <Paper elevation={0} sx={{
            height: '100% ', border: '1px solid',
            borderColor: 'background.border', backgroundColor: 'background.default', height: 'auto', padding: '20px', borderRadius:'15px'
         }}>

            <Typography>Data Management</Typography>
            
            <MemberMergeTool member={props.member} setMessage={props.setMessage} />

         </Paper> */}
      </Box>
      <FlexSnackbar
        displayAlert={props.message.messageDisplay}
        setDisplayAlert={props.setMessage}
        text={props.message.message}
      ></FlexSnackbar>
    </>
  );
}
