import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

//mui components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import {
  formatDateObjMMDDYYYYDisplay,
  formatCurrency,
  formatUTCDateToObjWithTimeZone,
} from '../../../utils';

export default function PortalPaymentSummary(props) {
  let history = useHistory();

  return (
    <>
      <Typography
        component={'div'}
        className="col-12"
        sx={{
          fontFamily: 'Archivo',
          textTransform: 'uppercase',
          fontSize: '12px',
          letterSpacing: '1px',
          color: 'primary.main',
        }}
      >
        {props.enrollment?.name}
      </Typography>

      <Typography
        component={'div'}
        className="col-12"
        sx={{
          color: 'text.primary',
          fontFamily: 'Poppins',
          fontSize: '20px',
          fontWeight: 600,
          letterSpacing: '.15px',
        }}
      >
        {formatCurrency(
          props.payment[0]?.paymentAmount +
            props.payment[0]?.totalTransactionFee || 0
        )}
        <Typography
          component={'span'}
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            color: 'text.secondary',
            letterSpacing: '.17px',
            fontFamily: 'Archivo',
          }}
        >
          &nbsp;/mo
        </Typography>
      </Typography>

      <Typography
        component={'div'}
        className="col-12"
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          color: 'text.primary',
          letterSpacing: '.17px',
          fontFamily: 'Archivo',
        }}
      >
        Next payment{' '}
        {props.payment[0]?.nextOccurance?.split &&
          formatDateObjMMDDYYYYDisplay(
            formatUTCDateToObjWithTimeZone(props.payment[0]?.nextOccurance)
          )}
      </Typography>

      {!props.onPaymentPage && (
        <Box
          className="col-12"
          sx={{
            display: 'flex',
            color: 'text.primary',
            fontFamily: 'Archivo',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="contained"
            size="small"
            aria-label="Manage Payments"
            sx={{
              fontSize: '13px',
              lineHeight: '22px',
              letterSpacing: '.46px',
            }}
            onClick={() => history.push('/dashboard/payment')}
          >
            Manage Payments
          </Button>
        </Box>
      )}
    </>
  );
}
