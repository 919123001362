import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

//Services
import ticketService from '../../../../../services/ticket-service';
import optionService from '../../../../../services/optionService';

//MuiIcons

//Mui Components
import Button from '@mui/material/Button';

// toggle button fun
import { Typography } from '@mui/material';
import MuiToggleButton from '@mui/material/ToggleButton';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import SingleviewCloseTicketDrawer from '../singleview-closeticketdrawer/SingleviewCloseTicketDrawer';

export default function SingleviewRightside(props) {
  let ticket = props.ticket;
  const textAreaRef = useRef(null);

  let history = useHistory();

  const [status, setStatus] = useState([]);

  const [member, setMember] = useState();
  useEffect(() => {
    setMember(props.member);
  }, [props.member]);

  const closeOrReopenTicket = async (resolved, textField) => {
    let yesNo = '';
    if (resolved == 1) {
      yesNo = 'Yes';
    } else {
      yesNo = 'No';
    }

    if (props.ticket.ticketStatusId == 2) {
      try {
        await ticketService.reopenTicket(props.ticket.id);
        // await props.refreshTickets()
      } catch (err) {
        console.error(err);
      }
      setCloseTicketDrawerOpen(false);
    } else {
      var details = yesNo + ': ' + textField;
      try {
        await ticketService.closeTicket(props.ticket.id, details);
        // await props.refreshTickets()
      } catch (err) {
        console.error(err);
      }
      setCloseTicketDrawerOpen(false);
    }
    await props.refreshTickets();
    // await props.refreshTickets()
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const setModalIsOpenToTrue = () => {
    // setModalIsOpen(true)
    setCloseTicketDrawerOpen(true);
  };

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  const [yesNoButtonVal, setYesNoButtonVal] = useState();
  const yesNoButton = (event) => {
    event.target.value = true;
    var b;

    if (event.target.id === 'yesButton') {
      b = document.getElementById('noButton');
      setYesNoButtonVal('Resolved');
    } else {
      b = document.getElementById('yesButton');
      setYesNoButtonVal('Not Resolved');
    }
    b.value = false;
  };

  const customStyles = {
    content: {
      height: '417px',
      width: '375px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '32px 45px 17px',
      textAlign: 'center',
    },
    fullContainer: {
      bgcolor: 'background.default',
      color: 'text.primary',
      borderLeft: '1px solid',
      borderColor: 'background.border',
      height: 'calc(100vh - 57px)',
      overflowX: 'hidden',
      overflowY: 'auto',
      position: 'relative',
      fontFamily: 'Archivo',
      minWidth: '300px',
    },
    titleBox: {
      marginBottom: '14px',
      padding: '16px',
      flexDirection: 'row',
      display: 'flex',
    },
    //after title
    statusBox: {
      padding: '12px 23px',
      position: 'relative',
      backgroundColor: 'primary.light',
      flexDirection: 'row',
      display: 'flex',
    },
    statusBoxinside: {
      margin: 'auto',
    },
    statusText: {
      width: '20px',
      height: '20px',
      backgroundColor: '#79747E',
      color: 'white',
      borderRadius: '4px',
      textAlign: 'center',
      marginLeft: '0px',
      zIndex: 1,
      position: 'relative',
    },
    dividerLine: {
      position: 'absolute',
      bottom: '0px',
      left: '31px',
      height: '30px',
      backgroundColor: '#C4C4C4',
      width: '1px',
    },
    closedTicketText: {
      position: 'relative',
      top: '-2.5px',
      fontFamily: 'Archivo',
      fontSize: '13px',
      fontWeight: 600,
    },
    ticketsClosed: {
      fontSize: 15,
      fontWeight: 500,
    },
    ticketsClosedDate: {
      fontSize: 13,
      fontWeight: 300,
    },
    openTicketBox: {
      flexDirection: 'row',
      display: 'flex',
      padding: '12px 23px',
      position: 'relative',
    },
    openStatusBox: {
      width: '20px',
      height: '20px',
      backgroundColor: 'primary.main',
      color: 'white',
      borderRadius: '6px',
      textAlign: 'center',
      marginLeft: '0px',
      zIndex: 1,
      position: 'relative',
    },
    openStatusText: {
      position: 'relative',
      top: '-2.5px',
      fontFamily: 'Archivo',
      fontWeight: 600,
    },
    openTicketStatus: {
      position: 'absolute',
      top: '0px',
      left: '31px',
      height: '32px',
      backgroundColor: '#C4C4C4',
      width: '1px',
    },
    ticketsOpenedTextinside: {
      fontSize: 15,
      fontWeight: 400,
    },
    ticketsOpenedText: {
      fontWeight: 500,
    },
    ticketsOpenedDate: {
      fontSize: 13,
      fontWeight: 300,
    },
    reopenTicketBox: {
      position: 'fixed',
      right: '20px',
      bottom: '20px',
      textAlign: 'right',
      backgroundColor: '',
    },
  };

  const [alignment, setAlignment] = useState('');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
    '&.Mui-selected, &.Mui-selected:hover': {
      color: 'white',
      backgroundColor: selectedColor,
    },
  }));

  const [origins, setOrigins] = useState('');

  const getTicketOptions = async () => {
    //SLA
    // let slaArray = await optionService.getTicketSLA()
    // setSlas(slaArray)

    //ORIGIN
    let originArray = await optionService.getTicketOrigins();
    setOrigins(originArray);

    // //PRIORITY
    // let priorityArray = await optionService.getTicketPriority()
    // setPriorities(priorityArray)

    // let organizationArray = await organizationService.getOrganizations()
    // setOrganizations(organizationArray)

    // let usersArray = await userService.getAll()
    // setAllUsers(usersArray)

    // let typeArray = await optionService.getTicketTypes()
    // setTypes(typeArray)

    //STATUS
    // let statusArray = await optionService.getTicketStatus()
    // setStatuses(statusArray)
  };

  const [closeTicketDrawerOpen, setCloseTicketDrawerOpen] = useState(false);

  useEffect(() => {
    getTicketOptions();
  }, [ticket]);

  return (
    <>
      <Box
        sx={customStyles.fullContainer}
        className="col-3"
        // style={{ height: 'calc(100vh - 57px)', overflowX: 'hidden', overflowY: 'auto', position: 'relative', fontFamily: 'Archivo' }}
      >
        <Box sx={{ position: 'relative' }}>
          <Box sx={customStyles.titleBox}>
            <Typography variant="h5">Status</Typography>
          </Box>

          {ticket?.ticketStatusId == 2 && (
            <Box sx={customStyles.statusBox}>
              <Box className="col-2" sx={customStyles.statusBoxinside}>
                <Box sx={customStyles.statusText}>
                  <Typography
                    component="span"
                    sx={customStyles.closedTicketText}
                  >
                    C
                  </Typography>
                </Box>
              </Box>

              <Box sx={customStyles.dividerLine} />

              <Box className="col-10">
                <Typography component="span" sx={customStyles.ticketsClosed}>
                  Ticket Closed
                </Typography>
                <Box sx={{ mt: 0.5 }} />
                <Typography
                  component="span"
                  sx={customStyles.ticketsClosedDate}
                >
                  {ticket?.dateCreated &&
                    new Intl.DateTimeFormat('en-US', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true,
                      timeZone: 'America/Chicago',
                    }).format(new Date(ticket.dateCreated))}
                </Typography>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              ...customStyles.openTicketBox,
              backgroundColor:
                ticket?.ticketStatusId == 1 ? '#195FFB0D' : 'white',
            }}
          >
            <Box className="col-2" sx={customStyles.statusBoxinside}>
              <Box sx={customStyles.openStatusBox}>
                <Typography component="span" sx={customStyles.openStatusText}>
                  o
                </Typography>
              </Box>
            </Box>
            {ticket?.ticketStatusId == 2 && (
              <Box sx={customStyles.openTicketStatus} />
            )}

            <Box className="col-10">
              <Typography
                component="span"
                sx={customStyles.ticketsOpenedTextinside}
              >
                <Typography
                  component="span"
                  sx={customStyles.ticketsOpenedText}
                >
                  Ticket Opened{' '}
                </Typography>
                via{' '}
                {origins &&
                  origins?.find((x) => x.id == ticket?.ticketOriginId)?.name}
              </Typography>
              <Box sx={{ mt: 0.5 }} />
              <Typography component="span" sx={customStyles.ticketsOpenedDate}>
                {ticket?.dateCreated &&
                  new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                    timeZone: 'America/Chicago',
                  }).format(new Date(ticket.dateCreated))}
              </Typography>
            </Box>
          </Box>

          <Box sx={customStyles.reopenTicketBox}>
            <Button variant="contained" onClick={setModalIsOpenToTrue}>
              {props.ticket.ticketStatusId === 2 ? 'Reopen' : 'Close'} Ticket
            </Button>
          </Box>
          {/* Modal */}
          {/* {
                  props.ticket.ticketStatusId == 1 &&
                  <Modal isOpen={modalIsOpen} style={customStyles} appElement={document.getElementById('root') || undefined}>
                     <div style={{ justifyContent: 'center' }}>
                        <div style={{ fontFamily: 'Poppins', fontSize: 24, color: '#195FFB', fontWeight: 600, lineHeight: '23px', marginBottom: '25px' }}> Is this member's issue resolved? </div>

                        <ToggleButtonGroup style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '18px' }} onChange={handleChange} value={alignment} exclusive color='primary'>
                           <ToggleButton
                              id='yesButton' value="yes" onClick={yesNoButton} selectedColor="#195FFB" variant="outlined"
                              sx={{ border: '1px solid #195FFB!important', borderRadius: '100px!important', width: 74, color: '#195ffb' }}
                           >YES</ToggleButton>

                           <ToggleButton
                              id='noButton' value="no" onClick={yesNoButton} selectedColor="#195FFB" variant="outlined"
                              sx={{ border: '1px solid #195FFB!important', borderRadius: '100px!important', width: 74, color: '#195ffb' }}
                           >NO</ToggleButton>
                        </ToggleButtonGroup>

                        <textarea ref={textAreaRef} id="details" name="details" cols="35" rows="5" placeholder="Type details here..." style={{ borderRadius: '15px', resize: 'none', padding: '10px' }} ></textarea>

                        <br />
                        <br />

                        <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '10px' }}>
                           <Button sx={{ height: 40, width: 100, fontSize: 12, color: '#195FFB', fontFamily: 'Poppins', fontWeight: '300!important' }} onClick={setModalIsOpenToFalse}>Cancel</Button> &nbsp;
                           <Button variant='contained' size='small' sx={{ height: 40, width: 135, borderRadius: '33px', fontWeight: '300!important', fontFamily: 'Poppins' }} onClick={closeOrReopenTicket}>Close Ticket</Button>
                        </div>
                     </div>
                  </Modal>
               }
               {
                  props.ticket.ticketStatusId == 2 &&
                  <Modal isOpen={modalIsOpen} style={customStyles} appElement={document.getElementById('root') || undefined}>
                     <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', fontFamily: 'Poppins' }}>
                        <span style={{ fontSize: 24, color: '#195FFB', fontWeight: 600, }}> Reopen Ticket #{props.ticket.id ?? 0} </span>
                     </div>
                     <br />
                     <div style={{ textAlign: 'center', fontFamily: 'Poppins', }}>
                        <span > The member will not be notified until you send a communication. </span>
                     </div>
                     <br />
                     <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', gap: '10px' }}>
                        <Button sx={{ height: 40, width: 100, fontSize: 12, color: '#195FFB', fontFamily: 'Poppins', fontWeight: '300!important' }} onClick={setModalIsOpenToFalse}>Cancel</Button> &nbsp;
                        <Button variant='contained' size='small' sx={{ height: 40, width: 100, borderRadius: '33px', fontWeight: '300!important', fontFamily: 'Poppins' }} onClick={closeOrReopenTicket}>Reopen</Button>
                     </div>

                  </Modal>
               } */}
        </Box>
        <SingleviewCloseTicketDrawer
          status={props.ticket.ticketStatusId}
          closeOrReopenTicket={closeOrReopenTicket}
          closeTicketDrawerOpen={closeTicketDrawerOpen}
          setCloseTicketDrawerOpen={setCloseTicketDrawerOpen}
        />
      </Box>
    </>
  );
}
