import React, { useEffect, useState, useContext } from 'react';
import { format, differenceInCalendarYears, set } from 'date-fns';
import { useHistory } from 'react-router-dom';

//Assets
// import teamstersLogo from '../../../../assets/images/TeamstersLogo.png'
// import bankLogo from '../../../../assets/images/bankOfAmerica.png'

//Services
import memberService from '../../../../services/member-service';
import enrollmentService from '../../../../services/enrollment-service';
import walletService from '../../../../services/wallet-service';
import optionService from '../../../../services/optionService';
import organizationService from '../../../../services/organization-service';
import paymentsService from '../../../../services/payments-service';
import activeCoverageService from '../../../../services/activeCoverage-service';
import timelineService from '../../../../services/timeline-service';

//Mui icons
import CircleIcon from '@mui/icons-material/Circle';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SettingsIcon from '@mui/icons-material/Settings';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';

//Mui Components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FlexSnackbar from '../../../flex-snackbar/FlexSnackbar';

//Our Components
import MemberInfo from './member-info/MemberInfo';
import MemberPlansTab from './MemberTabs/member-plans-tab/MemberPlansTab';
import MemberTicketsTab from './MemberTabs/member-tickets-tab/MemberTicketsTab';
import MemberEnrollTab from './MemberTabs/agent-enroll-tab/MemberEnrollTab';
// import AgentEnrollmentWrapper from "./MemberTabs/agent-enroll-tab/AgentEnrollmentWrapper";
import MemberNotesTab from './MemberTabs/member-notes-tab/MemberNotesTab';
import MemberSettingsTab from './MemberTabs/member-settings-tab/MemberSettingsTab';
import MemberPaymentsTab from './MemberTabs/member-payments-tab/MemberPaymentsTab';
import MemberDocumentsTab from './MemberTabs/member-documents-tab/MemberDocumentsTab';
import MemberHistoryTab from './MemberTabs/member-history-tab/MemberHistoryTab';
import { thisUserContext } from '../../nav-container/NavContainer';

//utils
import {
  formatUTCYYYYMMDD,
  day,
  month,
  formatShortDateToUTC,
  year,
  todayDateObj,
  todayDateShort,
  formatUTCDateToObjWithTimeZone,
} from '../../../utils';

const componentStyles = {
  memberProfileContainer: {
    display: 'flex',
    flexDirection: 'row',
    bgcolor: 'background.default',
    color: 'text.primary',
    width: '100%',
    // flexWrap: "wrap",
  },
  tabAndBodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 56px)',
  },
  tabRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '100%',
    height: '48px',
    paddingLeft: '10px',
    borderBottom: 'solid 1px',
    borderColor: 'background.border',
  },
  tabContainer: (activePage) => ({
    color: activePage ? 'primary.main' : 'text.gray',
    fontWeight: 500,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: '0px',
    cursor: 'pointer',
    padding: '4px 0px',
  }),
  tabOutline: (activePage) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    padding: '8px 16px',
    height: '28px',
    backgroundColor: activePage ? '#195FFB26' : '',
  }),
  tabText: {
    fontSize: '13px',
    fontWeight: 500,
    fontFamily: 'Archivo',
    paddingLeft: '7px',
  },
  icon: {
    height: '20px',
  },
};

export default function MemberProfile(props) {
  const history = useHistory();
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  let percentFormatter = new Intl.NumberFormat('en-US', { style: 'percent' });

  const formatDate = (rawDate) => {
    let [yyyy, mm, dd, hh, m, s] = rawDate.split(/[^\d]+/);
    let date = new Date();
    date.setUTCFullYear(+yyyy);
    date.setUTCDate(+dd);
    date.setUTCMonth(mm - 1);
    date.setUTCHours(+hh);
    date.setUTCMinutes(+m + date.getTimezoneOffset());
    date.setUTCSeconds(+s);
    return date;
  };

  let _thisUserContext = useContext(thisUserContext);

  const [permissionsEnabler, setPermissionsEnabler] = useState();
  useEffect(() => {
    if (_thisUserContext)
      setPermissionsEnabler(_thisUserContext.permissionsEnabler);
  }, [_thisUserContext]);

  // Page Navigation
  const [activePage, setActivePage] = useState(0);

  const handleActivePageChange = (event) => {
    let newVal = Number(event.target.getAttribute('value'));
    if (activePage == newVal) {
      setSelectedPlan();
    }
    setActivePage(newVal);
  };

  //Member
  const [thisMember, setThisMember] = useState();
  let getThisMember = async (memberId) => {
    let _member;
    try {
      _member = await memberService.getMemberByIdAsync(props.match?.params?.id);
    } catch (error) {
      console.error('error getting member in member profile', error);
    }

    setThisMember(_member);
    setProfileDisabled(_member?.isArchived);
  };

  useEffect(() => {
    if (props.match?.params?.id) {
      let memberId = Number(props.match?.params?.id);
      if (memberId > 0) {
        getThisMember(memberId);
        // getDependents()
        getPhones();
        getActiveCoverage(memberId);
      }
    }
  }, [props.match?.params?.id]);

  //member profile archived disable
  const [profileDisabled, setProfileDisabled] = useState(false);
  let disabledProfileStyle = {
    '& *:not(.archiveAlert)': {
      pointerEvents: profileDisabled && 'none',
      cursor: profileDisabled && 'default',
      boxShadow: profileDisabled && 'none',
      filter: profileDisabled && 'grayscale(80%)',
      opacity: profileDisabled && '0.9',
    },
  };
  let disabledProfileStyleWithPath = {
    '& *:not(.archiveAlert) :not(path)': {
      pointerEvents: profileDisabled && 'none',
      cursor: profileDisabled && 'default',
      boxShadow: profileDisabled && 'none',
      filter: profileDisabled && 'grayscale(80%)',
      opacity: profileDisabled && '0.9',
    },
  };

  //PHONE LOGIC
  const [phones, setPhones] = useState([]);
  // const [primaryPhone, setPrimaryPhone] = useState();
  const [primaryPhone, setPrimaryPhone] = useState({});
  const [secondaryPhone, setSecondaryPhone] = useState({});

  const getPhones = async () => {
    let memberId = Number(props.match?.params?.id);
    let _phones;
    try {
      _phones = await memberService.getPhonesForMember(memberId);
    } catch (error) {
      console.error('error getting phones in agent wrapper', error);
    }
    setPhones(_phones);

    let preferredNumber = _phones?.find((phone) => {
      if (phone.isPreferred) {
        return phone;
      }
    });

    let secondPhoneNumber = _phones?.find((phone) => {
      if (phone.isPreferred === false) {
        return phone;
      }
    });
    setPrimaryPhone(preferredNumber ? preferredNumber : {});
    setSecondaryPhone(secondPhoneNumber ? secondPhoneNumber : {});
  };

  const handlePhoneInsert = async () => {
    //if primary or secondary numbers already exist, update these numbers w/ phone id
    if (primaryPhone && primaryPhone.id) {
      try {
        await memberService.updatePhoneForMember(primaryPhone);
      } catch (error) {
        console.error('error updating primary/mobile phone', error);
      }
    }
    if (secondaryPhone && secondaryPhone.id) {
      try {
        await memberService.updatePhoneForMember(secondaryPhone);
      } catch (error) {
        console.error('error updating secondary/home phone', error);
      }
    }
    //  //if primary or secondary numbers don't exist, but user has added new data, create these numbers w/ NO phone id
    if (primaryPhone.number && !primaryPhone.id) {
      try {
        await memberService.insertPhoneForMember(primaryPhone);
      } catch (error) {
        console.error('error inserting a new primary/mobile phone', error);
      }
    }
    if (secondaryPhone.number && !secondaryPhone.id) {
      try {
        await memberService.insertPhoneForMember(secondaryPhone);
      } catch (error) {
        console.error('error inserting a new secondary/home phone', error);
      }
    }
    getPhones();
  };

  //SPOUSE
  const [spouseInDb, setSpouseInDb] = useState({});
  const [spouse, setSpouse] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    informationAuthorize: false,
    coverageAuthorize: null,
    spouseInDb: false, //Dont send this property back
  });
  const getSpouse = async (e) => {
    let _spouse;
    try {
      _spouse = await memberService.getSpouseForMember(thisMember.id);
      if (_spouse) {
        _spouse.spouseInDb = true;
        if (_spouse.dateOfBirth) {
          _spouse.dateOfBirth = format(
            formatDate(_spouse?.dateOfBirth),
            'yyyy-MM-dd'
          );
        }
      } else {
        _spouse = {
          firstName: '',
          lastName: '',
          dateOfBirth: '',
          email: '',
          informationAuthorize: false,
          coverageAuthorize: null,
          spouseInDb: false,
        };
      }
      setSpouse(_spouse);
      setSpouseInDb({ ..._spouse });
    } catch (error) {
      _spouse = {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        email: '',
        informationAuthorize: false,
        coverageAuthorize: null,
        spouseInDb: false,
      };
      setSpouse(_spouse);
    }
  };

  let saveSpouseInfo = async () => {
    //spouseInDb must exist, compare the new spouse info to spouseInDb to make sure its actually new info
    if (
      spouse.spouseInDb &&
      JSON.stringify(spouse) != JSON.stringify(spouseInDb)
    ) {
      let updateSpouseObj = { ...spouse };
      //remove the previous spouseInDb info
      delete updateSpouseObj.spouseInDb;
      try {
        await memberService.updateSpouseForMember(
          thisMember.id,
          updateSpouseObj
        );
      } catch (error) {
        console.error('error updating spouse', error);
      }
    } else if (!spouse.spouseInDb && spouse.firstName != '') {
      let addSpouseObj = { ...spouse };
      delete addSpouseObj.spouseInDb;
      try {
        await memberService.addSpouseForMember(thisMember.id, addSpouseObj);
      } catch (error) {
        console.error('error adding spouse', error);
      }
    }
    getSpouse();
  };

  let updateMember = async (updatedInfo) => {
    let overwritten = Object.assign({}, thisMember, updatedInfo);

    //update member info besides organization
    try {
      await memberService.updateForMember(thisMember.id, overwritten);
    } catch (error) {
      console.error('error updating member', error);
    }

    //update organization
    //check if a organizationId on updatedInfo is different from original thisMember obj
    if (updatedInfo?.organizationId !== thisMember?.organizationId) {
      let updatedMemberOrg = {
        memberId: thisMember?.id,
        organizationId: updatedInfo?.organizationId,
        isPrimary: true,
      };

      try {
        await memberService.updateMemberOrganization(updatedMemberOrg);
      } catch (error) {
        console.error('error updating member organization', error);
      }
    }

    //refresh member
    getThisMember();
    //refresh history
    setTimeout(() => {
      refreshHistory();
    }, 500);
  };

  //Dependent logic
  const [dependentsInDB, setDependentsInDB] = useState([]);
  const [dependents, setDependents] = useState([]);
  const getDependents = async () => {
    let _dependents;
    try {
      _dependents = await memberService.getDependentsForMember(thisMember.id);
    } catch (error) {
      console.error('error getting deps', error);
    }
    let _dependentsInDB = [];

    if (_dependents?.length > 0) {
      _dependents.forEach((_dependent) => {
        if (_dependent.dob) {
          _dependent.dob = format(formatDate(_dependent.dob), 'yyyy-MM-dd');
          // _dependent.dob = `${_dependent.dob}T00:00:00`;
        }
        _dependentsInDB.push({ ..._dependent });
      });
    }
    setDependentsInDB(_dependentsInDB);
    setDependents(_dependents);
  };
  let saveDependents = async () => {
    for (let editDependent of dependents) {
      let dependentInDB = dependentsInDB.find(
        (DBdependent) => DBdependent.id == editDependent.id
      );
      //check for delete dep
      if (dependentInDB && editDependent.isDeleted != dependentInDB.isDeleted) {
        try {
          await memberService.deleteDependentForMember(
            thisMember.id,
            editDependent.id
          );
        } catch (error) {
          console.error('error deleting dep', error);
        }
      }
      //check for updated dep
      else if (
        dependentInDB &&
        JSON.stringify(editDependent) != JSON.stringify(dependentInDB)
      ) {
        try {
          await memberService.updateDependentForMember(
            thisMember.id,
            editDependent
          );
        } catch (error) {
          console.error('error uopdating dep', error);
        }
      }

      //check for new dep
      else if (!dependentInDB) {
        try {
          await memberService.addDependentForMember(
            thisMember.id,
            editDependent
          );
        } catch (error) {
          console.error('error adding dep', error);
        }
      }
    }
  };

  let addDependent = async (dependent) => {
    await memberService.addDependentForMember(thisMember.id, dependent);
    getDependents();
  };

  let deleteDependent = async (dependentId) => {
    await memberService.deleteDependentForMember(thisMember.id, dependentId);
    getDependents();
  };

  //Language types
  const [languageTypes, setLanguageTypes] = useState([]);
  const getLanguageTypes = async () => {
    try {
      let _languageTypes = await optionService.getLanguageTypes();
      setLanguageTypes(_languageTypes);
    } catch (error) {
      console.error('error getting language types', error);
    }
  };

  //Member Enrollments
  const [availableEnrollments, setAvailableEnrollments] = useState([]);
  const [availablePlans, setAvailablePlans] = useState([]);
  const [enrollments, setEnrollments] = useState([]);

  let getEnrollments = async () => {
    let _enrollments = await enrollmentService.getEnrollmentsByMemberId(
      thisMember.id
    );
    setEnrollments(_enrollments);
    let selectedInArray;

    for (let enrollment of _enrollments) {
      if (
        selectedEnrollment?.enrollmentId == 0 &&
        enrollment?.groupPlanId == selectedEnrollment?.groupPlanId &&
        enrollment?.name == selectedEnrollment?.name &&
        enrollment?.enrollmentWindowId ==
          selectedEnrollment?.enrollmentWindowId &&
        enrollment?.isEnrollmentOpen == true
      )
        selectedInArray = enrollment;
      else if (enrollment.enrollmentId == selectedEnrollment?.enrollmentId)
        selectedInArray = enrollment;
    }
    if (selectedInArray) {
      setSelectedEnrollment(selectedInArray);
    }
  };

  useEffect(() => {
    if (thisMember?.id > 0) {
      getEnrollments();
      getPaymentSchedule();
      getOneTimePayments();
      getSpouse();
      getDependents();
    }
  }, [thisMember?.id]);

  //get bank accounts - refresh if member OR permissions change
  useEffect(() => {
    getBankAccount();
  }, [thisMember?.id, permissionsEnabler?.viewFullBankAccounts]);

  //OPTION CALLS
  useEffect(() => {
    getBankAccountTypes();
    getPaymentFrequencies();
    getPaymentTransactionTypes();
    getPaymentStatuses();
    getBankAccountPrenoteStatuses();
    getEnrollmentStates();
    getRelationshipTypes();
    getCancellationReasons();
    getLanguageTypes();
  }, []);

  //Selected Plan
  const [selectedPlan, setSelectedPlan] = useState();

  //Split our enrollments between closed viewable plans, and open enrollments
  useEffect(() => {
    if (enrollments?.length > 0) {
      let _enrollments = [...enrollments];
      let _availablePlans = [];
      let _availableEnrollments = [];

      for (let enrollmentObj of _enrollments) {
        //if they're enrolled and enrollment is closed, push to availablePlans
        if (enrollmentObj.isEnrolled && !enrollmentObj.isEnrollmentOpen) {
          _availablePlans.push(enrollmentObj);
        }
        //open enrollment, push to availableEnrollments
        else if (enrollmentObj.isEnrollmentOpen) {
          _availableEnrollments.push(enrollmentObj);
        }
      }

      setAvailablePlans(_availablePlans);
      setAvailableEnrollments(_availableEnrollments);

      //DEBUG
      // setAvailablePlans(_availableEnrollments);
    } else {
      setAvailablePlans([]);
      setAvailableEnrollments([]);
    }
  }, [enrollments, selectedPlan?.enrollmentStateId]);

  //Selected Enrollment
  const [selectedEnrollment, setSelectedEnrollment] = useState();

  const [newEnrollment, setNewEnrollment] = useState(false);

  let getOneEnrollment = async (enrollment) => {
    if (enrollment.enrollmentId != 0) {
      let _enrollment;
      try {
        _enrollment = await enrollmentService.getEnrollmentById(
          enrollment.enrollmentId
        );
      } catch (error) {
        console.error('error getting enrollment in getOneEnrollment', error);
      }
      setSelectedEnrollment(_enrollment);
    } else {
      setNewEnrollment(true);
      setSelectedEnrollment(enrollment);
    }
  };

  //ACTIVE COVERAGE LOGIC

  const [activeCoverage, setActiveCoverage] = useState([]);

  //this get's all current and past active coverages including modified/cancelled past coverages
  //organized as individual object per line of coverage
  //does not get future coverages
  const getActiveCoverage = async (memberId) => {
    let _activeCoverage;
    try {
      _activeCoverage = await activeCoverageService.getActiveCoveragebyMemberId(
        memberId
      );
    } catch (error) {
      console.error('error getting active coverage', error);
    }
    if (_activeCoverage && _activeCoverage?.length > 0) {
      setActiveCoverage(_activeCoverage);
    }
  };

  //separate active coverage into current, past, and future coverages
  const [currentActiveCoverage, setCurrentActiveCoverage] = useState([]);
  const [pastActiveCoverage, setPastActiveCoverage] = useState([]);
  const [futureActiveCoverage, setFutureActiveCoverage] = useState([]);

  //separate active coverages by group plan name and enrollmentId AND date (past, current, future)
  useEffect(() => {
    if (activeCoverage?.length > 0) {
      let currentDate = new Date();
      let currentDateYyyyDdMm = currentDate.toISOString()?.slice(0, 10);
      let _currentActiveCoverage = [];
      let _pastActiveCoverage = [];
      let _futureActiveCoverage = [];
      let _enrollments = [...enrollments];
      let _activeEnrollmentId;

      _activeEnrollmentId = _enrollments?.find((enrollment) => {
        return enrollment.isEnrollmentOpen;
      });
      if (!_activeEnrollmentId) _activeEnrollmentId = 0;

      //separate based on begin and end dates
      activeCoverage?.forEach((coverage) => {
        //if _activeEnrollmentId is 0, then we don't have an open enrollment that matches enrollmentId for AC, may have upcoming coverages that are not active for a few days following enrollment window close and before coverage effective day starts - temporarily put these upcoming coverages into currentActiveCoverage until we redesign plans tab and have upcoming section
        if (
          coverage.endDate >= currentDateYyyyDdMm &&
          (_activeEnrollmentId === 0 ||
            coverage.beginDate <= currentDateYyyyDdMm)
        ) {
          _currentActiveCoverage.push(coverage);
        } else if (coverage.endDate < currentDateYyyyDdMm) {
          _pastActiveCoverage.push(coverage);
        } else {
          _futureActiveCoverage.push(coverage);
        }
      });

      //organize current lines of coverage into objects based on enrollment ID. ex: [{enrollmentId: [coverage1, coverage2]}]
      let _currentActiveCoverageByEnrollmentId = [];
      if (_currentActiveCoverage?.length > 0) {
        _currentActiveCoverageByEnrollmentId = Object.values(
          _currentActiveCoverage.reduce((acc, coverage) => {
            if (!acc[coverage.enrollmentId]) {
              acc[coverage.enrollmentId] = [];
            }
            acc[coverage.enrollmentId].push(coverage);
            return acc;
          }, {})
        ).map((coverage) => {
          const enrollmentId = coverage[0].enrollmentId;
          const groupPlanName = coverage[0].groupPlanName;
          const groupPlanId = coverage[0]?.groupPlanId;
          return {
            enrollmentId: enrollmentId,
            groupPlanName: groupPlanName,
            groupPlanId: groupPlanId,
            linesOfCoverage: coverage,
          };
        });
      }

      //organize lines of future AC based on enrollment ID
      let _futureActiveCoverageByEnrollmentId = [];
      if (_futureActiveCoverage?.length > 0) {
        _futureActiveCoverageByEnrollmentId = Object.values(
          _futureActiveCoverage.reduce((acc, coverage) => {
            if (!acc[coverage.enrollmentId]) {
              acc[coverage.enrollmentId] = [];
            }
            acc[coverage.enrollmentId].push(coverage);
            return acc;
          }, {})
        ).map((coverage) => {
          const enrollmentId = coverage[0].enrollmentId;
          const groupPlanName = coverage[0].groupPlanName;
          const groupPlanId = coverage[0]?.groupPlanId;
          return {
            enrollmentId: enrollmentId,
            groupPlanName: groupPlanName,
            groupPlanId: groupPlanId,
            linesOfCoverage: coverage,
          };
        });
      }

      setCurrentActiveCoverage(_currentActiveCoverageByEnrollmentId);
      setPastActiveCoverage(_pastActiveCoverage);
      setFutureActiveCoverage(_futureActiveCoverageByEnrollmentId);
    }
  }, [activeCoverage, enrollments, selectedEnrollment?.enrollmentStateId]);

  // getting plan costs from the calc premium stuffs
  const [totalPlanCosts, setTotalPlanCosts] = useState();
  //get costs for ALL active coverage combined for a single member
  const getTotalPlanCosts = async () => {
    let _totalCosts;

    for (let coverage of currentActiveCoverage) {
      let _coverageCost;
      try {
        _coverageCost = await memberService.getMemberCosts(
          props.match?.params?.id,
          coverage?.enrollmentId
        );
      } catch (error) {
        console.error('error getting total plan costs', error);
      }
      if (!_totalCosts) _totalCosts = _coverageCost;
      else {
        //if more than one plan, add the costs together for each match ing line of coverage
        for (let [key, value] of Object.entries(_coverageCost)) {
          if (_totalCosts[key]) {
            let _value = Number(value);
            _totalCosts[key] += _value;
          } else _totalCosts[key] = Number(value);
        }
      }
    }
    setTotalPlanCosts(_totalCosts);
  };
  //refresh total cost of current AC
  useEffect(() => {
    // only grab total cost of all plans, enrollment and plan costs if we have data here
    if (currentActiveCoverage?.length > 0) {
      getTotalPlanCosts();
    }
  }, [currentActiveCoverage?.length]);

  //get bank accounts - full vs. last 4 depending on permissions
  const [bankAccount, setBankAccount] = useState([]);
  let getBankAccount = async () => {
    let _bankAccount = [];
    if (permissionsEnabler?.viewFullBankAccounts) {
      try {
        _bankAccount = await walletService.getFullBankAccountForMember(
          props.match?.params?.id
        );
      } catch (error) {
        console.error('Error getting full bank account', error);
      }
    } else {
      try {
        _bankAccount = await walletService.getBankAccountsForMember(
          props.match?.params?.id
        );
      } catch (error) {
        console.error('error getting bank account for member', error);
      }
    }
    setBankAccount(_bankAccount);
  };

  //get list of Bank account Types
  const [bankAccountTypes, setBankAccountTypes] = useState([]);

  let getBankAccountTypes = async () => {
    let accountTypes = await optionService.getBankAccountTypes();
    setBankAccountTypes(accountTypes);
  };

  //get wallet stuffs -> payment schedule
  const [paymentSchedule, setPaymentSchedule] = useState([]);

  //need to add the GET ALL payment schedules here in member profile - not enrollment specific for display
  let getPaymentSchedule = async () => {
    let _paymentSchedule = [];
    try {
      _paymentSchedule = await walletService.getAllPaymentSchedulesForMember(
        props.match?.params?.id
      );
    } catch (error) {
      console.error('error getting payment schedule', error);
    }
    //filter out only schedules on PeakPay
    if (_paymentSchedule?.length > 0) {
      let _schedulesOnPeakPay = _paymentSchedule?.filter(
        (schedule) => schedule?.isPeakPay
      );
      setPaymentSchedule(_schedulesOnPeakPay);
    } else {
      setPaymentSchedule(_paymentSchedule);
    }
  };

  //get any one-time payments that have been scheduled
  const [oneTimePayments, setOneTimePayments] = useState([]);
  const getOneTimePayments = async () => {
    try {
      let _oneTimePayments = await paymentsService.getOneTimePaymentsByMemberId(
        thisMember?.id
      );

      setOneTimePayments(_oneTimePayments);
    } catch (error) {
      console.error('error getting one-time payments', error);
    }
  };

  ///--------DISPLAY NEXT PAYMENT CHIP LOGIC--------///
  // Updated Logic: determine if we should display next payment chip or not - do not want to render an empty component if no payment schedules or one time payments exist
  const [displayNextPaymentChip, setDisplayNextPaymentChip] = useState({
    paymentScheduleExist: null,
    oneTimePaymentsExist: null,
    nearestFutureOneTimePayment: null,
  });

  let handlePayChipDisplay = () => {
    let displayObj = { ...displayNextPaymentChip };
    //check if payment schedules exist and if they are enabled
    if (paymentSchedule?.length > 0) {
      if (
        (paymentSchedule[0]?.calculatedNextOccurrence &&
          paymentSchedule[0]?.calculatedNextOccurrence <
            paymentSchedule[0]?.endPaymentDate &&
          paymentSchedule[0]?.isEnabled) ||
        (paymentSchedule[1]?.calculatedNextOccurrence &&
          paymentSchedule[1]?.calculatedNextOccurrence <
            paymentSchedule[1]?.endPaymentDate &&
          paymentSchedule[1]?.isEnabled) ||
        (paymentSchedule[1]?.calculatedNextOccurrence &&
          paymentSchedule[0]?.calculatedNextOccurrence <
            paymentSchedule[0]?.endPaymentDate &&
          paymentSchedule[1]?.calculatedNextOccurrence <
            paymentSchedule[1]?.endPaymentDate)
      ) {
        displayObj.paymentScheduleExist = true;
      }
    }
    //check if one time payments exist and if any are scheduled for today or future, if not, set back to null
    if (oneTimePayments?.length > 0) {
      let oneTimePaymentsInFuture = oneTimePayments?.filter((payment) => {
        if (payment?.occurrence) {
          //compare short date strings
          return formatUTCYYYYMMDD(payment?.occurrence) >= todayDateShort;
        }
      });

      if (oneTimePaymentsInFuture?.length > 0) {
        displayObj.oneTimePaymentsExist = true;
        //filter out one time payment with nearest occurrence in future
        let nearestOneTimePayment = oneTimePaymentsInFuture?.reduce((a, b) => {
          return a.occurrence < b.occurrence ? a : b;
        });
        displayObj.nearestFutureOneTimePayment = nearestOneTimePayment;
      }
    } else {
      displayObj.oneTimePaymentsExist = null;
      displayObj.nearestFutureOneTimePayment = null;
    }
    //set state for display at end
    // Update the state with the new values
    setDisplayNextPaymentChip((prevState) => ({
      ...prevState,
      ...displayObj, // This will spread the displayObj values into the state
    }));
  };

  useEffect(() => {
    if (paymentSchedule?.length > 0 || oneTimePayments?.length > 0) {
      handlePayChipDisplay();
    }
  }, [paymentSchedule, oneTimePayments, oneTimePayments?.length]);

  // ------------- 5 Day queue and next payment chip additional logic ----------------
  const [currentCombinedPaymentInfo, setCurrentCombinedPaymentInfo] = useState({
    activePaymentSchedule: null,
    nextOccurrenceFromActiveSchedule: null,
    nextOccurrenceShortDate: null,
    scheduleHasNextOccWithin5DaysInPast: null, //render message and modal if this is true (orange chip in modal)
    calculatedNextOccurrence: null,
    calculatedNextOccurrenceShortDate: null,
    futureOneTimePayment: null, //render message and modal if this is true (blue chip in modal)
    nextScheduledPaymentDate: null, //render green - Automatic pay chip in modal (green chip in modal)
    nextScheduledPaymentDateShort: null,
  });

  //manually calculate next payment date based on schedule - need to do this in JS in case a pay schedule next occurrence is stuck in the past and needs to be reset
  const calculateNextPaymentDate = (schedule) => {
    let schedulePaymentDatesArray = schedule?.scheduledPaymentDates?.map(
      (date) => {
        let day = date.dayOfMonth;
        return day;
      }
    );
    //sort (numerically) and format scheduledPaymentDates to be in leading 0 format
    let schedulePaymentDatesArrayFormatted = schedulePaymentDatesArray
      ?.sort((a, b) => a - b)
      .map((day) => {
        return day < 10 ? '0' + day : day;
      });

    //date pieces to help build the date
    let currentDay = day;
    let nextMonth = month + 1;
    const nextYear = year + 1;

    //format day and month w/ potential leading 0 AFTER doing calculations
    let formattedMonth = month < 10 ? '0' + month : month;
    nextMonth = nextMonth < 10 ? '0' + nextMonth : nextMonth;

    let builtPaymentDay;

    //if schedule begin date is in past
    if (schedule.beginPaymentDate < todayDateShort) {
      //determine next schedule date if one payment date per month
      if (
        schedulePaymentDatesArray &&
        schedulePaymentDatesArray?.length === 1
      ) {
        //if today is after scheduled payment day, need to go to next month, else create date in current month
        if (currentDay > schedulePaymentDatesArray[0]) {
          //need to add month - but check that we're not currently in december, otherwise we need to build 1-day -nextYEar
          if (month !== 12) {
            builtPaymentDay = `${year}-${nextMonth}-${schedulePaymentDatesArrayFormatted[0]}`;
          } else {
            builtPaymentDay = `${nextYear}-01-${schedulePaymentDatesArrayFormatted[0]}`;
          }
        } else {
          builtPaymentDay = `${year}-${formattedMonth}-${schedulePaymentDatesArrayFormatted[0]}`;
        }
      }

      //determine next schedule date if two payment dates per month
      if (schedulePaymentDatesArray && schedulePaymentDatesArray?.length > 1) {
        //if today is after 1st payment day and before or equal to 2nd day , need to set to 2nd payment day of this month
        if (
          currentDay > schedulePaymentDatesArray[0] &&
          currentDay <= schedulePaymentDatesArray[1]
        ) {
          builtPaymentDay = `${year}-${formattedMonth}-${schedulePaymentDatesArrayFormatted[1]}`;
          // checking to see if current day is before first day
        } else if (currentDay < schedulePaymentDatesArray[0]) {
          builtPaymentDay = `${year}-${formattedMonth}-${schedulePaymentDatesArrayFormatted[0]}`;
          //checking to see if current day is after second pay day - if yes, create date in next month w/ first payment day
        } else if (currentDay > schedulePaymentDatesArray[1]) {
          //check month
          if (month !== 12) {
            builtPaymentDay = `${year}-${nextMonth}-${schedulePaymentDatesArrayFormatted[0]}`;
          } else {
            builtPaymentDay = `${nextYear}-01-${schedulePaymentDatesArrayFormatted[0]}`;
          }
        }
      }
    } else {
      //if active schedule has a future begin date (this can happen when an agent disables old schedule after changing coverage that does not go into effect until end of month)
      let beginDateObj = formatUTCDateToObjWithTimeZone(
        schedule.beginPaymentDate
      );
      let beginDateMonth = beginDateObj.getMonth() + 1;
      beginDateMonth =
        beginDateMonth < 10 ? '0' + beginDateMonth : beginDateMonth;
      let beginDateYear = beginDateObj.getFullYear();
      //this schedule is set to start in the future so do not need to compare where we are in current month, only need to find month of begin date and first day of scheduled dates
      if (
        schedulePaymentDatesArray &&
        beginDateMonth &&
        beginDateYear &&
        schedulePaymentDatesArray?.length >= 1
      ) {
        builtPaymentDay = `${beginDateYear}-${beginDateMonth}-${schedulePaymentDatesArrayFormatted[0]}`;
      }
    }

    //format date
    let formattedDate;
    if (builtPaymentDay) {
      formattedDate = formatShortDateToUTC(builtPaymentDay);
    }
    return formattedDate;
  };

  //-----------Current Pay Schedule, Upcoming Payment Info, and 5 day queue logic----------------

  //NextOccurrence per pay schedule object is related directly to the next scheduled payment date for that specific payment schedule, can be different on both payment schedules if member has multiple payment schedules
  //We want to check that the NextOccurrence for the active schedule is today or in the future
  //Update currentCombinedPaymentInfo based on paymentSchedule and currentDate
  useEffect(() => {
    if (
      paymentSchedule?.length > 0 ||
      displayNextPaymentChip?.nearestFutureOneTimePayment
    ) {
      let _currentCombinedPaymentInfoObj = {
        ...currentCombinedPaymentInfo,
      };
      //find currently active schedule and make sure its enabled
      //for two payment schedules, need to also check that endPaymentDate is in future of next occurrence and calculated next occurrence date date (next date schedule should run), if not, select future upcoming schedule because currently 'active schedule is ending and has already passed the final scheduled date to run before the schedule is set to end
      const _activePaymentSchedule = paymentSchedule?.find((schedule) => {
        if (
          new Date(schedule.beginPaymentDate) <= todayDateObj &&
          new Date(schedule.endPaymentDate) >=
            new Date(schedule.nextOccurance) &&
          new Date(schedule.endPaymentDate) >=
            new Date(schedule.calculatedNextOccurrence) &&
          schedule.isEnabled
        ) {
          return schedule;
        } else if (
          new Date(schedule.beginPaymentDate) >= todayDateObj &&
          new Date(schedule.endPaymentDate) >=
            new Date(schedule.nextOccurance) &&
          new Date(schedule.endPaymentDate) >=
            new Date(schedule.calculatedNextOccurrence) &&
          schedule?.isEnabled
        ) {
          return schedule;
        } else {
          return null;
        }
      });

      //set scheduleHasNextOccWithin5DaysInPast to any enabled payment schedule that has a nextOccurrence date in the past
      const _scheduleHasNextOccWithin5DaysInPast = paymentSchedule.find(
        (schedule) => {
          let _nextOccShortDate = formatUTCYYYYMMDD(schedule.nextOccurance);
          let _checkIfDateWithin5Days =
            checkIfDateWithin5Days(_nextOccShortDate);
          if (_checkIfDateWithin5Days && schedule.isEnabled) {
            return schedule;
          }
        }
      );

      //update obj based on active schedule found
      if (_activePaymentSchedule) {
        const _nextOccShortDate = formatUTCYYYYMMDD(
          _activePaymentSchedule.nextOccurance
        );
        const _calculatedNextOccurrenceShort = formatUTCYYYYMMDD(
          _activePaymentSchedule.calculatedNextOccurrence
        );

        let formattedNextScheduledPaymentDate = calculateNextPaymentDate(
          _activePaymentSchedule
        );
        // format next scheduled payment date to short date
        let _nextScheduledPaymentDateShort = formattedNextScheduledPaymentDate
          ? formatUTCYYYYMMDD(formattedNextScheduledPaymentDate)
          : null;

        _currentCombinedPaymentInfoObj = {
          ..._currentCombinedPaymentInfoObj,
          activePaymentSchedule: _activePaymentSchedule,
          nextOccurrenceFromActiveSchedule:
            _activePaymentSchedule?.nextOccurance,
          nextOccurrenceShortDate: _nextOccShortDate,
          scheduleHasNextOccWithin5DaysInPast:
            _scheduleHasNextOccWithin5DaysInPast,
          calculatedNextOccurrence:
            _activePaymentSchedule?.calculatedNextOccurrence,
          calculatedNextOccurrenceShortDate: _calculatedNextOccurrenceShort,
          nextScheduledPaymentDate: formattedNextScheduledPaymentDate,
          nextScheduledPaymentDateShort: _nextScheduledPaymentDateShort,
        };
      } else {
        // Reset schedule-related fields when no active schedule
        _currentCombinedPaymentInfoObj = {
          ..._currentCombinedPaymentInfoObj,
          activePaymentSchedule: null,
          nextOccurrenceFromActiveSchedule: null,
          nextOccurrenceShortDate: null,
          scheduleHasNextOccWithin5DaysInPast: null,
          calculatedNextOccurrence: null,
          calculatedNextOccurrenceShortDate: null,
          nextScheduledPaymentDate: null,
          nextScheduledPaymentDateShort: null,
        };
      }

      //update obj based on future one time payments found
      _currentCombinedPaymentInfoObj = {
        ..._currentCombinedPaymentInfoObj,
        futureOneTimePayment:
          displayNextPaymentChip?.nearestFutureOneTimePayment,
      };

      //update obj based on payment schedule stuck in 5 day queue - many NOT be an active schedule
      _currentCombinedPaymentInfoObj = {
        ..._currentCombinedPaymentInfoObj,
        scheduleHasNextOccWithin5DaysInPast:
          _scheduleHasNextOccWithin5DaysInPast,
      };

      //set state with updated object
      setCurrentCombinedPaymentInfo((prevState) => ({
        ...prevState,
        ..._currentCombinedPaymentInfoObj,
      }));
    }
  }, [
    paymentSchedule,
    displayNextPaymentChip,
    displayNextPaymentChip?.nearestFutureOneTimePayment,
    todayDateShort,
  ]);

  //check if a past date is within 5 days of today
  const checkIfDateWithin5Days = (dateStr, timePeriod) => {
    if (!dateStr) return;
    let isWithin5Days = false;
    // Convert the date strings to Date objects
    const dateObj = new Date(dateStr);

    //check if nextOccurrence is in future return false and exit this check
    if (dateStr >= todayDateShort) return false;

    // Calculate the difference in time (in milliseconds)
    let timeDifference = Math.abs(todayDateObj - dateObj);

    // Convert the time difference to days (1000 ms * 60 seconds * 60 minutes * 24 hours)
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    // Check if the difference is within 5 days
    if (daysDifference <= 5) {
      isWithin5Days = true;
    }
    return isWithin5Days;
  };

  const [PaymentFrequencies, setPaymentFrequencies] = useState([]);
  let getPaymentFrequencies = async () => {
    let _paymentFrequencies = await optionService.getPaymentFrequencies();
    setPaymentFrequencies(_paymentFrequencies);
  };

  const [paymentTransactionTypes, setPaymentTransactionTypes] = useState([]);
  let getPaymentTransactionTypes = async () => {
    let _paymentTransactionTypes =
      await optionService.getPaymentTransactionTypes();
    setPaymentTransactionTypes(_paymentTransactionTypes);
  };

  const [paymentStatuses, setPaymentStatuses] = useState([]);
  let getPaymentStatuses = async () => {
    let _paymentStatuses = await optionService.getPaymentStatuses();
    setPaymentStatuses(_paymentStatuses);
  };

  const [bankAccountPrenoteStatuses, setBankAccountPrenoteStatuses] = useState(
    []
  );
  let getBankAccountPrenoteStatuses = async () => {
    let _bankAccountPrenoteStatuses =
      await optionService.getBankAccountPrenoteStatuses();
    setBankAccountPrenoteStatuses(_bankAccountPrenoteStatuses);
  };

  //value to determine if an agent is attempting to edit the wallet from the plans tab vs payments tab
  //restricts some editing options based on value
  const [editWalletFromPlans, setEditWalletFromPlans] = useState(false);

  const [enrollmentStates, setEnrollmentStates] = useState([]);
  const getEnrollmentStates = async () => {
    let _enrollmentStates = await optionService.getEnrollmentStates();
    setEnrollmentStates(_enrollmentStates);
  };

  const [relationshipTypes, setRelationshipTypes] = useState([]);
  const getRelationshipTypes = async () => {
    let _relationshipTypes = await optionService.getRelationshipTypes();
    setRelationshipTypes(_relationshipTypes);
  };

  const [cancellationReasons, setCancellationReasons] = useState([]);
  const getCancellationReasons = async () => {
    let _cancellationReasons = await optionService.getCancellationReasons();
    setCancellationReasons(_cancellationReasons);
  };

  // Error / Success Messages
  const [message, setMessage] = useState({});

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setMessage({
        ...message,
        messageDisplay: false,
        autoHideDuration: 1,
        severity: 'info',
        backgroundColor: '',
        message: '',
      });
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [message.messageDisplay]);

  const handleMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage({
      ...message,
      messageDisplay: false,
      autoHideDuration: '',
      severity: '',
      backgroundColor: '',
      message: '',
    });
  };

  const [organizations, setOrganizations] = useState('');

  const getOrganizations = async () => {
    let organizationArray = await organizationService.getOrganizations();
    setOrganizations(organizationArray);
  };

  const [documents, setDocuments] = useState([]);
  const [attachmentTypes, setAttachmentTypes] = useState([]);

  const getAttachmentTypes = async () => {
    let _attachmentTypes = await optionService.getAttachmentTypes();
    setAttachmentTypes(_attachmentTypes);
  };
  useEffect(() => {
    getAttachmentTypes();
  }, []);

  const getDocuments = async () => {
    let _documents = [];
    if (thisMember?.id) {
      _documents = await enrollmentService.getAttachmentsForMember(
        thisMember?.id
      );
    }
    setDocuments(_documents);
  };

  const backListener = () => {
    if ((history.back = '/members')) {
      history.push('/members', { useLastHistory: true });
    }
  };

  useEffect(() => {
    getOrganizations();
    window.onpopstate = () => {
      backListener();
    };
  }, []);

  useEffect(() => {
    if (thisMember?.id && !documents.length > 0 && !topOutstandingBalance) {
      getDocuments();
      getOutstandingBalance();
    }
  }, [thisMember]);

  const [topOutstandingBalance, setTopOutstandingBalance] = useState(0);
  const getOutstandingBalance = async () => {
    try {
      let _balance = await paymentsService.getPaymentBalanceByMemberId(
        thisMember?.id
      );
      setTopOutstandingBalance(_balance);
    } catch (error) {
      console.error(
        'error with getting outstanding balances in plans tab',
        error
      );
      //temporarily set balance to 0
      setTopOutstandingBalance({ ...topOutstandingBalance, balanceAmount: 0 });
    }
  };

  //History Tab
  const [unsortedHistoryData, setUnsortedHistoryData] = useState([]);

  //Date logic
  const [dateRange, setDateRange] = useState({});
  const [historyError, setHistoryError] = useState(null);
  //Pre-selected date range
  let today = new Date();

  let beginningOfTimeForMember = new Date();
  // needed to do if statement because the member object is not always available
  if (thisMember) {
    beginningOfTimeForMember = new Date(thisMember.createdDateTime);
  }
  //don't convert to date strings for datepicker
  const preSelectedDateRange = [
    {
      name: 'All',
      startDate: beginningOfTimeForMember,
      endDate: today,
    },
    {
      name: 'Past month',
      startDate: new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      ),
      endDate: today,
    },
    {
      name: 'Past 6 months',
      startDate: new Date(
        today.getFullYear(),
        today.getMonth() - 6,
        today.getDate()
      ),
      endDate: today,
    },
    {
      name: 'Past 12 months',
      startDate: new Date(
        today.getFullYear(),
        today.getMonth() - 12,
        today.getDate()
      ),

      endDate: today,
    },
    {
      name: 'Past 24 months',
      startDate: new Date(
        today.getFullYear(),
        today.getMonth() - 24,
        today.getDate()
      ),

      endDate: today,
    },
    {
      name: 'Custom',
      startDate: null,
      endDate: null,
    },
  ];

  //default date range is past 24 months
  useEffect(() => {
    setDateRange(preSelectedDateRange[4]);
  }, []);

  const getMemberHistory = async (startDate, endDate) => {
    let _history = [];
    let endDateTomorrow = new Date(endDate);
    endDateTomorrow.setDate(endDateTomorrow.getDate() + 1);
    endDateTomorrow = new Date(endDateTomorrow);
    setHistoryError(null);
    //takes in member id, start date, end date
    try {
      _history = await timelineService.getHistoryForMember(
        thisMember?.id,
        startDate.toJSON(),
        endDate.toJSON()
      );
    } catch (error) {
      console.error('Error getting history', error?.message);
      setHistoryError(error?.message);
    }

    setUnsortedHistoryData(_history);
    return _history;
  };

  //get initial history
  useEffect(() => {
    if (thisMember?.id && dateRange?.startDate && dateRange?.endDate) {
      getMemberHistory(dateRange?.startDate, dateRange?.endDate);
    }
  }, [thisMember?.id]);

  const refreshHistory = async () => {
    if (thisMember?.id && dateRange?.startDate && dateRange?.endDate) {
      await getMemberHistory(dateRange?.startDate, dateRange?.endDate);
    }
  };

  //set enrollment calculator survey state
  const [surveyResponses, setSurveyResponses] = useState(null);

  return (
    <Box sx={componentStyles.memberProfileContainer}>
      {/* LEFTSIDE MEMBER INFO */}
      <MemberInfo
        member={thisMember}
        getThisMember={getThisMember}
        dependents={dependents}
        saveDependents={saveDependents}
        updateMember={updateMember}
        addDependent={addDependent}
        deleteDependent={deleteDependent}
        phones={phones}
        setPhones={setPhones}
        primaryPhone={primaryPhone}
        setPrimaryPhone={setPrimaryPhone}
        secondaryPhone={secondaryPhone}
        setSecondaryPhone={setSecondaryPhone}
        organizations={organizations}
        handlePhoneInsert={handlePhoneInsert}
        getSpouse={getSpouse}
        spouse={spouse}
        setSpouse={setSpouse}
        saveSpouseInfo={saveSpouseInfo}
        permissionsEnabler={permissionsEnabler}
        refreshHistory={refreshHistory}
        languageTypes={languageTypes}
        setMessage={setMessage}
        profileDisabled={profileDisabled}
        disabledProfileStyle={disabledProfileStyleWithPath}
      />

      <Box sx={componentStyles.tabAndBodyContainer}>
        {/* NAV ROW */}
        <Box sx={componentStyles.tabRowContainer}>
          <Box
            value="0"
            sx={componentStyles.tabContainer(
              activePage === 0 || activePage === 4
            )}
            onClick={handleActivePageChange}
          >
            <Box
              value="0"
              sx={componentStyles.tabOutline(
                activePage === 0 || activePage === 4
              )}
            >
              <ContentCopyIcon sx={componentStyles.icon} />
              <Typography
                value="0"
                variant="body1"
                sx={componentStyles.tabText}
              >
                PLANS
              </Typography>
            </Box>
          </Box>

          <Box
            sx={componentStyles.tabContainer(activePage === 1)}
            value="1"
            onClick={handleActivePageChange}
          >
            <Box value="1" sx={componentStyles.tabOutline(activePage === 1)}>
              <LocalActivityIcon sx={componentStyles.icon} />
              <Typography
                value="1"
                variant="body1"
                fontWeight="500"
                sx={componentStyles.tabText}
              >
                TICKETS
              </Typography>
            </Box>
          </Box>

          {/* hiding notes tab while implementing new history tab*/}
          {/* <Box 
              sx={componentStyles.tabContainer(activePage === 2)}
              value="2"
              onClick={handleActivePageChange}
            >
              <Box
                value="2"
                sx={componentStyles.tabOutline(activePage === 2)}
              >
                <ContentPasteIcon sx={componentStyles.icon}/>
                <Typography
                  value="2"
                  variant="body1"
                  sx={componentStyles.tabText}
                >
                  NOTES
                </Typography>
              </Box>
            </Box> */}

          {/* Comment out for Humboldt release to prod */}
          <Box
            sx={componentStyles.tabContainer(activePage === 7)}
            value="7"
            onClick={handleActivePageChange}
          >
            <Box value="7" sx={componentStyles.tabOutline(activePage === 7)}>
              <HistoryOutlinedIcon sx={componentStyles.icon} />
              <Typography
                value="7"
                variant="body1"
                sx={componentStyles.tabText}
              >
                HISTORY
              </Typography>
            </Box>
          </Box>

          {permissionsEnabler && permissionsEnabler.viewPayments && (
            <Box
              sx={componentStyles.tabContainer(activePage === 5)}
              value="5"
              onClick={handleActivePageChange}
            >
              <Box value="5" sx={componentStyles.tabOutline(activePage === 5)}>
                <AttachMoneyIcon sx={componentStyles.icon} />
                <Typography
                  value="5"
                  variant="body1"
                  sx={componentStyles.tabText}
                >
                  PAYMENTS
                </Typography>
              </Box>
            </Box>
          )}

          <Box
            sx={componentStyles.tabContainer(activePage === 3)}
            value="3"
            onClick={handleActivePageChange}
          >
            <Box value="3" sx={componentStyles.tabOutline(activePage === 3)}>
              <SettingsIcon sx={componentStyles.icon} />
              <Typography
                value="3"
                variant="body1"
                fontWeight="500"
                sx={componentStyles.tabText}
              >
                SETTINGS
              </Typography>
            </Box>
          </Box>

          <Box
            sx={componentStyles.tabContainer(activePage === 6)}
            value="6"
            onClick={handleActivePageChange}
          >
            <Box value="6" sx={componentStyles.tabOutline(activePage === 6)}>
              <FolderOpenIcon sx={componentStyles.icon} />
              <Typography
                value="6"
                variant="body1"
                sx={componentStyles.tabText}
              >
                DOCUMENTS
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* BODY CONTENT */}
        {activePage === 0 && (
          <>
            <MemberPlansTab
              //ENROLLMENTS AND ACTIVE COVERAGES
              availableEnrollments={availableEnrollments}
              activeCoverage={activeCoverage}
              currentActiveCoverage={currentActiveCoverage}
              pastActiveCoverage={pastActiveCoverage}
              futureActiveCoverage={futureActiveCoverage}
              getActiveCoverage={getActiveCoverage}
              UsdFormatterDec={UsdFormatterDec}
              percentFormatter={percentFormatter}
              //getThisMember={getThisMember}
              member={thisMember}
              dependents={dependents}
              spouse={spouse}
              documents={documents}
              attachmentTypes={attachmentTypes}
              getAllDocuments={getDocuments}
              //these are the same and will be replaced by activeCoverage including current and past
              // availablePlans={availablePlans}
              // enrollments={availablePlans}
              getEnrollments={getEnrollments}
              getOneEnrollment={getOneEnrollment}
              // singleEnrollment={selectedPlan}
              singleEnrollment={selectedEnrollment}
              setSelectedEnrollment={setSelectedEnrollment}
              setActivePage={setActivePage}
              // singlePlan={selectedPlan}
              // selectedPlan={selectedPlan}//replaced by activeCoverage
              setSelectedPlan={setSelectedPlan}
              // updateSinglePlan={updateSinglePlan}
              totalPlanCosts={totalPlanCosts}
              bankAccount={bankAccount}
              paymentSchedule={paymentSchedule}
              paymentFrequencies={PaymentFrequencies}
              enrollmentStates={enrollmentStates}
              relationshipTypes={relationshipTypes}
              cancellationReasons={cancellationReasons}
              getPaymentSchedule={getPaymentSchedule}
              getBankAccount={getBankAccount}
              permissionsEnabler={permissionsEnabler}
              setBankAccount={setBankAccount}
              bankAccountTypes={bankAccountTypes}
              editWalletFromPlans={editWalletFromPlans}
              setEditWalletFromPlans={setEditWalletFromPlans}
              handleActivePageChange={handleActivePageChange}
              topOutstandingBalance={topOutstandingBalance}
              getMemberHistory={getMemberHistory}
              surveyResponses={surveyResponses}
              setSurveyResponses={setSurveyResponses}
              profileDisabled={profileDisabled}
              disabledProfileStyle={disabledProfileStyle}
              displayNextPaymentChip={displayNextPaymentChip}
              currentCombinedPaymentInfo={currentCombinedPaymentInfo}
              setCurrentCombinedPaymentInfo={setCurrentCombinedPaymentInfo}
            />
          </>
        )}

        {activePage === 1 && (
          <>
            <MemberTicketsTab
              member={thisMember}
              singleEnrollment={selectedEnrollment} //use singleEnrollment here in createTicketDrawer to create a ticket w/ groupPlanId
              permissionsEnabler={permissionsEnabler}
              profileDisabled={profileDisabled}
              disabledProfileStyle={disabledProfileStyle}
            />
          </>
        )}

        {/* {activePage === 2 && (
          <>
            <MemberNotesTab
              member={thisMember}
              singleEnrollment={selectedEnrollment}
              permissionsEnabler={permissionsEnabler}
            />
          </>
          )} */}

        {activePage === 3 && (
          <>
            <MemberSettingsTab
              // update
              member={thisMember}
              getThisMember={getThisMember}
              updateMember={updateMember}
              singleEnrollment={selectedEnrollment} //not used in this component, maybe remove
              message={message}
              setMessage={setMessage}
              permissionsEnabler={permissionsEnabler}
              profileDisabled={profileDisabled}
              disabledProfileStyle={disabledProfileStyle}
            />
          </>
        )}

        {activePage === 4 && selectedEnrollment && (
          <>
            <MemberEnrollTab
              newEnrollment={newEnrollment}
              member={thisMember}
              getThisMember={getThisMember}
              singleEnrollment={selectedEnrollment}
              setSelectedEnrollment={setSelectedEnrollment}
              getEnrollments={getEnrollments}
              getOneEnrollment={getOneEnrollment}
              documents={documents}
              UsdFormatterDec={UsdFormatterDec}
              permissionsEnabler={permissionsEnabler}
              setActivePage={setActivePage}
              getPaymentSchedule={getPaymentSchedule}
              currentActiveCoverage={currentActiveCoverage}
              getActiveCoverage={getActiveCoverage}
              surveyResponses={surveyResponses}
              setSurveyResponses={setSurveyResponses}
              profileDisabled={profileDisabled}
              disabledProfileStyle={disabledProfileStyle}
            />
          </>
        )}
        {activePage === 5 && (
          <>
            <MemberPaymentsTab
              UsdFormatterDec={UsdFormatterDec}
              member={thisMember}
              // enrollments={availablePlans} //not being used in payments tab, AC has replaced
              // enrollmentsList={enrollments}
              getOneEnrollment={getOneEnrollment}
              // singleEnrollment={selectedPlan}
              currentActiveCoverage={currentActiveCoverage}
              //planCosts={PlanCosts} //not used in payments tab
              bankAccount={bankAccount}
              setBankAccount={setBankAccount}
              bankAccountTypes={bankAccountTypes}
              // setBankAccountTypes={setBankAccountTypes}
              paymentSchedule={paymentSchedule}
              setPaymentSchedule={setPaymentSchedule}
              paymentFrequencies={PaymentFrequencies}
              getPaymentSchedule={getPaymentSchedule}
              getBankAccount={getBankAccount}
              paymentTransactionTypes={paymentTransactionTypes}
              paymentStatuses={paymentStatuses}
              bankAccountPrenoteStatuses={bankAccountPrenoteStatuses}
              permissionsEnabler={permissionsEnabler}
              editWalletFromPlans={editWalletFromPlans}
              setEditWalletFromPlans={setEditWalletFromPlans}
              topOutstandingBalance={topOutstandingBalance}
              profileDisabled={profileDisabled}
              disabledProfileStyle={disabledProfileStyle}
              oneTimePayments={oneTimePayments}
              seOneTimePayments={setOneTimePayments}
              getOneTimePayments={getOneTimePayments}
              displayNextPaymentChip={displayNextPaymentChip}
              currentCombinedPaymentInfo={currentCombinedPaymentInfo}
              setCurrentCombinedPaymentInfo={setCurrentCombinedPaymentInfo}
            />
          </>
        )}
        {activePage === 6 && (
          <>
            <MemberDocumentsTab
              member={thisMember}
              documents={documents}
              attachmentTypes={attachmentTypes}
              getAllDocuments={getDocuments}
              permissionsEnabler={permissionsEnabler}
              profileDisabled={profileDisabled}
              disabledProfileStyle={disabledProfileStyle}
            />
          </>
        )}
        {/* Comment out for Humboldt release to prod */}
        {activePage === 7 && (
          <>
            <MemberHistoryTab
              member={thisMember}
              permissionsEnabler={permissionsEnabler}
              getMemberHistory={getMemberHistory}
              dateRange={dateRange}
              setDateRange={setDateRange}
              preSelectedDateRange={preSelectedDateRange}
              unsortedHistoryData={unsortedHistoryData}
              today={today}
              beginningOfTimeForMember={beginningOfTimeForMember}
              historyError={historyError}
              setHistoryError={setHistoryError}
              profileDisabled={profileDisabled}
              disabledProfileStyle={disabledProfileStyle}
            />
          </>
        )}
        <FlexSnackbar
          text={message.message}
          displayAlert={message.messageDisplay}
          setDisplayAlert={setMessage}
          severity={message.severity}
          autoHideDuration={message.autoHideDuration}
        />
      </Box>
    </Box>
  );
}
