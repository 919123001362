import React, { useState, useEffect } from 'react';

//services
// import enrollmentService from '../../../../../../../../services/enrollment-service';
// import activeCoverageService from '../../../../../../../../services/activeCoverage-service';

import {
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Button,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
//utils
import {
  formatCurrency as utilsFormatCurrency,
  formatBenefit,
  formatMonthDay,
} from '../../../../../../../utils';

const styles = {
  container: {
    backgroundColor: '#FEF6E7',
    padding: '10px',
    minWidth: '250px',
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    borderRadius: '4px',
    marginTop: '8px',
  },
  title: {
    color: '#5f2b01',
    fontSize: '16px',
    marginLeft: 1,
    paddingTop: '5px',
    fontWeight: '500',
  },
  message: {
    color: '#5f2b01',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.17px',
    maxWidth: '400px',
    wordBreak: 'break-word',
    marginLeft: 6,
  },
  details: {
    color: '#5f2b01',
    marginLeft: 6,
    maxWidth: '400px',
    marginBottom: '8px',
  },
  button: {
    backgroundColor: 'transparent',
    color: '#5f2b01',
    textTransform: 'uppercase',
    fontSize: '13px',
    fontWeight: 'bold',
    letterSpacing: '0.46px',
    padding: 0,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  iconButton: {
    padding: 0,
    color: '#5f2b01',
  },
  warningHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  warningHeaderInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  changesMade: {
    fontWeight: 'medium',
    fontSize: '14px',
  },
  changeDescription: {
    marginLeft: 2,
    fontSize: '14px',
  },
  boldText: {
    fontWeight: '600',
    fontSize: '14px',
  },
  laddContainer: {
    marginTop: '-10px',
    paddingTop: 0,
    gap: '4px',
  },
  laddChangeDescription: {
    marginLeft: 2,
    fontSize: '14px',
    marginBottom: '2px',
  },
};

// Warning Component
export const WarningComponent = ({
  title,
  message,
  details,
  buttonLabel,
  onButtonClick,
  handleMenuClick,
  iconColor,
}) => (
  <Box sx={styles.container}>
    <Box sx={styles.warningHeaderContainer}>
      <Box sx={styles.warningHeaderInnerContainer}>
        <InfoOutlinedIcon sx={{ color: iconColor, marginLeft: 1 }} />
        <Typography sx={styles.title}>{title}</Typography>
      </Box>
      {buttonLabel ? (
        <Button onClick={onButtonClick} sx={styles.button}>
          {buttonLabel}
        </Button>
      ) : (
        <IconButton onClick={handleMenuClick} sx={styles.iconButton}>
          <MoreVertIcon />
        </IconButton>
      )}
    </Box>
    <Typography sx={styles.message}>{message}</Typography>
    {details && <Typography sx={styles.details}>{details}</Typography>}
  </Box>
);

// Pending Changes Warning Component - logic
export const PendingChangesWarning = ({
  plan, //array of active coverage data
  memberId,
  planId,
  initialWarningType = 'claim', //default warning type - can be claim, pending, modification, cancellation, ladd_warning
  handleModificationTypeChange,
  ...props
  // onClaimClick, a future implementation
}) => {
  // Constants for warning types
  const WARNING_TYPES = {
    CLAIM: 'claim',
    PENDING: 'pending',
    MODIFICATION: 'modification',
    CANCELLATION: 'cancellation',
    LADD_WARNING: 'ladd_warning',
  };

  // Helper functions
  const formatCurrency = (amount) =>
    !amount || isNaN(amount) ? '$0.00' : utilsFormatCurrency(amount);

  const [warningType, setWarningType] = useState(initialWarningType);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  // console.log('lines in pending changes warning:', plan);

  useEffect(() => {
    if (!plan) return;

    // console.log('📊 Coverage data:', plan);

    const newWarningType = plan.some((item) => item.isClaimInProgress)
      ? WARNING_TYPES.CLAIM
      : plan.some(
          (item) =>
            item?.lineOfCoverage?.displayName === 'LADD' &&
            (item?.lineOfCoverage?.futureBenefitAmount ||
              item?.lineOfCoverage?.futureBeginDate)
        )
      ? WARNING_TYPES.LADD_WARNING
      : plan.some((item) => item?.lineOfCoverage?.isCancelled)
      ? WARNING_TYPES.CANCELLATION
      : WARNING_TYPES.PENDING;

    setWarningType(newWarningType);
    // console.log('⚠️ Warning type:', newWarningType);
  }, [
    plan,
    plan?.length,
    plan?.some((line) => line?.lineOfcoverage)?.futureActiveCoverageId,
    plan?.some((line) => line?.lineOfcoverage)?.futurePremiumAmount,
    plan?.some((line) => line?.lineOfcoverage)?.isCancelled,
  ]); //

  const handleMenuClick = (event) => setMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);

  //lines of coverage that are pending cancellation or have future begin date
  const warningData = plan?.filter(
    (item) =>
      item?.lineOfCoverage?.isCancelled ||
      (item?.lineOfCoverage?.futureBeginDate &&
        item?.lineOfCoverage?.futureActiveCoverageId &&
        item?.lineOfCoverage?.futureBeginDate !== '0001-01-01T00:00:00+00:00' &&
        new Date(item?.lineOfCoverage?.futureBeginDate) > new Date())
  );

  /////build warning message
  const warningMessages = {
    //Claims - future implementation
    [WARNING_TYPES.CLAIM]: (data) => ({
      title: `${
        data[0]?.lineOfCoverage?.displayName || 'Coverage'
      } Claim in Progress`,
      message:
        'This line of coverage is currently under claim. The member will not be charged during this time. This plan cannot be changed or canceled.',
      buttonLabel: 'Go to Claim',
      iconColor: 'rgba(230, 81, 0, 1)',
      link: data?.claimLink,
    }),
    [WARNING_TYPES.LADD_WARNING]: (data) => ({
      title: 'Coverage Changes Pending',
      message: 'Changes made:',
      details: (
        <Box sx={styles.laddContainer}>
          {data.map((item, index) => {
            const line = item?.lineOfCoverage;
            const displayName =
              props?.findSingleLineDisplayName(line?.displayName) || 'Coverage';
            const effectiveDate = formatMonthDay(line?.futureBeginDate);
            const cancellationDate = formatMonthDay(line?.endDate);
            const isCancelled = line?.isCancelled;
            const currentBenefit = formatCurrency(line?.benefitAmount || 0);
            const futureBenefit = formatCurrency(
              line?.futureBenefitAmount || 0
            );
            const hasBenefitChange =
              line?.benefitAmount != null &&
              line?.futureBenefitAmount != null &&
              line?.futureBenefitAmount !== line?.benefitAmount;

            // Determine the change type
            let changeType = '';
            if (isCancelled) {
              changeType = 'cancelled';
            } else if (hasBenefitChange) {
              changeType =
                line?.futureBenefitAmount > line?.benefitAmount
                  ? 'increased'
                  : 'decreased';
            } else {
              changeType = 'updated';
            }
            // console.log('📊 LADD data:', data);
            // console.log('📊 canceldate data:', cancellationDate);
            return (
              <Typography
                key={index}
                variant="body2"
                sx={{ ...styles.laddChangeDescription }}
              >
                • {displayName} {changeType}, effective{' '}
                {isCancelled ? cancellationDate : effectiveDate}.
                {!isCancelled && hasBenefitChange && (
                  <>
                    {' '}
                    Benefit Amount {changeType} from{' '}
                    <Typography component="span" sx={styles.boldText}>
                      {currentBenefit}
                    </Typography>{' '}
                    to{' '}
                    <Typography component="span" sx={styles.boldText}>
                      {futureBenefit}.
                    </Typography>
                  </>
                )}
              </Typography>
            );
          })}
        </Box>
      ),
      iconColor: '#5f2b01',
    }),

    [WARNING_TYPES.PENDING]: (data) => {
      // console.log('📊 Pending data:', data);
      //accident
      const renderAccidentChanges = (data) => {
        if (!data || !data[0]?.lineOfCoverage) {
          console.error('Invalid data structure for Accident changes:', data);
          return null;
        }
        // Accessing premium amounts
        const premiumAmount = data[0]?.lineOfCoverage?.premiumAmount ?? 0;
        const futurePremiumAmount =
          data[0]?.lineOfCoverage?.futurePremiumAmount ?? 0;

        // Determine if the premium amount has changed
        const premiumChange =
          futurePremiumAmount > premiumAmount
            ? 'increased'
            : futurePremiumAmount < premiumAmount
            ? 'decreased'
            : 'unchanged';

        return (
          <Typography variant="body2" sx={styles.changeDescription}>
            • Premium Amount {premiumChange} from{' '}
            <Typography component="span" sx={styles.boldText}>
              {formatCurrency(premiumAmount)}
            </Typography>{' '}
            to{' '}
            <Typography component="span" sx={styles.boldText}>
              {formatCurrency(futurePremiumAmount)}
            </Typography>
            .
          </Typography>
        );
      };

      // Plan-specific changes (Hospital, ADD, Cancer)
      const renderPlanChanges = (data) => {
        if (!data || !data[0]?.lineOfCoverage) {
          console.error('Invalid data structure for Plan changes:', data);
          return null;
        }

        // Access benefits
        const benefitAmount = data[0]?.lineOfCoverage?.benefitAmount ?? 0;
        const futureBenefitAmount =
          data[0]?.lineOfCoverage?.futureBenefitAmount ?? 0;

        // Determine change action
        const changeAction =
          futureBenefitAmount > benefitAmount
            ? 'increased'
            : futureBenefitAmount < benefitAmount
            ? 'decreased'
            : 'unchanged';

        return (
          <Typography variant="body2" sx={styles.changeDescription}>
            • Benefit Amount {changeAction} from{' '}
            <Typography component="span" sx={styles.boldText}>
              {formatBenefit(benefitAmount)}
            </Typography>{' '}
            to{' '}
            <Typography component="span" sx={styles.boldText}>
              {formatBenefit(futureBenefitAmount)}
            </Typography>
            .
          </Typography>
        );
      };

      // STD - different Weekly benefit
      const renderSTDChanges = (data) => {
        if (!data || !data[0]?.lineOfCoverage) {
          console.error('Invalid data structure for STD changes:', data);
          return null;
        }

        // Access benefits
        const benefitAmount = data[0]?.lineOfCoverage?.benefitAmount ?? 0;
        const futureBenefitAmount =
          data[0]?.lineOfCoverage?.futureBenefitAmount ?? 0;

        // Determine change action
        const changeAction =
          futureBenefitAmount > benefitAmount
            ? 'increased'
            : futureBenefitAmount < benefitAmount
            ? 'decreased'
            : 'unchanged';

        // Determine benefit type (Weekly or Monthly)
        const benefitType = data[0]?.lineOfCoverage?.desc.includes('Weekly')
          ? 'Weekly'
          : 'Monthly';

        return (
          <Typography variant="body2" sx={styles.changeDescription}>
            • Benefit Amount {changeAction} from{' '}
            <Typography component="span" sx={styles.boldText}>
              ${benefitAmount} {benefitType} Benefit
            </Typography>{' '}
            to{' '}
            <Typography component="span" sx={styles.boldText}>
              ${futureBenefitAmount} {benefitType} Benefit
            </Typography>
            .
          </Typography>
        );
      };

      //LTD - different monthly benefit
      const renderLTDChanges = (data) => {
        if (!data || !data[0]?.lineOfCoverage) {
          console.error('Invalid data structure for LTD changes:', data);
          return null;
        }

        //Percentage and dollar amouns
        const benefitPercentage = data[0]?.lineOfCoverage?.desc || 0;
        const futureBenefitAmount =
          data[0]?.lineOfCoverage?.futureBenefitAmount ?? 0;

        // Determine the change action
        const changeAction =
          data[0]?.lineOfCoverage?.futureBenefitAmount != null &&
          data[0]?.lineOfCoverage?.benefitAmount != null
            ? data[0]?.lineOfCoverage?.futureBenefitAmount >
              data[0]?.lineOfCoverage?.benefitAmount
              ? 'increased'
              : 'decreased'
            : 'updated';

        // Format the future benefit amount as dollars
        const formattedFutureBenefitAmount = formatBenefit(
          futureBenefitAmount,
          false // Not a percentage
        );

        return (
          <Typography variant="body2" sx={styles.changeDescription}>
            • Benefit Amount {changeAction} from{' '}
            <Typography component="span" sx={styles.boldText}>
              {benefitPercentage}
            </Typography>{' '}
            to{' '}
            <Typography component="span" sx={styles.boldText}>
              {formattedFutureBenefitAmount}
            </Typography>
            .
          </Typography>
        );
      };

      // Return the formatted description
      return {
        title: 'Coverage Changes Pending',
        message: `Changes in coverage have been scheduled to go into effect on ${formatMonthDay(
          data[0]?.lineOfCoverage?.futureBeginDate ?? 0
        )}. The new premium amount will be ${formatCurrency(
          data[0]?.lineOfCoverage?.futurePremiumAmount ?? 0
        )}/mo.`,
        details: (
          <Box>
            <Typography variant="body2" sx={styles.changesMade}>
              Changes made:
            </Typography>
            {data[0]?.lineOfCoverage?.desc === 'Accident'
              ? renderAccidentChanges(data)
              : data[0]?.lineOfCoverage?.displayName === 'STD'
              ? renderSTDChanges(data)
              : data[0]?.lineOfCoverage?.displayName === 'LTD'
              ? renderLTDChanges(data)
              : ['Hospital', 'ADD', 'Cancer'].includes(
                  data[0]?.lineOfCoverage?.displayName
                )
              ? renderPlanChanges(data)
              : null}
          </Box>
        ),
        iconColor: '#5f2b01',
      };
    },

    [WARNING_TYPES.CANCELLATION]: (data) => ({
      title: 'Coverage Changes Pending',
      message: `Changes in coverage have been scheduled to go into effect on ${formatMonthDay(
        data[0]?.lineOfCoverage?.endDate ?? 0
      )}. The new premium amount will be ${formatCurrency(
        data[0]?.lineOfCoverage?.futurePremiumAmount ?? 0
      )}/mo.`,
      details: (
        <Box>
          <Typography variant="body2" sx={styles.changesMade}>
            Changes made:
          </Typography>
          {data.map((item, index) => (
            <Typography
              key={index}
              variant="body2"
              sx={{ ...styles.boldText, ...styles.changeDescription }}
            >
              • CANCELLATION.
            </Typography>
          ))}
        </Box>
      ),
      iconColor: '#5f2b01',
    }),
  };

  // console.log('📊 Warning data:', warningData);

  //makes sure theres a change - if so renders
  const currentMessage =
    warningType &&
    warningData?.length > 0 &&
    typeof warningMessages[warningType] === 'function'
      ? warningMessages[warningType](warningData)
      : null;

  if (!currentMessage) return null;

  // go to claim button functionality -- future implementation
  const onClaimClick = () => {
    if (currentMessage.claimLink) {
      window.location.href = currentMessage.claimLink;
    }
  };

  return (
    <>
      <WarningComponent
        title={currentMessage?.title}
        message={currentMessage.message}
        details={currentMessage.details}
        buttonLabel={currentMessage.buttonLabel}
        iconColor={currentMessage.iconColor}
        onButtonClick={
          warningType === WARNING_TYPES.CLAIM ? onClaimClick : undefined
        }
        handleMenuClick={handleMenuClick}
      />
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        {/* connected to active coverage drawer edit and cancel */}
        <MenuItem
          onClick={() => handleModificationTypeChange('edit')}
          disabled={!props?.allowModify}
        >
          Modify Changes
        </MenuItem>
        {/* future sprint  */}
        {/* <MenuItem onClick={() => handleModificationTypeChange('cancel')}>
          Cancel Changes
        </MenuItem> */}
      </Menu>
    </>
  );
};

export default PendingChangesWarning;
