import React, { useState, useEffect } from 'react';
import { set, format } from 'date-fns';

//Mui Components
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

//Our components
import InfinityLoader from '../../../../../../../UH-loading-animation/InfinityLoader';
import EditCoverageEffectiveDateStepper from '../EditCoverageEffectiveDateStepper';
import AccAddCanOrHospitalCoverage from './viewAndEditCoverageBody/AccAddCanOrHospitalActiveCoverage';
import IncrementalStdAndLtdActiveCoverage from './viewAndEditCoverageBody/IncrementalStdAndLtdActiveCoverage';
import NonIncrementalStdAndLtdActiveCoverage from './viewAndEditCoverageBody/NonIncrementalStdAndLtdActiveCoverage';

//Serviees
import activeCoverageService from '../../../../../../../../services/activeCoverage-service';

//utils
import {
  formatCurrency,
  formatUTCToShortDate,
} from '../../../../../../../utils';

//Our styles
import activeCoverageStyles from './activeCoverageStyles';

const componentStyles = {
  menuOptions: {
    color: 'text.primary',
    fontFamily: 'Archivo',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    height: '48px',
    '&:hover': { backgroundColor: 'primary.light' },
  },
};

export default function ActiveLineOfCoverage(props) {
  //set single plan in state
  const [plan, setPlan] = useState({});
  const [planOptions, setPlanOptions] = useState([]);

  useEffect(() => {
    if (props?.singlePlan?.options) {
      setPlanOptions(props?.singlePlan?.options);
    }

    if (props?.singlePlan?.lineOfCoverage) {
      setPlan(props?.singlePlan?.lineOfCoverage);
    }
  }, [props?.singlePlan?.options, props?.singlePlan?.lineOfCoverage]);

  //check if plan is add, accident, cancer, or hospital - we edit these differently

  const checkIfPlanIsAccAddCanOrHosp = () => {
    let _isAccAddCanOrHosp = false;
    if (
      plan?.calculatedPlanTypeId &&
      (plan?.calculatedPlanTypeId === 3 ||
        plan?.calculatedPlanTypeId === 7 ||
        plan?.calculatedPlanTypeId === 8 ||
        plan?.calculatedPlanTypeId === 9)
    ) {
      _isAccAddCanOrHosp = true;
    }
    setIsAccAddCanOrHosp(_isAccAddCanOrHosp);
  };

  const [isAccAddCanOrHosp, setIsAccAddCanOrHosp] = useState(false);

  useEffect(() => {
    checkIfPlanIsAccAddCanOrHosp();
  }, [plan?.calculatedPlanTypeId]);

  //Manage Plan Info
  //open verticle dot menu to open active coverage drawer
  // const [edit, setEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState({});

  const handleOpenRowMenu = (e) => {
    setAnchorEl({
      ...anchorEl,
      [e.currentTarget.value]: e.currentTarget,
    });
  };

  const handleCloseRowMenu = () => {
    setAnchorEl({});
  };

  return (
    <Box sx={activeCoverageStyles.container}>
      {/* Plan Title and Amount */}
      <Box sx={activeCoverageStyles.planTitleRow}>
        <Typography sx={activeCoverageStyles.planTitle}>
          {plan?.displayName}
        </Typography>
        <Box sx={activeCoverageStyles.planAmountContainer}>
          <Typography sx={activeCoverageStyles.planAmount}>
            {formatCurrency(plan?.premiumAmount)}
          </Typography>
          <Typography sx={activeCoverageStyles.perMonth}>/per month</Typography>

          {/* Edit Plan Menu */}
          <Box>
            <IconButton
              onClick={(e) => handleOpenRowMenu(e, plan)}
              value={plan?.planId}
              sx={activeCoverageStyles.threeDotMenu.iconButton}
              disabled={props?.isFutureCoverage}
            >
              <MoreVertIcon
                sx={activeCoverageStyles.threeDotMenu.MoreVertIcon}
              />
            </IconButton>
            <Menu
              anchorEl={anchorEl?.[plan.planId]}
              open={Boolean(anchorEl?.[plan.planId])}
              onClose={handleCloseRowMenu}
              sx={{ ul: { padding: '0px' }, justifyContent: 'flex-start' }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {!plan?.isCancelled &&
                props?.activePlansNotPendingCancel?.length === 1 && (
                  <MenuItem
                    onClick={(e) => {
                      props?.handleOpenCancelCoverageDrawer(
                        null,
                        'cancelAllCoverage'
                      );

                      handleCloseRowMenu();
                    }}
                    sx={componentStyles.menuOptions}
                    disabled={!props.allowModify || plan?.isCancelled}
                  >
                    Cancel All Coverage
                  </MenuItem>
                )}

              {/* manage coverage drawer button */}
              <MenuItem
                onClick={(e) => {
                  props?.handleOpenActiveCoverageDrawer(e, plan, planOptions);
                  handleCloseRowMenu();
                }}
                sx={componentStyles.menuOptions}
                disabled={!props.allowModify}
              >
                Manage Coverage
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
      {/* Pending cancelation or modification warning */}
      {(plan?.futureActiveCoverageId || plan?.isCancelled) &&
        props?.pendingChangesMessage(plan)}
      {/* ////////// View Active Coverage Body ////////////*/}

      {/* Non-incremental plans */}
      {!planOptions?.isIncremental && !isAccAddCanOrHosp && (
        <NonIncrementalStdAndLtdActiveCoverage plan={plan} />
      )}
      {/* Incremental plans */}
      {planOptions?.isIncremental && !isAccAddCanOrHosp && (
        <IncrementalStdAndLtdActiveCoverage plan={plan} />
      )}
      {/* accident, add, cancer, hospital */}
      {isAccAddCanOrHosp && <AccAddCanOrHospitalCoverage plan={plan} />}
      {/* Offset row - non editable */}
      {plan?.benefitOffsets ? (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <Typography variant="body1">Offset</Typography>
          <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
            {plan?.benefitOffsets}
          </Typography>
        </Box>
      ) : null}
      {/* Coverage Effective Date and Edit/Save Edit buttons*/}
      {/* {!editCED ? ( */}
      <Box sx={activeCoverageStyles.planInfoRow}>
        <Typography variant="body1">{`Effective Date: ${
          plan?.effectiveStartDate
            ? formatUTCToShortDate(plan?.effectiveStartDate)
            : 'N/A'
        }`}</Typography>
      </Box>
      {/* temporarily keeping this depending on how ux wants to allow editing CED in future
       ) : (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <EditCoverageEffectiveDateStepper
            currentEffectiveDate={plan?.effectiveStartDate}
          />
          <Box sx={activeCoverageStyles.editButtonsContainer}>
            <Button
              variant="outlined"
              sx={activeCoverageStyles.saveAndCancelEditButtons}
              onClick={() => {
                setEditCED(false);
              }}
            >
              BACK
            </Button>

            <Button
              variant="contained"
              sx={activeCoverageStyles.saveAndCancelEditButtons}
              onClick={console.warn(
                'this will be where we edit coverage effective date in future'
              )}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Box style={{ width: '100%', position: 'absolute' }}>
                    <InfinityLoader
                      style={{
                        zIndex: 5,
                        height: '30px',
                      }}
                    />
                  </Box>
                  <Typography sx={{ opacity: 0 }}>SAVE CHANGES</Typography>
                </>
              ) : (
                <>SAVE CHANGES</>
              )}
            </Button>
          </Box>
        </Box>
      )}{' '} */}
    </Box>
  );
}
