import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

//services
import enrollmentService from '../../../../../../../services/enrollment-service';
import activeCoverageService from '../../../../../../../services/activeCoverage-service';

// Assets

//Mui Components
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Alert, Box, Typography, Checkbox } from '@mui/material';

//Mui icons
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

//Our Components
import InfinityLoader from '../../../../../../UH-loading-animation/InfinityLoader';
import ViewAcLine from './active-coverage-drawer-components/ViewAcLine';
import EditAcLine from './active-coverage-drawer-components/EditAcLine';
import CancelAcLine from './active-coverage-drawer-components/CancelAcLine';

//utils
import { formatUTCToShortDate } from '../../../../../../utils';

//shared styles
import acDrawerSharedStyles from './active-coverage-drawer-components/acDrawerSharedStyles';
import activeCoverageStyles from './activeCoverage-summary-plans/activeCoverageStyles';

//component styles
const componentStyles = {
  componentBody: {
    fontFamily: 'Poppins',
    minWidth: '538px',
    height: '100%',
    backgroundColor: 'background.default',
    overflowY: 'auto',
  },
  headerContainer: {
    width: '100%',
    padding: '10px 16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 auto',
    borderBottom: '1px solid',
    borderColor: 'primary.main',
  },
  drawerTitle: {
    color: 'text.primary',
  },
  policyDetails: {
    fontFamily: 'Archivo',
    fontSize: '13px!important',
    fontWeight: 400,
    color: 'text.secondary',
    lineHeight: '20px',
  },
  description: {
    color: 'text.primary',
    fontFamily: 'Archivo',
    fontSize: '14px',
    fontWeight: '500',
    padding: '10px 16px 10px 20px',
  },
  closeDrawerButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
};
export default function ActiveCoverageDrawer(props) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modificationType, setModificationType] = useState({
    view: true,
    edit: false,
    cancel: false, // switch this view will be default true
  });

  useEffect(() => {
    //default open to cancel when drawer opens - doing this for ladd cancel as first logic added to drawer
    if (props?.activeCoverageDrawerOpen?.open) {
      setModificationType({
        ...modificationType,
        view: true,
        edit: false,
        cancel: false,
      });
    }
  }, [props?.activeCoverageDrawerOpen?.open]);

  const handleModificationTypeChange = (type) => {
    setModificationType((prevState) => {
      // Reset all keys to false, then set the specified key to true
      const updatedState = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = key === type;
        return acc;
      }, {});
      return updatedState;
    });
  };

  const titleDisplayName = (displayName) => {
    let upperCaseDisplay = displayName?.toUpperCase() || '';
    let name = upperCaseDisplay;
    switch (upperCaseDisplay) {
      case 'LADD':
      case 'LADD-S':
      case 'LADD-D':
        name = 'Life and AD&D';
        break;
      case 'STD':
        name = 'Short-Term Disability';
        break;
      case 'LTD':
        name = 'Long-Term Disability';
        break;
      case 'ACCIDENT':
        name = 'Accident';
        break;
      case 'CANCER':
        name = 'Cancer';
        break;
      //ADD AND CI - to be added if needed
      default:
        return name;
    }
    return name;
  };

  const findSingleLineDisplayName = (displayName) => {
    let upperCaseDisplay = displayName?.toUpperCase() || '';
    let name = upperCaseDisplay;
    switch (upperCaseDisplay) {
      case 'LADD':
        name = 'Member';
        break;
      case 'LADD-S':
        name = 'Spouse';
        break;
      case 'LADD-D':
        name = 'Dependent';
        break;
      default:
        return name;
    }
    return name;
  };

  const renderEffectiveDateList = (lineSelected) => {
    if (lineSelected?.length > 1) {
      //ladd or ci
      let builtListDisplay = lineSelected?.map((coverage) => {
        let displayName = coverage?.lineOfCoverage?.displayName
          ? findSingleLineDisplayName(coverage?.lineOfCoverage?.displayName)
          : '';
        return (
          <Typography sx={acDrawerSharedStyles.infoRow}>
            {`${displayName} Effective Date: ${
              coverage?.lineOfCoverage?.effectiveStartDate
                ? formatUTCToShortDate(
                    coverage?.lineOfCoverage?.effectiveStartDate
                  )
                : ''
            }`}
          </Typography>
        );
      });
      return builtListDisplay;
    } else {
      return (
        <Typography sx={acDrawerSharedStyles.infoRow}>
          Effective Date:{' '}
          {lineSelected[0]?.lineOfCoverage?.effectiveStartDate
            ? formatUTCToShortDate(
                lineSelected[0]?.lineOfCoverage?.effectiveStartDate
              )
            : ''}
        </Typography>
      );
    }
  };

  //Drawer Logic
  const handleCloseDrawer = () => {
    props?.setActiveCoverageDrawerOpen((prevState) => {
      return {
        ...prevState,
        open: false,
        lineSelected: [],
      };
    });
    props?.setReasonForCancelation('');
    //reset initial view state of drawer
    setModificationType({
      view: false,
      edit: false,
      cancel: true,
    });
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={props?.activeCoverageDrawerOpen?.open}
        onClose={handleCloseDrawer}
        sx={activeCoverageStyles.drawerContainer}
      >
        {loading && (
          <>
            <InfinityLoader
              style={{
                // position: "fixed",
                position: 'absolute',
                zIndex: 5,
                width: '100%',
                height: '100%',
                backdropFilter: 'blur(10px)',
              }}
            />
            <style>
              {`
                      div.lottie-div > svg {
                         height:55px!important;
                         position:absolute;
                         top:50%;
                      }
                   `}
            </style>
          </>
        )}
        <Box sx={componentStyles.componentBody}>
          <Box sx={componentStyles.headerContainer}>
            <Typography variant="h5" sx={componentStyles.drawerTitle}>
              {props?.activeCoverageDrawerOpen?.lineSelected[0]?.lineOfCoverage
                ?.displayName
                ? titleDisplayName(
                    props?.activeCoverageDrawerOpen?.lineSelected[0]
                      ?.lineOfCoverage?.displayName
                  )
                : 'Active Coverage'}
            </Typography>
            <Box
              sx={componentStyles.closeDrawerButton}
              role="button"
              onClick={handleCloseDrawer}
            >
              <CloseIcon sx={{ height: '34px' }} />
            </Box>
          </Box>
          {/* View Coverage Info Section */}
          {modificationType?.view && (
            <Box sx={acDrawerSharedStyles.infoContainer}>
              <ViewAcLine
                {...props}
                plan={props?.activeCoverageDrawerOpen?.lineSelected}
                onClose={handleCloseDrawer}
                loading={loading}
                setLoading={setLoading}
                handleModificationTypeChange={handleModificationTypeChange}
                findSingleLineDisplayName={findSingleLineDisplayName}
                findTitleDisplayName={titleDisplayName}
              />
            </Box>
          )}
          {/* Edit Coverage Info Section */}
          {modificationType?.edit && (
            <Box sx={acDrawerSharedStyles.infoContainer}>
              <EditAcLine
                {...props}
                modificationType={modificationType}
                setModificationType={setModificationType}
                renderEffectiveDateList={renderEffectiveDateList}
                loading={loading}
                setLoading={setLoading}
                handleModificationTypeChange={handleModificationTypeChange}
                findSingleLineDisplayName={findSingleLineDisplayName}
                findTitleDisplayName={titleDisplayName}
              />
            </Box>
          )}
          {/* Cancel Coverage Info Section */}
          {modificationType?.cancel && (
            <Box sx={acDrawerSharedStyles.infoContainer}>
              <CancelAcLine
                {...props}
                modificationType={modificationType}
                setModificationType={setModificationType}
                findSingleLineDisplayName={findSingleLineDisplayName}
                renderEffectiveDateList={renderEffectiveDateList}
                loading={loading}
                setLoading={setLoading}
                handleModificationTypeChange={handleModificationTypeChange}
                //logic for cancel all coverage - conditionally use if only one remaining active line
                activePlansNotPendingCancel={props?.activePlansNotPendingCancel}
                handleOpenCancelCoverageDrawer={
                  props?.handleOpenCancelCoverageDrawer
                }
              />
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  );
}
