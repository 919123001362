import React, { useEffect, useState } from 'react';
//services

// Assets

//Mui Components
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
  Stack,
} from '@mui/material';

//Mui icons
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

//Our Components

//utils
import { formatCurrency } from '../../../../../../../utils';
//shared styles
import acDrawerSharedStyles from './acDrawerSharedStyles';
import { max, set } from 'date-fns';

//component styles
const componentStyles = {
  displayMultipleLinesContainer: {
    maxWidth: '454px',
  },
};

export default function EditMultipleAcLinesSection(props) {
  //local state obj to keep track of member, spouse, and dependent selection changes and compare w/ max amounts allowed
  const [laddSelections, setLaddSelections] = useState({});
  useEffect(() => {
    if (props?.multiLinesToEdit?.length > 0) {
      //get total premium per line and update with edit changes
      let _totalPremium = props?.getTotalPremium(props?.multiLinesToEdit);
      //set initial selections and separate by member, spouse, dep
      let _sortedLinesObj = props?.multiLinesToEdit?.reduce(
        (acc, line) => {
          if (line?.lineOfCoverage?.calculatedPlanTypeId === 4) {
            acc.member = line;
          } else if (line?.lineOfCoverage?.calculatedPlanTypeId === 5) {
            acc.spouse = line;
          } else if (line?.lineOfCoverage?.calculatedPlanTypeId === 6) {
            acc.dependent = line;
          }
          return acc;
        },
        { member: {}, spouse: {}, dependent: {} }
      );

      //check for lines to auto cancel if benefit amount is 0
      let _linesToAutoCancel = props?.multiLinesToEdit.filter((line) => {
        return line?.editInfo?.benefitAmount === 0;
      });
      props?.setLinesToAutoCancel(_linesToAutoCancel);
      setLaddSelections(_sortedLinesObj);
      props?.setTotalMultiLinePremium(_totalPremium);
    }
  }, [
    props?.multiLinesToEdit?.some((line) => line?.editInfo)?.benefitAmount,
    props?.multiLinesToEdit,
  ]);

  const optionsPersonTypeDisplayName = (lineDisplayName) => {
    let lowerCaseDisplayName = '';
    if (lineDisplayName) {
      lowerCaseDisplayName = props
        ?.findSingleLineDisplayName(lineDisplayName)
        ?.toLowerCase();
    }
    return lowerCaseDisplayName;
  };

  // Benefit Amount Change

  const handleBenefitAmountChange = (e, type, acId, optionSelected) => {
    //match line on acId and update benefit amount
    const updatedLines = props?.multiLinesToEdit.map((line) => {
      if (line?.lineOfCoverage?.id === acId) {
        return {
          ...line,
          editInfo: {
            ...line.editInfo,
            benefitAmount: optionSelected?.coverageAmount,
            premiumAmount: optionSelected?.premiumAmount,
            isUpdated:
              line?.lineOfCoverage?.benefitAmount !==
              optionSelected?.coverageAmount
                ? true
                : false,
          },
        };
      }
      return line;
    });
    props.setMultiLinesToEdit(updatedLines);
  };

  ///////////// Spouse and Dependent option max logic ////////////
  const [spouseMaxAmount, setSpouseMaxAmount] = useState(0);
  const [dependentMaxAmount, setDependentMaxAmount] = useState(0);

  useEffect(() => {
    let _updatedLinesArray = [...props?.multiLinesToEdit];
    //////////CHECK SPOUSE max amount w/ member benefit changes
    if (
      props?.multiLinesToEdit[0]?.options?.spouseMaximumAmount &&
      props?.multiLinesToEdit[0]?.options?.spousePercentageCap
    ) {
      //check spouse max amount against member amount AND spouse percentage cap
      let _spouseMax;
      if (
        props?.multiLinesToEdit[0]?.options?.spouseMaximumAmount <=
        laddSelections?.member?.editInfo?.benefitAmount *
          props?.multiLinesToEdit[0]?.options?.spousePercentageCap
      ) {
        _spouseMax = props?.multiLinesToEdit[0]?.options?.spouseMaximumAmount;
        setSpouseMaxAmount(
          props?.multiLinesToEdit[0]?.options?.spouseMaximumAmount
        );
      } else {
        _spouseMax =
          laddSelections?.member?.editInfo?.benefitAmount *
          props?.multiLinesToEdit[0]?.options?.spousePercentageCap;
        setSpouseMaxAmount(_spouseMax);
      }

      let _newSpouseSelection;
      //IF current spouse selection > new spouse max, find and update spouse w/ new closest spouse option that meets spouse max parameters OR 0 if no options match
      if (laddSelections?.spouse?.editInfo?.benefitAmount > _spouseMax) {
        _newSpouseSelection =
          props?.multiLinesToEdit[0]?.options?.spouseOptions.reduce(
            (prevOption, currOption) => {
              if (
                currOption.coverageAmount <= _spouseMax &&
                prevOption.coverageAmount <= _spouseMax
              ) {
                return prevOption.coverageAmount > currOption.coverageAmount
                  ? prevOption
                  : currOption;
              }
              // Return the previous option if the condition is not met
              return prevOption;
            },
            props?.multiLinesToEdit[0]?.options?.spouseOptions[0]
          );

        //update spouse selection
        //if doesn't meet spouse max, set spouse to 0
        if (_newSpouseSelection?.coverageAmount <= _spouseMax) {
          _updatedLinesArray = _updatedLinesArray?.map((line) => {
            if (line?.lineOfCoverage?.calculatedPlanTypeId === 5) {
              return {
                ...line,
                editInfo: {
                  ...line.editInfo,
                  benefitAmount: _newSpouseSelection?.coverageAmount,
                  premiumAmount: _newSpouseSelection?.premiumAmount,
                  isUpdated: true,
                },
              };
            } else return line;
          });
        } else {
          //set spouse to 0 so we can cancel line
          _updatedLinesArray = _updatedLinesArray?.map((line) => {
            if (line?.lineOfCoverage?.calculatedPlanTypeId === 5) {
              return {
                ...line,
                editInfo: {
                  ...line.editInfo,
                  benefitAmount: 0,
                  premiumAmount: 0,
                  isUpdated: true,
                },
              };
            } else return line;
          });
        }
      }
    }
    ////DEPENDENT CHECK
    if (
      props?.multiLinesToEdit[0]?.options?.dependentMaximumAmount &&
      props?.multiLinesToEdit[0]?.options?.dependentPercentageCap
    ) {
      let _dependentMax;
      //check dependent max amount agains member amount AND dependent percentage cap
      if (
        props?.multiLinesToEdit[0]?.options?.dependentMaximumAmount <=
        laddSelections?.member?.editInfo?.benefitAmount *
          props?.multiLinesToEdit[0]?.options?.dependentPercentageCap
      ) {
        _dependentMax =
          props?.multiLinesToEdit[0]?.options?.dependentMaximumAmount;
        setDependentMaxAmount(_dependentMax);
      } else {
        _dependentMax =
          laddSelections?.member?.editInfo?.benefitAmount *
          props?.multiLinesToEdit[0]?.options?.dependentPercentageCap;
        setDependentMaxAmount(_dependentMax);
      }

      let _newDependentSelection;
      //IF current dependent selection > new dependent max, find and update dependent w/ new closest dependent option that meets dependent max parameters
      if (laddSelections?.dependent?.editInfo?.benefitAmount > _dependentMax) {
        _newDependentSelection =
          props?.multiLinesToEdit[0]?.options?.dependentOptions.reduce(
            (prevOption, currOption) => {
              if (
                currOption.coverageAmount <= _dependentMax &&
                prevOption.coverageAmount <= _dependentMax
              ) {
                return prevOption.coverageAmount > currOption.coverageAmount
                  ? prevOption
                  : currOption;
              }
              // Return the previous option if the condition is not met
              return prevOption;
            },
            props?.multiLinesToEdit[0]?.options?.dependentOptions[0]
          );

        //update dependent selection
        //if doesn't meet dep max requirements, set dep to 0
        if (_newDependentSelection?.coverageAmount <= _dependentMax) {
          _updatedLinesArray = _updatedLinesArray?.map((line) => {
            if (line?.lineOfCoverage?.calculatedPlanTypeId === 6) {
              return {
                ...line,
                editInfo: {
                  ...line.editInfo,
                  benefitAmount: _newDependentSelection?.coverageAmount,
                  premiumAmount: _newDependentSelection?.premiumAmount,
                  isUpdated: true,
                },
              };
            }
            return line;
          });
        } else {
          //set dep to 0 so we can cancel line
          _updatedLinesArray = _updatedLinesArray?.map((line) => {
            if (line?.lineOfCoverage?.calculatedPlanTypeId === 6) {
              return {
                ...line,
                editInfo: {
                  ...line.editInfo,
                  benefitAmount: 0,
                  premiumAmount: 0,
                  isUpdated: true,
                },
              };
            } else return line;
          });
        }
      }
    }

    props?.setMultiLinesToEdit(_updatedLinesArray);
  }, [
    laddSelections?.member?.editInfo?.benefitAmount,
    props?.multiLinesToEdit[0]?.options?.spouseMaximumAmount,
    props?.multiLinesToEdit[0]?.options?.spousePercentageCap,
    props?.multiLinesToEdit[0]?.options?.dependentMaximumAmount,
    props?.multiLinesToEdit[0]?.options?.dependentPercentageCap,
  ]);

  return (
    <>
      <Box sx={componentStyles.displayMultipleLinesContainer}>
        {props?.multiLinesToEdit?.length > 0
          ? props?.multiLinesToEdit
              ?.sort((a, b) => {
                // Sort by calculatedPlanTypeId to display Member first
                return (
                  a?.lineOfCoverage?.calculatedPlanTypeId -
                  b?.lineOfCoverage?.calculatedPlanTypeId
                );
              })
              .map((line) => {
                let lowerCaseDisplayName = optionsPersonTypeDisplayName(
                  line?.lineOfCoverage?.displayName
                );
                return (
                  <FormControl fullWidth key={line?.lineOfCoverage?.id}>
                    <InputLabel>{`${
                      line?.lineOfCoverage?.displayName
                        ? props?.findSingleLineDisplayName(
                            line?.lineOfCoverage?.displayName
                          )
                        : ''
                    } Benefit`}</InputLabel>
                    <Select
                      sx={acDrawerSharedStyles.inputTextField}
                      label={`${
                        line?.lineOfCoverage?.displayName
                          ? props?.findSingleLineDisplayName(
                              line?.lineOfCoverage?.displayName
                            )
                          : ''
                      } Benefit`}
                      value={line?.editInfo?.benefitAmount || ''}
                      //disable dep options if no options based on member selection
                      disabled={
                        line?.lineOfCoverage?.calculatedPlanTypeId === 6 &&
                        dependentMaxAmount >=
                          laddSelections?.member?.editInfo?.benefitAmount
                      }
                    >
                      {line?.options?.[`${lowerCaseDisplayName}Options`]?.map(
                        (option) => {
                          //member options - greater than 0, and less than or equal to member's current coverage
                          if (
                            line?.lineOfCoverage?.calculatedPlanTypeId === 4 &&
                            // option.coverageAmount > 0 &&
                            option?.coverageAmount <=
                              line?.lineOfCoverage?.benefitAmount
                          ) {
                            return (
                              <MenuItem
                                value={option.coverageAmount}
                                key={option.coverageAmount}
                                onClick={(e) =>
                                  handleBenefitAmountChange(
                                    e,
                                    `${lowerCaseDisplayName}Options`,
                                    line?.lineOfCoverage?.id,
                                    option
                                  )
                                }
                              >
                                {formatCurrency(option?.coverageAmount || 0)}{' '}
                                {` (${formatCurrency(
                                  option?.premiumAmount || 0
                                )})`}
                              </MenuItem>
                            );
                          }
                          //only show options that are less than or equal to spouse max relative to member election, greater than 0, and less than or equal to member's current coverage
                          else if (
                            line?.lineOfCoverage?.calculatedPlanTypeId === 5 &&
                            option.coverageAmount <= spouseMaxAmount &&
                            // option.coverageAmount > 0 &&
                            option?.coverageAmount <=
                              line?.lineOfCoverage?.benefitAmount
                          ) {
                            return (
                              <MenuItem
                                value={option.coverageAmount}
                                key={option.coverageAmount}
                                onClick={(e) =>
                                  handleBenefitAmountChange(
                                    e,
                                    `${lowerCaseDisplayName}Options`,
                                    line?.lineOfCoverage?.id,
                                    option
                                  )
                                }
                              >
                                {formatCurrency(option?.coverageAmount || 0)}{' '}
                                {` (${formatCurrency(
                                  option?.premiumAmount || 0
                                )})`}
                              </MenuItem>
                            );
                          }
                          //only show options that are less than or equal to dependent max relative to member election, greater than 0, and less than or equal to member's current coverage
                          else if (
                            line?.lineOfCoverage?.calculatedPlanTypeId === 6 &&
                            option.coverageAmount <= dependentMaxAmount &&
                            // option.coverageAmount > 0 &&
                            option?.coverageAmount <=
                              line?.lineOfCoverage?.benefitAmount
                          ) {
                            return (
                              <MenuItem
                                value={option.coverageAmount}
                                key={option.coverageAmount}
                                onClick={(e) =>
                                  handleBenefitAmountChange(
                                    e,
                                    `${lowerCaseDisplayName}Options`,
                                    line?.lineOfCoverage?.id,
                                    option
                                  )
                                }
                              >
                                {formatCurrency(option?.coverageAmount || 0)}{' '}
                                {` (${formatCurrency(
                                  option?.premiumAmount || 0
                                )})`}
                              </MenuItem>
                            );
                          }
                        }
                      )}
                    </Select>
                  </FormControl>
                );
              })
          : 'No coverage found'}
      </Box>
    </>
  );
}
