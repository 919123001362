import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme, useMediaQuery, Fade } from '@mui/material';

//Assets
import FullColorBlackText from '../../../../assets/images/FullColorBlackText.svg';
import defaultLogo from '../../../../assets/images/FullColorOnlyLogo.png';

//Services
import enrollmentService from '../../../../services/enrollment-service';

//MuiComponents
import Button from '@mui/material/Button';
import { Checkbox, Divider, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

// MUI Icons
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Home from '@mui/icons-material/Home';

//Our components
import PoweredBy from '../PoweredBy';
import authService from '../../../../services/auth-service';
import InfinityLoader from '../../../UH-loading-animation/InfinityLoader';

//Azure
import { appInsights } from '../../../../ExternalApp';

export default function EnrollmentComplete(props) {
  const componentStyles = {
    logoContainer: {
      display: 'flex',
      margin: '6px auto',
      justifyContent: 'center',
      minHeight: '80px',
      minWidth: '80px',
    },
    image: {
      maxHeight: '86px',
      minHeight: '80px',
      width: '100%',
      objectFit: 'contain',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '5px',
      justifyContent: 'center',
      textAlign: 'center',
    },
  };

  appInsights.trackPageView({
    name: 'EnrollmentComplete Page 7',
    properties: { memberId: props?.fullMemberObj?.id },
  });

  let history = useHistory();

  const handleDownloadPDF = async () => {
    const link = document.createElement('a');
    // link.download = `/api/attachment/${props.guid}`;
    // link.href = `./api/attachment/${props.guid}`;
    if (props.agentEnroller) {
      let attachments = await enrollmentService.getAttachmentsForMember(
        props.fullMemberObj.id
      );

      link.download = `/api/attachment/${attachments[0]?.uniqueId}`;
      link.href = `./api/attachment/${attachments[0]?.uniqueId}`;
    } else {
      let attachments = await enrollmentService.getAttachments();
      link.download = `/api/attachment/${attachments[0]?.uniqueId}`;
      link.href = `./api/attachment/${attachments[0]?.uniqueId}`;
    }
    link.click();
  };

  const logout = () => {
    authService.signout();
    //localStorage.clear()
    history.push('/login');
  };

  const handleDashboardClick = () => {
    appInsights.trackEvent({
      name: 'EnrollmentComplete-DashboardClick',
      properties: { memberId: props.fullMemberObj?.id },
    });
    history.push('/dashboard');
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  const [ellipsis, setEllipsis] = useState('.');
  let ellipsisAnim = () => {
    setEllipsis((prev) => {
      if (prev == '...') return '';
      else return `${prev}.`;
    });
  };

  const [swapFade, setSwapFade] = useState(true);
  let fadeAnim = () => {
    setSwapFade((prev) => !prev);
  };

  useEffect(() => {
    let intervalId = setInterval(ellipsisAnim, 500);
    let swapIntervalId = setInterval(fadeAnim, 1800);
    if (!loading) {
      clearInterval(intervalId);
      clearInterval(swapIntervalId);
    }
    // intervalFunc
  }, [loading]);

  const [imgFetchAttempts, setImgFetchAttempts] = useState(0);
  const handleLogoError = (e) => {
    setImgFetchAttempts((prev) => prev++);
    if (imgFetchAttempts <= 10) e.target.src = defaultLogo;
  };

  return (
    <div
      className="flex-container"
      style={{
        height: props.agentEnroller ? 'calc(100vh - 276px)' : '',
        maxWidth: props.agentEnroller ? '' : '600px',
        margin: props.agentEnroller ? '' : '0 auto',
        padding: props.agentEnroller ? '20px' : '40px',
        minHeight: props.agentEnroller ? '' : 'calc(100vh - 60px)',
        width: '100%',
      }}
    >
      <div
        className="flex-row"
        style={{ marginBottom: '20px', padding: '0px', textAlign: 'center' }}
      >
        <img
          src={
            props.agentEnroller
              ? `api/image/organization/${props.fullMemberObj?.organizationId}`
              : '/api/image/organization'
          }
          style={componentStyles.image}
          onError={handleLogoError}
        />
      </div>

      <div
        className="flex-row"
        style={{
          marginBottom: '20px',
          padding: '0px',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="subtitle1" fontWeight="600">
          Congratulations! You have successfully enrolled.{' '}
        </Typography>
      </div>

      {props.notActivelyWorking && (
        <div
          className="flex-row"
          style={{
            marginBottom: '24px',
            padding: '0px',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Alert
            fullWidth
            severity="warning"
            sx={{ lineHeight: '20.02px', letterSpacing: '.17px' }}
          >
            A benefit advisor is reviewing your working status and will confirm
            coverage with you.
          </Alert>
        </div>
      )}

      <div
        className="row"
        style={{
          marginBottom: '20px',
          padding: '0px',
          textAlign: props.agentEnroller ? 'center' : 'left',
        }}
      >
        <Typography variant="body1" fontWeight="500" marginBottom="15px">
          Look out for your confirmation email sent from{' '}
          <Link sx={{ cursor: 'pointer' }}>enrolled@unionhub.com</Link>.
        </Typography>
        <Typography variant="body1" fontWeight="500">
          Click below to download a copy of your completed enrollment form.
        </Typography>
      </div>

      <div
        className="row"
        style={{
          marginBottom: '20px',
          padding: '0px',
          textAlign: props.agentEnroller ? 'center' : 'left',
          justifyContent: props.agentEnroller ? 'center' : '',
        }}
      >
        <Button
          disabled={loading}
          variant="contained"
          sx={{ height: 50, maxWidth: props.agentEnroller ? '400px' : '100%' }}
          onClick={handleDownloadPDF}
        >
          {loading ? (
            <>
              <Fade
                in={swapFade}
                timeout={900}
                unmountOnExit
                style={{ position: 'absolute' }}
              >
                <div>
                  <InfinityLoader
                    style={{
                      zIndex: 5,
                      height: '41px',
                    }}
                  />
                </div>
              </Fade>
              <Fade in={!swapFade} timeout={900} unmountOnExit>
                <span>Generating Enrollment PDF{ellipsis}</span>
              </Fade>
            </>
          ) : (
            <>
              <DownloadIcon /> Download Enrollment PDF
            </>
          )}
        </Button>
      </div>
      {!props.agentEnroller && (
        <div
          className="row"
          style={{ padding: '0px', textAlign: 'left', marginBottom: '20px' }}
        >
          <Button variant="outlined" sx={{ height: 50 }} onClick={logout}>
            Log Out
          </Button>
        </div>
      )}
      {!props.agentEnroller && (
        <div className="row" style={{ padding: '0px', textAlign: 'left' }}>
          <Button
            variant="text"
            sx={{ height: 50 }}
            onClick={handleDashboardClick}
            startIcon={<Home />}
          >
            Go to homepage
          </Button>
        </div>
      )}

      {/* <div className="row" style={{ marginBottom: '20px', padding: '0px', textAlign: 'left', margin: 'auto' }} >
            <Button variant="outlined" sx={{height:50}} onClick={handleHomeClick}><ArrowBackIcon/>Go Home</Button>
         </div> */}

      {/* <StickyProgressTotal progress={90} nextClickHandler={handleNextClick} /> */}
    </div>
  );
}
