import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

//services
import enrollmentService from '../../../../../../../services/enrollment-service';
import activeCoverageService from '../../../../../../../services/activeCoverage-service';

// Assets

//Mui Components
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Alert, Box, Typography, Checkbox } from '@mui/material';

//Mui icons
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoVisibilityIcon from '@mui/icons-material/VisibilityOff';

//Our Components
import InfinityLoader from '../../../../../../UH-loading-animation/InfinityLoader';
import { set, format } from 'date-fns';

//component styles
const componentStyles = {
  componentBody: {
    fontFamily: 'Poppins',
    minWidth: '538px',
    height: '100%',
    backgroundColor: 'background.default',
    overflowY: 'auto',
  },
  headerContainer: {
    width: '100%',
    padding: '10px 30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 auto',
    borderBottom: '1px solid',
    borderColor: 'primary.main',
  },
  drawerTitle: {
    color: 'text.primary',
    // fontFamily: 'Poppins',
    // fontSize: '22px',
    // fontWeight: '400',
  },
  infoContainer: {
    width: '100%',
    padding: '10px 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    margin: '0 auto',
  },
  policyDetails: {
    fontFamily: 'Archivo',
    fontSize: '13px!important',
    fontWeight: 400,
    color: 'text.secondary',
    lineHeight: '20px',
  },
  description: {
    color: 'text.primary',
    fontFamily: 'Archivo',
    fontSize: '14px',
    fontWeight: '500',
    padding: '10px 16px 10px 20px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  closeDrawerButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};
export default function CancelCoverageDrawer(props) {
  //Drawer Logic
  const handleCloseDrawer = () => {
    props?.setCancelCoverageDrawerOpen(false);
    //clear selected plan if canceling AC vs enrollment during open enrollment
    if (!props?.cancelEnrollment) {
      props?.setCancelDateError(false);
      props?.setReasonForCancelation('');
      //reset date of cancelation
      props?.setDateOfCancelation(props?.lastDayOfCurrentMonthString);
      //clear if CANCEL ALL coverage was selected
      props?.setCancelAllCoverage(false);
    }
    setGenerateNewForm(true);
  };

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  //may need to move this to cancel single line in AC drawer - currently not being used here because it's to regen pdf after a single line has been cancelled 
  //checkbox logic for download enrollment elections repots
  const [generateNewForm, setGenerateNewForm] = useState(true);

  // const downloadEnrollmentElectionsReport = async () => {
  //   const link = document.createElement('a');
  //   let sendEmail = false;

  //   try {
  //     let attachmentId = await enrollmentService.generateEnrollmentReport(
  //       props?.planToCancel?.enrollmentId,
  //       sendEmail
  //     );
  //     if (attachmentId) {
  //       link.download = `/api/attachment/${attachmentId}`;
  //       link.href = `./api/attachment/${attachmentId}`;
  //     }
  //   } catch (error) {
  //     console.error('error generating enrollment report', error);
  //   }

  //   setTimeout(() => {
  //     link.click();
  //     props?.getAllDocuments();
  //   }, 500);
  // };

  //submit cancelation
  const submitCancelation = async () => {
    setLoading(true);
    //Cancel all coverage
    if (props?.cancelAllCoverage) {
      props?.handleCancelAllCoverage();
    } else {
      //cancel enrollment during open enrollment
      if (props?.cancelEnrollment) {
        let memberId = props?.member?.id;
        let enrollmentId = props?.planEnrollmentId;
        let errorCanceling = false;

        try {
          let cancelEnrollment = await enrollmentService.cancelEnrollment(
            enrollmentId,
            memberId
          );
        } catch (error) {
          console.error('error canceling all coverage', error);
          props?.setCancelAllError(error);
          //local error value for conditional rendering of modal
          errorCanceling = true;
          setLoading(false);
        }
        setTimeout(() => {
          //refresh enrollment, Active Coverage, and Payment Schedule
          props?.getThisPlan();
          props?.getActiveCoverage
            ? props?.getActiveCoverage(props?.member?.id)
            : console.log('no getActiveCoverage method');
          props?.getPaymentSchedule
            ? props?.getPaymentSchedule()
            : console.log('no getPaymentSchedule method');
          // props?.getPaymentSchedule()
          setLoading(false);
          //close modal if no error, otherwise display error
          if (!errorCanceling) {
            handleCloseDrawer();
          }
        }, 1000);
      }
    }

    //following cancel coverage, refresh
    //refresh payment schedule plan and cancellation list
    setTimeout(() => {
      props?.getPaymentSchedule
        ? props?.getPaymentSchedule()
        : console.log('no getPaymentSchedule method');
      props?.getActiveCoverage
        ? props?.getActiveCoverage(props?.member?.id)
        : console.log('no getActiveCoverage method');
      props?.getMemberHistory
        ? props?.getMemberHistory()
        : console.log('no getMemberHistory method');
      handleCloseDrawer();
      setLoading(false);
    }, 500);
  };

  //TO DO: potentially want to download in documents tab and documents section of plans tab  so it is updated
  // pass in getAllDocuments

  return (
    <>
      <Drawer
        anchor="right"
        open={props?.cancelCoverageDrawerOpen}
        onClose={handleCloseDrawer}
      >
        {loading && (
          <>
            <InfinityLoader
              style={{
                // position: "fixed",
                position: 'absolute',
                zIndex: 5,
                width: '100%',
                height: '100%',
                backdropFilter: 'blur(10px)',
              }}
            />
            <style>
              {`
                      div.lottie-div > svg {
                         height:55px!important;
                         position:absolute;
                         top:50%;
                      }
                   `}
            </style>
          </>
        )}
        <Box sx={componentStyles.componentBody}>
          <Box sx={componentStyles.headerContainer}>
            <Typography variant="h5" sx={componentStyles.drawerTitle}>
              {props?.cancelEnrollment ? 'Cancel Enrollment' : ''}
              {props?.cancelAllCoverage ? 'Cancel All Coverage' : ''}
            </Typography>
            <Box
              sx={componentStyles.closeDrawerButton}
              role="button"
              onClick={handleCloseDrawer}
            >
              <CloseIcon sx={{ height: '34px' }} />
            </Box>
          </Box>
          {/* Plan Info */}
          <Box sx={componentStyles.infoContainer}>
            <Typography
              variant="h6"
              sx={{
                marginBottom: '6px',
              }}
            >
              {!props?.cancelEnrollment ? '' : props?.singleEnrollment?.name}
            </Typography>

            {/* display this message if canceling enrollment during open enrollment */}
            {props?.cancelEnrollment ? (
              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: 400,
                  color: 'text.secondary',
                  wordWrap: 'break-word',
                  maxWidth: '538px',
                }}
              >
                Canceling this enrollment will prevent it from going into effect
                or making payments. This will leave the enrollment in a
                cancelled status that you can revert back during this enrollment
                window.
              </Typography>
            ) : (
              ''
            )}
          </Box>

          {/* Edit fields */}
          <Box sx={componentStyles.infoContainer}>
            {!props?.cancelEnrollment && (
              <>
                <TextField
                  disabled //temp disabled while we currently only allow cancelling on the first of the month - remove this and it will allow for 'now' or 'next month'
                  select
                  fullWidth
                  id="dateOfCancelation"
                  sx={{
                    marginBottom: '15px',
                  }}
                  label="Cancellation Effective Date"
                  value={props?.dateOfCancelation || ''}
                  onChange={props?.handleCancelDateChange}
                  InputLabelProps={{ shrink: true }}
                >
                  {props?.dateSelectOptions?.length > 0 &&
                    props?.dateSelectOptions.map((date) => {
                      return (
                        <MenuItem key={date.value} value={date.value}>
                          {`${date.name} (${date.displayDate})`}
                        </MenuItem>
                      );
                    })}
                </TextField>

                <TextField
                  fullWidth
                  id="reasonForCancelation"
                  onChange={(e) => {
                    props?.handleChangeReasonForCancelation(e);
                  }}
                  label="Reason for Cancellation"
                  value={props?.reasonForCancelation || ''}
                  select
                >
                  {props?.cancellationReasons &&
                    props?.cancellationReasons.map((reason) => {
                      return (
                        <MenuItem key={reason.id} value={reason.name}>
                          {reason.name}
                        </MenuItem>
                      );
                    })}
                </TextField>

                {/* <Box //not currently using this - need to rework with AC records
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '20px 0px',
                    position: 'relative',
                    marginBottom: '15px',
                    fontFamily: 'Archivo',
                  }}
                >
                  <Checkbox
                    onChange={(e) => setGenerateNewForm(!generateNewForm)}
                    // value={}
                    checked={generateNewForm}
                    name={'generateNewForm'}
                    sx={{
                      padding: '0px',
                      marginRight: '12px',
                    }}
                    icon={<CheckBoxOutlineBlankIcon sx={{ height: '19px' }} />}
                    checkedIcon={<CheckBoxIcon sx={{ height: '19px' }} />}
                  />
                  Generate Enrollment Elections Report
                </Box> */}
              </>
            )}
          </Box>
          {/* Buttons */}
          <Box sx={componentStyles.buttonContainer}>
            <Button sx={{ marginRight: '10px' }} onClick={handleCloseDrawer}>
              CANCEL
            </Button>
            <Button
              variant="contained"
              //do not currently need cancelation reason for canceling enrollment durin open enrollment
              disabled={
                (!props?.cancelEnrollment &&
                  (props?.reasonForCancelation === '' ||
                    !props?.reasonForCancelation)) ||
                props?.cancelActiveCoverageForMemberancelDateError
              }
              onClick={submitCancelation}
            >
              SUBMIT CANCELLATION
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
