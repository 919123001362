//services

// Assets

//Mui Components
import { Box, Typography, Checkbox, Stack } from '@mui/material';

//Mui icons
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

//Our Components

//utils

//component styles
const componentStyles = {
  checkboxRow: (coveragePendingCancel) => ({
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    color: coveragePendingCancel ? 'text.disabled' : 'text.primary',
  }),
  displayMultipleLinesContainer: {
    width: '100%',
    padding: '0px 0px 0px 30px',
  },
  checkboxContainer: {
    margin: '10px 0px',
  },
  helperText: {
    fontSize: '12px',
    color: 'text.lightGrey',
  },
  pendingCancelMessage: { marginLeft: '4px', fontStyle: 'italic' },
};

export default function CancelMultipleAcLinesSelection(props) {
  return (
    <>
      <Box>
        <Typography>
          Please select which coverage you would like to cancel:
        </Typography>
        <Box sx={componentStyles.displayMultipleLinesContainer}>
          <Stack sx={componentStyles.checkboxContainer}>
            {props?.lineToCancel?.length > 0
              ? props?.lineToCancel
                  ?.sort((a, b) => {
                    // Sort by calculatedPlanTypeId to display Member first
                    return (
                      a?.lineOfCoverage?.calculatedPlanTypeId -
                      b?.lineOfCoverage?.calculatedPlanTypeId
                    );
                  })
                  .map((line) => (
                    <Box
                      key={line?.lineOfCoverage?.id}
                      sx={componentStyles.checkboxRow(
                        line?.lineOfCoverage?.isCancelled
                      )}
                    >
                      <Checkbox
                        disabled={line?.lineOfCoverage?.isCancelled} //disable interaction if pending cancellation
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name={line?.lineOfCoverage?.displayName}
                        value={line?.lineOfCoverage?.id}
                        //checking ladd calculated plan type IDs only
                        checked={line?.lineOfCoverage?.cancelLineOfCoverage}
                        onChange={() =>
                          props?.handleUpdateLineSelected(
                            line?.lineOfCoverage
                          )
                        }
                      />
                      <Typography>
                        {line?.lineOfCoverage?.displayName
                          ? props?.findSingleLineDisplayName(
                              line?.lineOfCoverage?.displayName
                            )
                          : ''}
                      </Typography>
                      {line?.lineOfCoverage?.isCancelled && (
                        <Typography sx={componentStyles.pendingCancelMessage}>
                          - Pending Cancellation
                        </Typography>
                      )}
                    </Box>
                  ))
              : 'No coverage found'}
          </Stack>
          <Typography sx={componentStyles.helperText}>
            Cancelling member life coverage will automatically cancel all.
          </Typography>
        </Box>
      </Box>
    </>
  );
}
