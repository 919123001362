import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

//Mui icons

//Mui Components
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

//our components
import InfinityLoader from '../../../../../../../UH-loading-animation/InfinityLoader';
import EditCoverageEffectiveDateStepper from '../EditCoverageEffectiveDateStepper';
import BeneficiaryViewAndEdit from './BeneficiaryViewAndEdit';

//Utils
import {
  formatCurrency,
  formatUTCToShortDate,
} from '../../../../../../../utils';

//Active coverage global Styles
import activeCoverageStyles from './activeCoverageStyles';

//component local styles
const componentStyles = {
  menuOptions: {
    color: 'text.primary',
    fontFamily: 'Archivo',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    height: '48px',
    '&:hover': { backgroundColor: 'primary.light' },
  },
  benefitInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'top',
    minHeight: '40px',
    marginRight: '8px',
    fontFamily: 'Archivo',
  },
  benefitAmountContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '50%',
  },
  benefitAmountRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
    textAlign: 'right',
    width: '100%',
    minHeight: '40px',
  },
};

export default function ActiveLineOfCoverageLadd(props) {
  //loading state for future edit CED
  const [loading, setLoading] = useState(false);

  //set array of local Ladd plans in state of this component
  const [memberLaddPlan, setMemberLaddPlan] = useState({});
  const [totalPremium, setTotalPremium] = useState(0);

  useEffect(() => {
    if (props?.activeLaddPlansAndOptions?.length > 0) {
      //set member ladd plan object to only the active coverage object
      let _memberLaddPlan = props?.activeLaddPlansAndOptions.find((plan) => {
        return plan?.lineOfCoverage?.displayName === 'LADD';
      });
      setMemberLaddPlan(_memberLaddPlan?.lineOfCoverage);

      //get total premium amount for all ladd plans
      let total = 0;
      props?.activeLaddPlansAndOptions.forEach((plan) => {
        if (plan?.lineOfCoverage?.premiumAmount > 0) {
          total += plan?.lineOfCoverage?.premiumAmount;
        }
      });
      setTotalPremium(total);
    }
  }, [props?.activeLaddPlansAndOptions]);

  // const [edit, setEdit] = useState(false);
  const [editBeneficiary, setEditBeneficiary] = useState(false);

  //open verticle dot menu to edit or cancel coverage
  const [anchorEl, setAnchorEl] = useState({});

  const handleOpenRowMenu = (e) => {
    setAnchorEl({
      ...anchorEl,
      [e.currentTarget.value]: e.currentTarget,
    });
  };

  const handleCloseRowMenu = () => {
    setAnchorEl({});
  };

  const getSinglePlanDisplayName = (name) => {
    switch (name) {
      case 'LADD':
        return 'Member';
      case 'LADD-S':
        return 'Spouse';
      case 'LADD-D':
        return 'Dependent';
      default:
        return '';
    }
  };

  return (
    <Box sx={activeCoverageStyles.container}>
      {/* Plan Title and Amount */}
      <Box sx={activeCoverageStyles.planTitleRow}>
        <Typography sx={activeCoverageStyles.planTitle}>LIFE & AD&D</Typography>
        <Box sx={activeCoverageStyles.planAmountContainer}>
          <Typography sx={activeCoverageStyles.planAmount}>
            {formatCurrency(totalPremium) || ''}
          </Typography>
          <Typography sx={activeCoverageStyles.perMonth}>/per month</Typography>

          {/* Vertical 3 dot menu */}
          <Box>
            <IconButton
              onClick={(e) =>
                handleOpenRowMenu(e, props?.activeLaddPlansAndOptions)
              }
              value={
                props?.activeLaddPlansAndOptions[0]?.lineOfCoverage?.planId
              }
              sx={activeCoverageStyles.threeDotMenu.iconButton}
              disabled={props?.isFutureCoverage}
            >
              <MoreVertIcon
                sx={activeCoverageStyles.threeDotMenu.MoreVertIcon}
              />
            </IconButton>
            <Menu
              anchorEl={
                anchorEl?.[
                  props?.activeLaddPlansAndOptions[0]?.lineOfCoverage?.planId
                ]
              }
              open={Boolean(
                anchorEl?.[
                  props?.activeLaddPlansAndOptions[0]?.lineOfCoverage?.planId
                ]
              )}
              onClose={handleCloseRowMenu}
              sx={{ ul: { padding: '0px' }, justifyContent: 'flex-start' }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {/* manage coverage drawer button */}
              <MenuItem
                onClick={(e) => {
                  props?.handleOpenActiveCoverageDrawer(e, memberLaddPlan);
                  handleCloseRowMenu();
                }}
                sx={componentStyles.menuOptions}
                disabled={!props.allowModify}
              >
                Manage Coverage
              </MenuItem>

              <MenuItem
                //   value={}
                onClick={() => {
                  setEditBeneficiary(true);
                  handleCloseRowMenu();
                }}
                sx={componentStyles.menuOptions}
                disabled={
                  !props?.allowEditEnrollment || memberLaddPlan?.isCancelled
                }
              >
                Edit Beneficiaries
              </MenuItem>

              {/* Cancel all coverage option if only one line exists */}
              {props?.activePlansNotPendingCancel?.length === 1 && (
                <MenuItem
                  onClick={(e) => {
                    if (props?.activePlansNotPendingCancel?.length === 1) {
                      //cancel all coverage - open CancelCoverageDrawer
                      props?.handleOpenCancelCoverageDrawer(
                        null,
                        'cancelAllCoverage'
                      );
                    }
                    handleCloseRowMenu();
                  }}
                  sx={componentStyles.menuOptions}
                  disabled={!props?.allowModify}
                >
                  Cancel All Coverage
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Box>
      </Box>

      {/* BenefitAmount Section */}
      <Box sx={componentStyles.benefitInfoRow}>
        <Box>Benefit Amount</Box>

        {/* Benefit Amounts */}
        <Box sx={componentStyles.benefitAmountContainer}>
          {/* display current amounts from db */}
          {props?.activeLaddPlansAndOptions?.length > 0
            ? props?.activeLaddPlansAndOptions
                ?.sort(
                  (a, b) =>
                    a.lineOfCoverage?.calculatedPlanTypeId -
                    b.lineOfCoverage?.calculatedPlanTypeId
                )
                ?.map((plan, index) => {
                  return (
                    <Box key={index} sx={componentStyles.benefitAmountRow}>
                      {/* pending changes message */}
                      {plan?.lineOfCoverage?.futureActiveCoverageId ||
                      plan?.lineOfCoverage?.isCancelled
                        ? props?.pendingChangesMessage(plan?.lineOfCoverage)
                        : null}

                      <Box>
                        {getSinglePlanDisplayName(
                          plan?.lineOfCoverage?.displayName
                        )}
                      </Box>
                      <Box sx={{ margin: '0px 4px' }}>{' - '}</Box>
                      <Box>
                        {` ${formatCurrency(
                          plan?.lineOfCoverage?.benefitAmount
                        )} - 
                       ${formatCurrency(
                         plan?.lineOfCoverage?.premiumAmount
                       )}` || ''}
                      </Box>
                    </Box>
                  );
                })
            : null}
        </Box>
      </Box>

      {/* Beneficiaries */}
      <BeneficiaryViewAndEdit
        edit={editBeneficiary}
        setEdit={setEditBeneficiary}
        member={props?.member}
        plan={memberLaddPlan}
        relationshipTypes={props?.relationshipTypes}
      />

      {/* Coverage Effective Date & Save/cancel edit coverage buttons */}

      <Box sx={activeCoverageStyles.planInfoRow}>
        <Typography variant="body1">{`Effective Date: ${
          props?.activeLaddPlansAndOptions[0]?.lineOfCoverage
            ?.effectiveStartDate
            ? formatUTCToShortDate(
                props?.activeLaddPlansAndOptions[0]?.lineOfCoverage
                  ?.effectiveStartDate
              )
            : 'N/A'
        }`}</Typography>
      </Box>

      {/* {editCED &&(
          <Box sx={activeCoverageStyles.planInfoRow}>
            <EditCoverageEffectiveDateStepper
              currentEffectiveDate={
                props?.activeLaddPlansAndOptions[0]?.lineOfCoverage
                  ?.effectiveStartDate
              }
            />
            <Box sx={activeCoverageStyles.editButtonsContainer}>
              <Button
                variant="outlined"
                sx={activeCoverageStyles.saveAndCancelEditButtons}
                onClick={() => {
                  setEditCED(false);
                }}
              >
                BACK
              </Button>

              <Button
                variant="contained"
                sx={activeCoverageStyles.saveAndCancelEditButtons}
                onClick={console.warn(
                        'this will be where we edit coverage effective date possibly happens'
                      )
                }
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Box sx={{ width: '100%', position: 'absolute' }}>
                      <InfinityLoader
                        style={{
                          zIndex: 5,
                          height: '30px',
                        }}
                      />
                    </Box>
                    <Typography sx={{ opacity: 0 }}>SAVE CHANGES</Typography>
                  </>
                ) : (
                  <>SAVE CHANGES</>
                )}
              </Button>
            </Box>
          </Box>
        )} */}
    </Box>
  );
}
