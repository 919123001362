import React, { useEffect, useState } from 'react';
import { format, differenceInCalendarYears, set } from 'date-fns';

//MuiIcons
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import WorkOffOutlinedIcon from '@mui/icons-material/WorkOffOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Mui Components
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Box, Chip, FormControl, InputLabel, Typography } from '@mui/material/';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';

// Our Components
import WorkingStatusChips from '../../../DisplayChips/WorkingStatusChips';
import memberService from '../../../../../../services/member-service';
import { margin } from '@mui/system';

//Our styles
import memberInfoStyles from '../memberInfoStyles';

//Component Styles
const componentStyles = {
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '16px',
    // border: "1px solid red",
  },
  employmentInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'space-between',
  },
  employmentInfoTitle: (edit) => ({
    display: 'flex',
    alignItems: 'top',
    fontSize: '16px',
    fontWeight: 400,
    marginBottom: 0,
    marginTop: edit ? '5px' : '0px',
    flex: 1,
  }),
  employmentInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    fontSize: '16px',
    fontWeight: 400,
    alignSelf: 'flex-end',
    textAlign: 'right',
    // border: '1px solid blue',
    maxWidth: '250px',
  },
  organizationInfo: {
    alignSelf: 'flex-end',
  },
  employmentEditInfo: {
    flex: 2,
  },
  inputField: {
    fontSize: '15px',
    height: '40px',
    maxWidth: '206px',
  },
  organizationSelect: {
    marginTop: '10px',
  },
  dividerSpacing: {
    margin: '7px 0',
  },
};
export default function MemberInfoMiddle(props) {
  let profileDisabled = props.profileDisabled;

  const [edit, setEdit] = useState(false);
  const [memberInfo, setMemberInfo] = useState();
  useEffect(() => {
    if (props.member) {
      let _memberInfo = {
        workStatusId: props.member.workStatusId,
        jobTitle: props.member.jobTitle,
        organizationName: props.member.organizationName,
        organizationId: props.member.organizationId,
        joinedUnionDate: props.member?.joinedUnionDate
          ? format(
              new Date(`${props.member.joinedUnionDate.slice(0, 10)}T00:00:00`),
              'yyyy-MM-dd'
            )
          : null,
        annualSalary: props.member.annualSalary,
        employerName: props.member.employerName,
        employerId: props.member.employerId,
        employers: props.employers,
      };
      setMemberInfo(_memberInfo);
    }
  }, [props.member]);

  const handleCancelClick = () => {
    setEdit(false);
    setInputError(false);
    //reset input values to original/before user input changes
    if (props.member) {
      let _memberInfo = {
        workStatusId: props.member.workStatusId,
        jobTitle: props.member.jobTitle,
        organizationName: props.member.organizationName,
        organizationId: props.member.organizationId,
        joinedUnionDate: props.member?.joinedUnionDate
          ? format(
              new Date(`${props.member.joinedUnionDate.slice(0, 10)}T00:00:00`),
              'yyyy-MM-dd'
            )
          : null,
        annualSalary: props.member.annualSalary,
        employerName: props.member.employerName,
        employerId: props.member.employerId,
        employers: props.employers,
      };

      setMemberInfo(_memberInfo);
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = (e) => {
    setOpen(true);
  };

  const [inputError, setInputError] = useState(false);

  let handleMemberInfoChange = (e, property) => {
    let value = e.target.value;
    //check salary amouont and display error if incorret amount entered
    if (property === 'annualSalary') {
      let numericSalary = Number(value);
      if (numericSalary < 10000) {
        setInputError(true);
      } else {
        setInputError(false);
      }
    }
    //update member info in state
    setMemberInfo({
      ...memberInfo,
      [property]: value,
    });
  };

  let updateMember = () => {
    //update member info in db IF no input errors
    if (!inputError) {
      props.updateMember(memberInfo);
      setEdit(false);
    }
  };

  let updateMemberOrganization = async () => {
    let updateMemberOrgId =
      memberOrganization.organizationId === '' &&
      memberOrganization.lowestOrganizationId === ''
        ? memberOrganization.topParentOrganizationId
        : memberOrganization.lowestOrganizationId === ''
        ? memberOrganization.organizationId
        : memberOrganization.lowestOrganizationId;

    let updateMemberOrg = {
      memberId: props?.member.id,
      isPrimary: true,
      organizationId: updateMemberOrgId,
    };

    try {
      await memberService.updateMemberOrganization(updateMemberOrg);
    } catch (error) {
      console.error('error updating member organization', error);
    }
  };

  const handleDobBlur = (e) => {
    if (!e.target.value) e.target.setAttribute('type', '');
  };

  const handleDobFocus = (e) => {
    e.target.setAttribute('type', 'date');
  };

  //HANDLE MEMBER ORGANIZATION INFO
  const [memberOrganization, setMemberOrganization] = useState({});

  useEffect(() => {
    //if the member has an organizationID and the organizations array has been populated, set the memberOrganization local state
    if (props?.member?.organizationId && props?.organizations?.length > 0) {
      //fing member organization in organizations array based off of id listed in member object
      let firstOrg = props.organizations?.find(
        (org) => org?.id === props?.member?.organizationId
      );
      let secondOrg = props.organizations?.find(
        (org) => org?.id === firstOrg?.parentId
      );
      let thirdOrg = props.organizations?.find(
        (org) => org?.id === secondOrg?.parentId
      );

      //if only one org exists
      if (firstOrg && !firstOrg?.parentId) {
        setMemberOrganization({
          topParentOrganizationId: firstOrg?.id,
          organizationId: '',
          lowestOrganizationId: '',
          association: firstOrg?.name,
          secondary: null,
          tertiary: null,
          displayName: firstOrg?.displayName,
        });
      }

      //if top and middle org exists
      if (firstOrg && secondOrg && !secondOrg?.parentId && !thirdOrg) {
        setMemberOrganization({
          topParentOrganizationId: secondOrg?.id,
          organizationId: firstOrg?.id,
          lowestOrganizationId: '',
          association: secondOrg?.name,
          secondary: firstOrg?.name,
          tertiary: null,
          displayName: firstOrg?.displayName,
        });
      }

      //if all orgs exist (first org listed in member object is actually the deepest/lowest org - we find all the rest up the pyramid based on this)
      if (firstOrg && secondOrg && thirdOrg && !thirdOrg?.parentId) {
        setMemberOrganization({
          topParentOrganizationId: thirdOrg?.id,
          organizationId: secondOrg?.id,
          lowestOrganizationId: firstOrg?.id,
          association: thirdOrg?.name,
          secondary: secondOrg?.name,
          tertiary: firstOrg?.name,
          displayName: firstOrg?.displayName,
        });
      }
    } else {
      setMemberOrganization({
        topParentOrganizationId: '',
        organizationId: '',
        lowestOrganizationId: '',
        association: null,
        secondary: null,
        tertiary: null,
        displayName: '',
      });
    }
  }, [props.organizations, props?.member?.organizationId]);

  const handleOrganizationInformation = (e, property) => {
    let orgId = e.target.value;
    let newOrg = props?.organizations?.find((org) => org?.id === orgId);

    if (property === 'topParentOrganizationId') {
      setMemberOrganization({
        ...memberOrganization,
        [property]: orgId,
        organizationId: '',
        lowestOrganizationId: '',
      });
    } else if (property === 'organizationId') {
      setMemberOrganization({
        ...memberOrganization,
        [property]: orgId,
        lowestOrganizationId: '',
      });
    } else {
      setMemberOrganization({
        ...memberOrganization,
        [property]: orgId,
      });
    }

    //set global state for memberOrg held in memberProfile if it differs from current
    if (orgId !== props?.member?.organizationId) {
      //update local state of member info
      setMemberInfo({
        ...memberInfo,
        organizationId: orgId,
        organizationName: newOrg?.displayName,
        employerId: '',
        employerName: '',
      });
      props.getEmployers(orgId);
    }
  };

  // const [employerObj, setEmployerObj] = useState({});
  const handleEmployerChange = (e, property) => {
    let empId = property?.id;
    let newEmployer = [];
    newEmployer = props?.employers.find((emp) => emp?.id === empId);
    setMemberInfo({
      ...memberInfo,
      employerId: newEmployer?.id,
      employerName: newEmployer?.name,
    });
  };

  const handleHasChildren = (id) => {
    let children = props?.organizations?.find(
      (organization) => organization.parentId === id
    );
    if (children) {
      return true;
    } else return false;
  };

  function isAsttuEligible(unionId) {
    let attsuEligibleOrgIds = [
      3, //IBEW
      461, //IBEW Rail
      464, //SMART
      9, //UA
    ];

    return attsuEligibleOrgIds.includes(unionId);
  }

  const displayOrganization = () => {
    return memberOrganization.secondary;
  };

  const displayUnion = () => {
    if (props.organizations) {
      let union = props.organizations.find(
        (org) => org.id === memberOrganization.topParentOrganizationId
      );
      return union?.displayName;
    }
  };

  const displayTertiary = () => {
    if (props.organizations) {
      let tertiary = props.organizations.find(
        (org) => org.id === memberOrganization.lowestOrganizationId
      );
      return tertiary?.displayName;
    }
  };

  // New for ASTTU Associations
  // TODO: Update to reflect actual association once backend work is completed
  const displayAssociation = () => {
    if (props.organizations) {
      let association = props.organizations.find(
        (org) => org.id === memberOrganization.topParentOrganizationId
      );
      return isAsttuEligible(association?.id) ? 'ASTTU' : null;
    }
  };

  return (
    <Box sx={memberInfoStyles.sectionContainer}>
      {/* Title and Edit Button */}
      <Box sx={memberInfoStyles.sectionHeaderContainer}>
        <Typography variant="h5" sx={memberInfoStyles.sectionTitle}>
          Employment
        </Typography>
        <Box sx={memberInfoStyles.editButtonsContainer}>
          {!edit ? (
            <Button
              disabled={!memberInfo || profileDisabled}
              variant="contained"
              // type='button'
              onClick={() => setEdit(true)}
              sx={memberInfoStyles.editButton}
            >
              EDIT
            </Button>
          ) : (
            <>
              <Button
                size="small"
                variant="text"
                onClick={handleCancelClick}
                sx={memberInfoStyles.cancelEditButton}
              >
                CANCEL
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  updateMember();
                  // updateMemberOrganization()
                }}
                sx={memberInfoStyles.saveEditButton}
              >
                SAVE
              </Button>
            </>
          )}
        </Box>
      </Box>

      {/* Employment Info Body */}
      <Box sx={componentStyles.infoContainer}>
        {/* Working Status */}
        <Box sx={componentStyles.employmentInfoRow}>
          <Box sx={componentStyles.employmentInfoTitle()}>Working Status</Box>
          {!edit && (
            <Box sx={componentStyles.employmentInfo}>
              <WorkingStatusChips
                workingStatusId={memberInfo?.workStatusId}
                employmentStatuses={props?.employmentStatuses}
              />
            </Box>
          )}

          {edit && (
            <Box sx={componentStyles.employmentEditInfo}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Working Status</InputLabel>
                <Select
                  variant="outlined"
                  label="Working Status"
                  size="small"
                  fullWidth
                  value={
                    (props.employmentStatuses &&
                      props.employmentStatuses.find(
                        (status) => status.id === memberInfo.workStatusId
                      )?.id) ||
                    0
                  }
                  onChange={(e) => handleMemberInfoChange(e, 'workStatusId')}
                >
                  {props?.employmentStatuses?.length > 0 &&
                    props.employmentStatuses.map((status) => {
                      return (
                        <MenuItem value={status.id} key={status.id}>
                          {status.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>

        {edit && <Divider sx={componentStyles.dividerSpacing} />}

        {/* Add in the displayAssociation method here once all the ASTTU stuff is good to go */}
        <Box sx={componentStyles.employmentInfoRow}>
          <Box sx={componentStyles.employmentInfoTitle(edit)}>Association</Box>
          {!edit && <Box sx={componentStyles.employmentInfo}>{'--'}</Box>}
          {edit && (
            <Box sx={componentStyles.employmentEditInfo}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  select={false}
                  variant="outlined"
                  label="Association"
                  size="small"
                  fullWidth
                  sx={componentStyles.inputField}
                  value={'--'}
                ></TextField>
              </FormControl>
            </Box>
          )}
        </Box>

        {edit && <Divider sx={componentStyles.dividerSpacing} />}

        {/* Organization - Top Org */}
        <Box component="container" sx={componentStyles.employmentInfoRow}>
          <Box sx={componentStyles.employmentInfoTitle(edit)}>Organization</Box>
          {!edit && (
            <Box sx={componentStyles.employmentInfo}>
              <Box sx={componentStyles.organizationInfo}>
                {displayUnion() ?? ''}
              </Box>
              <Box sx={componentStyles.organizationInfo}>
                {displayOrganization() ?? ''}
              </Box>
              <Box sx={componentStyles.organizationInfo}>
                {displayTertiary() ?? ''}
              </Box>
            </Box>
          )}
          {edit && (
            <Box sx={componentStyles.employmentEditInfo}>
              {/* Top Parent Organization */}
              <FormControl fullWidth>
                <TextField
                  select
                  variant="outlined"
                  labelId="topParentOrganizationId"
                  label="Union"
                  size="small"
                  fullWidth
                  sx={componentStyles.inputField}
                  value={memberOrganization?.topParentOrganizationId || ''}
                  onChange={(e) =>
                    handleOrganizationInformation(e, 'topParentOrganizationId')
                  }
                >
                  {props?.organizations &&
                    props?.organizations.map((organization) => {
                      if (!organization.parentId) {
                        return (
                          <MenuItem
                            key={organization.id}
                            value={organization.id}
                          >
                            {organization.displayName}
                          </MenuItem>
                        );
                      }
                    })}
                </TextField>
              </FormControl>

              {/* Organization 2nd */}
              {handleHasChildren(
                memberOrganization?.topParentOrganizationId
              ) && (
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={componentStyles.organizationSelect}
                >
                  <TextField
                    select
                    variant="outlined"
                    labelId="organizationId"
                    label="Secondary"
                    size="small"
                    sx={componentStyles.inputField}
                    value={memberOrganization?.organizationId || ''}
                    onChange={(e) =>
                      handleOrganizationInformation(e, 'organizationId')
                    }
                  >
                    {props?.organizations &&
                      props?.organizations.map((organization) => {
                        if (
                          organization.parentId ===
                          memberOrganization?.topParentOrganizationId
                        ) {
                          return (
                            <MenuItem
                              value={organization.id}
                              key={organization.id}
                            >
                              {organization.name}
                            </MenuItem>
                          );
                        }
                      })}
                  </TextField>
                </FormControl>
              )}

              {/* Organization - 3rd */}
              {handleHasChildren(memberOrganization?.organizationId) && (
                <FormControl fullWidth sx={componentStyles.organizationSelect}>
                  <TextField
                    select
                    variant="outlined"
                    labelId="lowestOrganizationId"
                    label="Tertiary"
                    size="small"
                    sx={componentStyles.inputField}
                    value={memberOrganization?.lowestOrganizationId || ''}
                    onChange={(e) =>
                      handleOrganizationInformation(e, 'lowestOrganizationId')
                    }
                  >
                    {props?.organizations &&
                      props?.organizations.map((organization) => {
                        if (
                          organization.parentId ==
                          memberOrganization?.organizationId
                        ) {
                          return (
                            <MenuItem value={organization.id}>
                              {organization.name}
                            </MenuItem>
                          );
                        }
                      })}
                  </TextField>
                </FormControl>
              )}
            </Box>
          )}
        </Box>

        {edit && <Divider sx={componentStyles.dividerSpacing} />}

        {/* Employer */}
        <Box component="container" sx={componentStyles.employmentInfoRow}>
          <Box sx={componentStyles.employmentInfoTitle(edit)}>Employer</Box>
          {!edit && (
            <Box sx={componentStyles.employmentInfo}>
              {(memberInfo?.employerName && memberInfo.employerName) || '--'}
            </Box>
          )}
          {edit && (
            <Box sx={componentStyles.employmentEditInfo}>
              <Autocomplete
                disablePortal
                fullWidth
                size="small"
                options={props?.employers || []}
                noOptionsText="Please select an organization to see employer options"
                open={open}
                onOpen={handleOpen}
                onClose={(e) => setOpen(false)}
                onClick={(e) => setOpen(true)}
                forcePopupIcon={true}
                popupIcon={<ArrowDropDownIcon />}
                value={{ name: memberInfo.employerName } || null}
                onChange={handleEmployerChange}
                sx={{
                  ...componentStyles.inputField,
                  '.MuiAutocomplete-inputRoot': {
                    padding: '0px 0px 0px 8px',
                    height: '40px',
                  },
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Employer" variant="outlined" />
                )}
                getOptionLabel={(option) => (option.name ? option.name : '')}
                renderOption={(params) => {
                  return (
                    <Stack
                      direction="row"
                      spacing={1}
                      {...params}
                      key={params['data-option-index'] + 1}
                    >
                      {params.key}
                    </Stack>
                  );
                }}
              />
            </Box>
          )}
        </Box>

        {edit && <Divider sx={componentStyles.dividerSpacing} />}

        {/* Job Title */}
        <Box sx={componentStyles.employmentInfoRow}>
          <Box sx={componentStyles.employmentInfoTitle(edit)}>Job Title</Box>
          {!edit && (
            <Box sx={componentStyles.employmentInfo}>
              {(props.member?.jobTitle && props.member?.jobTitle) || '--'}
            </Box>
          )}
          {edit && (
            <Box sx={componentStyles.employmentEditInfo}>
              <TextField
                variant="outlined"
                label="Job Title"
                size="small"
                fullWidth
                // sx={{marginBottom:'14px'}}
                value={memberInfo?.jobTitle || ''}
                onChange={(e) => handleMemberInfoChange(e, 'jobTitle')}
                InputProps={{
                  sx: {
                    '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                      border: '2px solid primary.main',
                    },
                  },
                }}
              />
            </Box>
          )}
        </Box>

        {edit && <Divider sx={componentStyles.dividerSpacing} />}

        {/* Joined Date */}
        <Box sx={componentStyles.employmentInfoRow}>
          <Box sx={componentStyles.employmentInfoTitle(edit)}>Joined</Box>
          {!edit && (
            <Box sx={componentStyles.employmentInfo}>
              {(props.member?.joinedUnionDate &&
                new Date(props.member?.joinedUnionDate) >
                  new Date('1900-01-01') &&
                format(
                  new Date(
                    `${props.member.joinedUnionDate.slice(0, 10)}T00:00:00`
                  ),
                  'MMM yyyy'
                )) ||
                '--'}
            </Box>
          )}
          {edit && (
            <Box sx={componentStyles.employmentEditInfo}>
              <TextField
                variant="outlined"
                label="Join Date"
                size="small"
                fullWidth
                type={memberInfo?.joinedUnionDate ? 'date' : ''}
                onFocus={handleDobFocus}
                onBlur={handleDobBlur}
                // sx={{marginBottom:'14px'}}
                // value={memberInfo.joinedUnionDate}
                value={memberInfo?.joinedUnionDate || null}
                onChange={(e) => handleMemberInfoChange(e, 'joinedUnionDate')}
                InputProps={{
                  sx: {
                    '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                      border: '2px solid primary.main',
                    },
                  },
                }}
              />
            </Box>
          )}
        </Box>

        {edit && <Divider sx={componentStyles.dividerSpacing} />}

        {/* Salary */}
        <Box sx={componentStyles.employmentInfoRow}>
          <Box sx={componentStyles.employmentInfoTitle(edit)}>Salary</Box>
          {!edit && (
            <Box sx={componentStyles.employmentInfo}>
              {(props.member?.annualSalary &&
                props.member &&
                props.UsdFormatter.format(props.member.annualSalary)) ||
                '--'}
            </Box>
          )}
          {edit && (
            <Box sx={componentStyles.employmentEditInfo}>
              <TextField
                variant="outlined"
                error={inputError}
                helperText={inputError && 'Please enter a valid salary'}
                label="Salary"
                size="small"
                fullWidth
                // sx={{marginBottom:'14px'}}
                value={memberInfo?.annualSalary || 0}
                onChange={(e) => handleMemberInfoChange(e, 'annualSalary')}
                InputProps={{
                  sx: {
                    '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                      border: '2px solid primary.main',
                    },
                  },
                }}
              />
            </Box>
          )}
        </Box>

        {edit && <Divider sx={componentStyles.dividerSpacing} />}

        {/* Retirement */}
        <Box sx={componentStyles.employmentInfoRow}>
          <Box sx={componentStyles.employmentInfoTitle(edit)}>Retirement</Box>
          {!edit && <Box sx={componentStyles.employmentInfo}>--</Box>}
          {edit && (
            <Box sx={componentStyles.employmentEditInfo}>
              <TextField
                disabled
                variant="outlined"
                label="Retirement Date"
                size="small"
                fullWidth
                InputProps={{
                  sx: {
                    '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                      border: '2px solid primary.main',
                    },
                  },
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
