import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export let todayDateObj = new Date();
export let todayDateShort = formatDateObjYYYYMMDD(todayDateObj);
export let day = todayDateObj.getDate();
export let month = todayDateObj.getMonth() + 1;
export let year = todayDateObj.getFullYear();
let formatDay = day < 10 ? '0' + day : day;
let formatMonth = month < 10 ? '0' + month : month;
let dateMax = `${year - 18}-${month}-${day}`;
let dateMaxObj = new Date(dateMax);
let dateMin = `${year - 100}-${month}-${day}`;
let dateMinObj = new Date(dateMin);

export let firstDayOfCurrentMonth = new Date(year, month - 1, 1);
export let lastDayOfCurrentMonth = new Date(year, month, 0);
export let firstDayOfNextMonth = new Date(year, month, 1);
export let lastDayOfPreviousMonth = new Date(year, month - 1, 0);

//TIME ZONE
export const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

//Format all UTC dates to date obj with user's local timezone
// date comes in looking like this: 1984-08-10T00:00:00+00:00

//USE FOR all dates where we do not care about time
export function formatUTCDateToObjWithTimeZone(date) {
  //take off the time part of the date string for DOBs
  date = date.split('T')[0];
  const dateObj = parseISO(date); //updates any utc date string into date object using local timezone
  const localTimezoneDateObj = utcToZonedTime(dateObj, userTimeZone);
  // console.log('localDate',localTimezoneDateObj)
  return localTimezoneDateObj;
}
//format date AND time for history tab display or when we care about the TIME portion of UTC date string
export function parseUTCDateToZonedTime(date) {
  const dateObj = parseISO(date);
  const localTimezoneDateObj = utcToZonedTime(dateObj, userTimeZone);
  return localTimezoneDateObj;
}

//format a short date string yyyy-MM-dd to UTC date string
export function formatShortDateToUTC(date) {
  if (!date) return;
  let utcDate = date + 'T00:00:00Z';
  return utcDate;
}

/////////////Dates with spelling out month and including time
//format PRETTY Month Day Year - ex: March 31, 2025
export function formatMonthDayYear(date) {
  if (!date) return;
  const dateObj = formatUTCDateToObjWithTimeZone(date);
  const formattedDate = format(dateObj, 'MMMM dd, yyyy');
  return formattedDate;
}

//format PRETTY Weekday Month Day Year - ex: Mon, Mar 31, 2025
export function formatWeekdayMonthDayYear(date) {
  if (!date) return;
  const dateObj = formatUTCDateToObjWithTimeZone(date);
  const formattedDate = format(dateObj, 'EEE, MMM dd, yyyy');
  return formattedDate;
}

//format PRETTY Weekday Month Day Year - ex: Mon, Mar 31, 2025, 4 AM
export function formatWeekdayMonthDayYearTime(date) {
  if (!date) return;
  const dateObj = parseISO(date);
  const localTimezoneDateObj = utcToZonedTime(dateObj, userTimeZone);
  const formattedDate = format(localTimezoneDateObj, 'EEE, MMM dd, yyyy, haaa');
  const capitalAM = formattedDate.replace('am', 'AM').replace('pm', 'PM');
  return capitalAM;
}
/////////////format dates to yyyy-MM-dd format

//format date obj to short date string 'yyyy-MM-dd'
export function formatDateObjYYYYMMDD(dateObj) {
  if (!dateObj) return;
  const formattedDate = format(dateObj, 'yyyy-MM-dd');
  return formattedDate;
}

//format uct date string to short date string 'yyyy-MM-dd' format
export function formatUTCYYYYMMDD(utcDate) {
  if (!utcDate) return;
  const dateObj = formatUTCDateToObjWithTimeZone(utcDate);
  const formattedDate = format(dateObj, 'yyyy-MM-dd');
  return formattedDate;
}

//////////////format dates to MM/dd/yyyy format

//format date obj to short date string for nice display 'MM/dd/yyyy'
export function formatDateObjMMDDYYYYDisplay(dateObj) {
  const formattedDate = format(dateObj, 'MM/dd/yyyy');
  return formattedDate;
}

//format utc date string to short date string
export function formatUTCToShortDate(utcDate) {
  if (!utcDate) return;
  const dateObj = formatUTCDateToObjWithTimeZone(utcDate);
  const formattedDate = format(dateObj, 'MM/dd/yyyy');
  return formattedDate;
}

export function formatUTCToMonthDay(utcDate) {
  const dateObj = formatUTCDateToObjWithTimeZone(utcDate);
  const formattedDate = format(dateObj, 'MMM do');
  return formattedDate;
}

//get user's time converted to MST in 24hr formate
export function convertUserTimeToMST() {
  const date = new Date();
  const mountainTime = date.toLocaleTimeString('en-US', {
    timeZone: 'America/Denver',
    hour12: false,
  });
  return mountainTime;
}

//DEVICE DETECT
export function checkMobileDevice() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}

//Compare two objects
export function compareObjects(obj1, obj2) {
  // Get the keys of the first object
  const keys = Object.keys(obj1);

  // Iterate over the keys
  for (let key of keys) {
    // Check if the key exists in both objects and their values are different
    if (
      obj1.hasOwnProperty(key) &&
      obj2.hasOwnProperty(key) &&
      obj1[key] !== obj2[key]
    ) {
      console.log('obj1[key]', obj1[key]);
      return true; // Values have changed
    }
  }
  // If no differences found, return false
  return false;
}

//format large numbers with commas  ***********need to replace this where it is used in reporting dashboard
const formatNumWithCommas = (number) => {
  if (number === 0 || !number) return 0; //default to 0 if 0 or undefined
  if (number) {
    return number.toLocaleString('en-Us');
  }
};

export function millisecondToYear(milliseconds) {
  return Math.floor(milliseconds / 31536000000);
}

export function capitalizeFirstLetter(string) {
  if (!string) return;
  else if (string.length === 1) return string.toUpperCase();
  else return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatPhoneNumber(phoneNumberString) {
  if (!phoneNumberString) return;
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

export function formatCurrency(number) {
  if (!number) return;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(number);
}

//get display name from personTypeId for active coverage lines of coverage
export function getPersonTypeFromId(personTypeId) {
  switch (personTypeId) {
    case 0:
      return 'Member';
    case 1:
      return 'Member + Spouse';
    case 2:
      return 'Member + Dependent';
    case 3:
      return 'Member + Family';
    default:
      return '';
  }
}

function isWhitespace(str) {
  return !str.trim().length;
}

export function titleCase(str) {
  if (str && !isWhitespace(str)) {
    str = str.replace(/\s+/g, ' '); // Replace multiple adjacent spaces with a single space.  This prevents null entries in the following map
    return str
      .trim()
      .toLowerCase()
      .split(' ')
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(' ');
  } else return str;
}

//check for IBT air plan - survey responses used to render agent side open enrollment title - will not be passed in for current active coverage
export function isAirPlan(enrollObj, surveyResponses = { isPilot: false }) {
  //check if enrollment is beign returned from calculator service with plan options (will occurr if started and options selected)
  var benefitOffsetSearch =
    'Aeromedical Certification Services included with AMAS to work with FAA on your behalf';
  if (enrollObj?.stdPlan) {
    return (
      enrollObj?.stdPlan?.options?.filter(
        (opt) => opt.benefitOffsets === benefitOffsetSearch
      ).length > 0
    );
  }
  //check if member isPilot is true - display ibt air enrollment if it is
  if (surveyResponses && surveyResponses?.isPilot) return true;
}

// Format benefits, optionally as a percentage
export function formatBenefit(amount, isPercentage = false) {
  if (!amount || isNaN(amount)) return '';
  return isPercentage
    ? `${Number(amount).toFixed(0)}% Monthly Benefit`
    : `$${Number(amount).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })} Monthly Benefit`;
}

// Format a date string into "MM/DD"
export function formatMonthDay(dateString) {
  const date = new Date(dateString);
  if (isNaN(date)) return 'a future date';
  const month = date.getUTCMonth() + 1; // Months are zero-indexed
  const day = date.getUTCDate();
  return `${month}/${day}`;
}
