import React, { useContext, useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';

//MuiIcons

//MuiComponents
// import { Box } from '@mui/system';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

//Assets

// import planBuildService from '../../../../services/planBuild-service';

//Our components
// import { thisUserContext } from '../../nav-container/NavContainer';
// import OurDataGrid from '../../DataGrid/OurDataGrid';
// import InfinityLoader from '../../../UH-loading-animation/InfinityLoader';
import WindowRowGraph from './WindowRowGraph';

export default function WindowRow(props) {
  let plan = props.plan;
  let windows = plan.enrollmentWindows;
  let windowsArray = null;
  if (typeof windows === 'string') {
    windowsArray = JSON.parse(windows);
  }
  if (windowsArray) {
    windows = windowsArray;
  }

  const [expanded, setExpanded] = useState(false);
  const [timezone, setTimezone] = useState('UTC');

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '10px',
          borderBottom: '1px solid black',
          '&:hover': {
            backgroundColor: 'lightgray',
          },
          backgroundColor: '#F9F9F9',
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <Box
          sx={{
            display: 'inline-block',
            borderRight: '1px solid black',
            padding: '10px',
            width: '20%',
          }}
        >
          {plan.name}
        </Box>

        <WindowRowGraph {...props} plan={plan} windows={windows} />
      </Box>
      <Collapse in={expanded}>
        <Box sx={{ padding: '10px' }}>
          <Typography variant="h6">Windows</Typography>
          <Box sx={{ marginBottom: '10px' }}>
            <label htmlFor="timezone-select">Select Timezone: </label>
            <select
              id="timezone-select"
              onChange={(e) => setTimezone(e.target.value)}
            >
              <option value="UTC">UTC</option>
              <option value="PST">PST</option>
              <option value="EST">EST</option>
              <option value="CST">CST</option>
              <option value="MST">MST</option>
              <option value="HST">HST</option>
            </select>
          </Box>
          {windows.map((window, index) => (
            <Paper key={index} sx={{ padding: '10px', marginBottom: '10px' }}>
              <Typography variant="body1">
                {new Date(window.OpenEnrollmentStart).toLocaleString('en-US', {
                  timeZone: timezone,
                })}{' '}
                -{' '}
                {new Date(window.OpenEnrollmentEnd).toLocaleString('en-US', {
                  timeZone: timezone,
                })}
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'right' }}>
                <Typography variant="body3">Hawaii:</Typography>
                <br />
                {new Date(window.OpenEnrollmentStart).toLocaleString('en-US', {
                  timeZone: 'Pacific/Honolulu',
                })}{' '}
                -{' '}
                {new Date(window.OpenEnrollmentEnd).toLocaleString('en-US', {
                  timeZone: 'Pacific/Honolulu',
                })}
              </Typography>
            </Paper>
          ))}
        </Box>
      </Collapse>
    </>
  );
}
