import React from 'react';

//Mui icons

//Mui Components
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

//utils
import { formatCurrency } from '../../../../../../../../utils';

//Our styles
import activeCoverageStyles from '../activeCoverageStyles';

export default function AccAddCanOrHospitalCoverage(props) {
  return (
    <>
      {/* Benefit Amount Row */}
      {props?.plan?.benefitAmount && props?.plan?.benefitAmount > 0 ? (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <Typography variant="body1">Benefit Amount</Typography>

          <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
            {formatCurrency(props?.plan?.benefitAmount)}
          </Typography>
        </Box>
      ) : null}

      {/* Covered Lives - show for accident, add, hospital, and cancer plans*/}
      {props?.plan?.personType ? (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <Typography variant="body1">Covered Lives</Typography>

          <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
            {props?.plan?.personType === 0 ? 'Member' : props?.plan?.personType}
          </Typography>
        </Box>
      ) : null}
    </>
  );
}
