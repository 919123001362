import React, { useState } from 'react';
import './RecommendationSurvey.css';

//Mui Components
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import { Typography, Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
//MuiIcons
import CreateIcon from '@mui/icons-material/Create';
import externalEnrollmentSharedStyles from '../../app-external/externalEnrollmentSharedStyles';
//utils
import { DrawerContainer } from '../../../components/app-external/external-enrollments/enrollment-drawers/DrawerContainer';
import { formatDateObjMMDDYYYYDisplay } from '../../utils';

export default function RecommendationSurvey(props) {
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  // See what the current plan is -> this is active on Teamsters Voluntary Income Protection VIP+
  // console.log('Current results name:', props.results?.name);

  // REFACTORED STYLES FROM HTML TO MUI COMPONENTS
  const surveyStyles = {
    section: {
      paddingTop: '5px',
    },
    questionText: {
      marginBottom: '5px',
      variant: 'body1',
      marginTop: '5px',
    },
    questionTextspecial: {
      marginTop: '5px',
      variant: 'body1',
      marginBottom: '5px',
    },
    buttonBox: {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      width: '100%',
      marginBottom: '13px',
      marginTop: '13px',
    },
    buttonBoxspecial: {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      width: '100%',
    },
    button: {
      width: '100%',
      minHeight: '48px',
      marginBottom: '13px',
      '&.MuiButton-contained': {
        backgroundColor: (theme) => theme.palette.primary.main,
        color: 'white',
        '&:hover': {
          backgroundColor: (theme) => theme.palette.primary.accent,
        },
      },
      '&.MuiButton-outlined': {
        color: (theme) => theme.palette.primary.main,
        borderColor: (theme) => theme.palette.primary.main,
      },
      '&.Mui-disabled': {
        color: (theme) => theme.palette.primary.main,
        backgroundColor: 'white',
        border: (theme) => `1px solid ${theme.palette.primary.main}`,
      },
    },
    col6: {
      width: '50%',
      padding: '0 8px',
      flex: '0 0 50%',
    },
    col12: {
      width: '100%',
      padding: '0 8px',
    },
    infoIcon: {
      padding: '0 5px',
      '& svg': {
        color: (theme) => theme.palette.primary.main,
      },
    },
    calculatedContainer: {
      margin: '10px',
      justifySelf: 'center',
    },
    headerStack: {
      marginBottom: '15px',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    headerText: {
      fontWeight: 600,
      fontSize: '20px',
    },
    editButton: {
      marginLeft: '10px',
    },
    infoRow: {
      marginBottom: '12px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    valueText: {
      fontWeight: 600,
      color: (theme) => theme.palette.primary.main,
    },
    statusValue: {
      fontWeight: 600,
      color: '#CD2026', // default red for NO
      '&.positive': {
        color: '#2e8540', // green for YES
      },
    },
  };

  // Then in your components:
  <Box sx={surveyStyles.section}>
    <Typography sx={surveyStyles.questionText}>Are you married?</Typography>
    <Box sx={surveyStyles.buttonBox}>
      <Button
        variant={props.survey.IsMarried === true ? 'contained' : 'outlined'}
        onClick={() => props.surveyHandler('IsMarried', true)}
        sx={surveyStyles.button}
      >
        YES
      </Button>
    </Box>
  </Box>;
  // ----------------- Drawer -----------------
  // if the user is not employed, show the drawer
  // Inside RecommendationSurvey component, add state MOVED TO STANDALONE

  return (
    <>
      {!props.calculated && (
        <Grow in={!props.calculated} timeout={1000}>
          {/* ARE YOU CURRENTLY EMPLOYED SECTION */}
          <Box sx={surveyStyles.section}>
            <Typography sx={surveyStyles.questionText}>
              Are you currently employed?
              <IconButton
                onClick={() => props.setNawDrawerOpen(true)}
                sx={surveyStyles.infoIcon}
              >
                <InfoOutlinedIcon />
              </IconButton>
            </Typography>

            <Box sx={surveyStyles.buttonBox}>
              <Box sx={surveyStyles.col6}>
                <Button
                  variant={
                    props.survey.isEmployed === true ? 'contained' : 'outlined'
                  }
                  onClick={() => props.surveyHandler('isEmployed', true)}
                  sx={surveyStyles.button}
                >
                  YES
                </Button>
              </Box>

              <Box sx={surveyStyles.col6}>
                <Button
                  variant={
                    props.survey.isEmployed === false ? 'contained' : 'outlined'
                  }
                  onClick={() => {
                    props.surveyHandler('isEmployed', false);
                    props.setNawDrawerOpen(true);
                  }}
                  sx={surveyStyles.button}
                >
                  NO
                </Button>
              </Box>
            </Box>

            {/* ARE YOU A FULL TIME OR PART TIME EMPLOYEE SECTION */}
            {props.results?.name &&
              (props?.results?.name?.includes('IBT') ||
                props?.results?.name?.includes('Teamsters')) && (
                <Box sx={surveyStyles.section}>
                  <Typography sx={surveyStyles.questionText}>
                    Are you a full time or part time employee?
                  </Typography>

                  <Box sx={surveyStyles.buttonBox}>
                    <Box sx={surveyStyles.col6}>
                      <Button
                        variant={
                          props.survey.isFullTime === true
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => props.surveyHandler('isFullTime', true)}
                        sx={surveyStyles.button}
                      >
                        Full Time
                      </Button>
                    </Box>

                    <Box sx={surveyStyles.col6}>
                      <Button
                        variant={
                          props.survey.isFullTime === false
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => props.surveyHandler('isFullTime', false)}
                        sx={surveyStyles.button}
                      >
                        Part Time
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}

            {/* ARE YOU MARRIED SECTION  */}
            <Box sx={surveyStyles.section}>
              <Typography sx={surveyStyles.questionText}>
                Are you married?
              </Typography>

              <Box sx={surveyStyles.buttonBox}>
                <Box sx={surveyStyles.col6}>
                  <Button
                    variant={
                      props.survey.IsMarried === true ? 'contained' : 'outlined'
                    }
                    onClick={() => props.surveyHandler('IsMarried', true)}
                    sx={surveyStyles.button}
                  >
                    YES
                  </Button>
                </Box>

                <Box sx={surveyStyles.col6}>
                  <Button
                    variant={
                      props.survey.IsMarried === false
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => props.surveyHandler('IsMarried', false)}
                    sx={surveyStyles.button}
                  >
                    NO
                  </Button>
                </Box>
              </Box>
            </Box>

            {/* DO YOU HAVE DEPENDENTS SECTION */}
            <Box sx={surveyStyles.section}>
              <Typography sx={surveyStyles.questionText}>
                Do you have any dependents?
              </Typography>

              <Box sx={surveyStyles.buttonBox}>
                <Box sx={surveyStyles.col6}>
                  <Button
                    variant={
                      props.survey.HaveDependents === true
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => props.surveyHandler('HaveDependents', true)}
                    sx={surveyStyles.button}
                  >
                    YES
                  </Button>
                </Box>

                <Box sx={surveyStyles.col6}>
                  <Button
                    variant={
                      props.survey.HaveDependents === false
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => props.surveyHandler('HaveDependents', false)}
                    sx={surveyStyles.button}
                  >
                    NO
                  </Button>
                </Box>
              </Box>
            </Box>

            {/* ARE YOU THE PRIMARY household income SECTION */}
            <Box sx={surveyStyles.section}>
              <Typography sx={surveyStyles.questionText}>
                Are you the primary household income?
              </Typography>

              <Box sx={surveyStyles.buttonBox}>
                <Box sx={surveyStyles.col6}>
                  <Button
                    variant={
                      props.survey.IsPrimary === true ? 'contained' : 'outlined'
                    }
                    onClick={() => props.surveyHandler('IsPrimary', true)}
                    sx={surveyStyles.button}
                  >
                    YES
                  </Button>
                </Box>

                <Box sx={surveyStyles.col6}>
                  <Button
                    variant={
                      props.survey.IsPrimary === false
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => props.surveyHandler('IsPrimary', false)}
                    sx={surveyStyles.button}
                  >
                    NO
                  </Button>
                </Box>
              </Box>
            </Box>

            {/* Do you have a mortgage or rent SECTION? */}
            <Box sx={surveyStyles.section}>
              <Typography sx={surveyStyles.questionText}>
                Do you have a mortgage or rent?
              </Typography>

              <Box sx={surveyStyles.buttonBox}>
                <Box sx={surveyStyles.col6}>
                  <Button
                    variant={
                      props.survey.Mortgage === true ? 'contained' : 'outlined'
                    }
                    onClick={() => props.surveyHandler('Mortgage', true)}
                    sx={surveyStyles.button}
                  >
                    YES
                  </Button>
                </Box>

                <Box sx={surveyStyles.col6}>
                  <Button
                    variant={
                      props.survey.Mortgage === false ? 'contained' : 'outlined'
                    }
                    onClick={() => props.surveyHandler('Mortgage', false)}
                    sx={surveyStyles.button}
                  >
                    NO
                  </Button>
                </Box>
              </Box>
            </Box>

            {/* ARE YOU A FEMALE WANTING COVERAGE FOR PREGNANCY SECTION */}
            {props.results?.name && props?.results?.name?.includes('IUPAT') && (
              <Box sx={surveyStyles.section}>
                <Typography sx={surveyStyles.questionText}>
                  Are you a female wanting coverage for pregnancy?
                  <IconButton
                    onClick={() => props.setFamilyPlanningDrawerOpen(true)}
                    sx={surveyStyles.infoIcon}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </Typography>

                <Box sx={surveyStyles.buttonBox}>
                  <Box sx={surveyStyles.col6}>
                    <Button
                      variant={
                        props.survey.FamilyPlanning === true
                          ? 'contained'
                          : 'outlined'
                      }
                      onClick={() => {
                        props.surveyHandler('FamilyPlanning', true);
                        // props.setFamilyPlanningDrawerOpen(true);
                      }}
                      sx={surveyStyles.button}
                      disabled={props.survey.FamilyPlanning === false}
                    >
                      YES
                    </Button>
                  </Box>

                  <Box sx={surveyStyles.col6}>
                    <Button
                      variant={
                        props.survey.FamilyPlanning === false
                          ? 'contained'
                          : 'outlined'
                      }
                      onClick={() =>
                        props.surveyHandler('FamilyPlanning', false)
                      }
                      sx={surveyStyles.button}
                      disabled={props.survey.FamilyPlanning === true}
                    >
                      NO
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}

            {/* ARE YOU A RAILROAD EMPLOYEE SECTION */}
            {props.results?.name && props?.results?.name?.includes('ASTTU') && (
              <Box sx={surveyStyles.section}>
                <Typography sx={surveyStyles.questionText}>
                  Are you a railroad employee?
                  <IconButton
                    onClick={() => props.setWorksInRailDrawerOpen(true)}
                    sx={surveyStyles.infoIcon}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </Typography>

                <Box sx={surveyStyles.buttonBox}>
                  <Box sx={surveyStyles.col6}>
                    <Button
                      variant={
                        props.survey.WorksInRail === true
                          ? 'contained'
                          : 'outlined'
                      }
                      onClick={() => {
                        props.surveyHandler('WorksInRail', true);
                        // props.setWorksInRailDrawerOpen(true);
                      }}
                      sx={surveyStyles.button}
                      disabled={props.survey.WorksInRail === false}
                    >
                      YES
                    </Button>
                  </Box>

                  <Box sx={surveyStyles.col6}>
                    <Button
                      variant={
                        props.survey.WorksInRail === false
                          ? 'contained'
                          : 'outlined'
                      }
                      onClick={() => props.surveyHandler('WorksInRail', false)}
                      sx={surveyStyles.button}
                      disabled={props.survey.WorksInRail === true}
                    >
                      NO
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}

            {/* ARE YOU AN AIRLINE PILOT SECTION */}
            {props.results?.name &&
              (props?.results?.name?.includes('Teamsters') ||
                props?.results?.name?.includes('IBT')) && (
                <Box sx={surveyStyles.section}>
                  <Typography sx={surveyStyles.questionText}>
                    Are you an Airline Pilot?
                    <IconButton
                      onClick={() => props.setIsPilotDrawerOpen(true)}
                      sx={surveyStyles.infoIcon}
                    >
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Typography>

                  <Box sx={surveyStyles.buttonBox}>
                    <Box sx={surveyStyles.col6}>
                      <Button
                        variant={
                          props.survey.IsPilot === true
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => {
                          props.surveyHandler('IsPilot', true);
                        }}
                        sx={surveyStyles.button}
                        disabled={props.survey.IsPilot === false}
                      >
                        YES
                      </Button>
                    </Box>

                    <Box sx={surveyStyles.col6}>
                      <Button
                        variant={
                          props.survey.IsPilot === false
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => props.surveyHandler('IsPilot', false)}
                        sx={surveyStyles.button}
                        disabled={props.survey.IsPilot === true}
                      >
                        NO
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
          </Box>
        </Grow>
      )}

      {/* SECTION AFTER IT GETS CALCULATED */}
      {props.calculated && (
        <Grow in={props.calculated} timeout={1000}>
          <Box sx={surveyStyles.calculatedContainer}>
            {/* My Information TITLE  */}
            <Stack sx={surveyStyles.headerStack}>
              <Typography variant="h5" sx={surveyStyles.headerText}>
                My Information
              </Typography>
              <Button
                sx={surveyStyles.editButton}
                onClick={() => props.setCalculated(false)}
                startIcon={<CreateIcon color="primary.main" />}
              >
                Edit
              </Button>
            </Stack>
            {/* My Information DETAILS */}
            <Box sx={surveyStyles.infoRow}>
              <Typography sx={surveyStyles.questionText}>
                Estimated annual earnings
              </Typography>
              <Typography sx={surveyStyles.valueText}>
                {UsdFormatterDec.format(props.userInfo.Salary)}
              </Typography>
            </Box>
            {/* EMPLOYMENT STATUS SECTION */}
            <Box sx={surveyStyles.infoRow}>
              <Typography sx={surveyStyles.questionText}>
                Employment Status
              </Typography>
              <Typography sx={surveyStyles.valueText}>
                {props?.survey?.isEmployed ? 'Employed' : 'Not Employed'}
              </Typography>
            </Box>
            {/* DOB  SECTION */}
            <Box sx={surveyStyles.infoRow}>
              <Typography sx={surveyStyles.questionText}>
                Date of birth
              </Typography>
              <Typography sx={surveyStyles.valueText}>
                {props?.userInfo.DOB
                  ? formatDateObjMMDDYYYYDisplay(props?.userInfo.DOB)
                  : ''}
              </Typography>
            </Box>
            {/* FULLTIME PART TIME  SECTION */}
            {props.results?.name &&
              (props?.results?.name?.includes('IBT') ||
                props?.results?.name?.includes('Teamsters')) && (
                <Box sx={surveyStyles.infoRow}>
                  <Typography sx={surveyStyles.questionText}>
                    Are you a Full Time or Part Time employee?
                  </Typography>
                  <Typography sx={surveyStyles.valueText}>
                    {props?.survey?.isFullTime
                      ? 'Full Time'
                      : props?.survey?.isFullTime == false
                      ? 'Part Time'
                      : 'Full Time'}
                  </Typography>
                </Box>
              )}
            {/* ARE YOU MARRIED SECTION */}
            <Box sx={surveyStyles.infoRow}>
              <Typography sx={surveyStyles.questionText}>
                Are you married?
              </Typography>
              <Typography
                sx={surveyStyles.statusValue}
                className={props.survey.IsMarried ? 'positive' : ''}
              >
                {props.survey.IsMarried ? 'YES' : 'NO'}
              </Typography>
            </Box>
            {/* DO YOU HAVE DEPENDENTS SECTION */}
            <Box sx={surveyStyles.infoRow}>
              <Typography sx={surveyStyles.questionText}>
                Do you have dependents?
              </Typography>
              <Typography
                sx={surveyStyles.statusValue}
                className={props.survey.HaveDependents ? 'positive' : ''}
              >
                {props.survey.HaveDependents ? 'YES' : 'NO'}
              </Typography>
            </Box>
            {/* ARE YOU THE PRIMARY household income SECTION */}
            <Box sx={surveyStyles.infoRow}>
              <Typography sx={surveyStyles.questionText}>
                Are you the primary household income?
              </Typography>
              <Typography
                sx={surveyStyles.statusValue}
                className={props.survey.IsPrimary ? 'positive' : ''}
              >
                {props.survey.IsPrimary ? 'YES' : 'NO'}
              </Typography>
            </Box>
            {/* Do you have a mortgage SECTION? */}
            <Box sx={surveyStyles.infoRow}>
              <Typography sx={surveyStyles.questionText}>
                Do you have a mortgage?
              </Typography>
              <Typography
                sx={surveyStyles.statusValue}
                className={props.survey.Mortgage ? 'positive' : ''}
              >
                {props.survey.Mortgage ? 'YES' : 'NO'}
              </Typography>
            </Box>
            {/* ARE YOU A PILOT SECTION */}
            {props.results?.name &&
              (props?.results?.name?.includes('IBT') ||
                props?.results?.name?.includes('Teamsters')) && (
                <Box sx={surveyStyles.infoRow}>
                  <Typography sx={surveyStyles.questionText}>
                    Are you an Airline Pilot?
                  </Typography>
                  <Typography
                    sx={surveyStyles.statusValue}
                    className={props.survey.IsPilot ? 'positive' : ''}
                  >
                    {props.survey.IsPilot ? 'YES' : 'NO'}
                  </Typography>
                </Box>
              )}
            {/* ARE YOU A RAILWORKER SECTION */}
            {props.results?.name && props?.results?.name?.includes('ASTTU') && (
              <Box sx={surveyStyles.infoRow}>
                <Typography sx={surveyStyles.questionText}>
                  Are you a Railroad Union Member?
                </Typography>
                <Typography
                  sx={surveyStyles.statusValue}
                  className={props.survey.WorksInRail ? 'positive' : ''}
                >
                  {props.survey.WorksInRail ? 'YES' : 'NO'}
                </Typography>
              </Box>
            )}
            {/* END OF SECTION AFTER IT GETS CALCULATED */}
          </Box>
        </Grow>
      )}
    </>
  );
}
