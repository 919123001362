import React, { useState, useEffect } from 'react';

//Mui Components
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import { Typography, Box, TextField } from '@mui/material';

//Mui icons
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

// MUI Refactored Styles
const styles = {
  drawerPaper: {
    marginTop: '56px!important',
  },
  container: {
    width: '560px',
    padding: '19px 28px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  content: {
    paddingTop: '24px',
  },
  textField: {
    marginTop: '24px',
  },
  fullWidthButton: {
    marginTop: '24px',
  },
  resolvedButton: {
    marginRight: '16px',
  },
  unresolvedButton: {
    marginLeft: '16px',
  },
  questionText: {
    marginBottom: '16px',
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
  },
};

//Our Components
export default function SingleviewCloseTicketDrawer(props) {
  //Drawer Logic
  const handleDrawerClose = (e) => {
    props.setCloseTicketDrawerOpen(false);
  };

  const [resolved, setResolved] = useState(0);

  const [text, setText] = useState('');
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (text != '' && resolved != 0 && props.status == 1) {
      setDisabled(false);
    } else if (props.status == 2) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [text, resolved, props.status]);

  return (
    <>
      <Drawer
        PaperProps={{
          sx: styles.drawerPaper,
        }}
        hideBackdrop={true}
        anchor="right"
        open={props.closeTicketDrawerOpen}
        onClose={handleDrawerClose}
      >
        <Box sx={styles.container}>
          <Box sx={styles.header}>
            <Typography variant="h6" sx={styles.headerText}>
              {props.status === 2 ? 'Reopen' : 'Close'} Ticket
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          {props.status != 2 && (
            <Box sx={{ paddingTop: '24px' }}>
              <Typography sx={{ marginBottom: '16px' }} variant="body1">
                Is this member's issue resolved?
              </Typography>
              <Box sx={styles.flexRow}>
                <Button
                  fullWidth
                  sx={styles.resolvedButton}
                  variant={resolved === 1 ? 'contained' : 'outlined'}
                  onClick={(e) => setResolved(1)}
                >
                  YES
                </Button>
                <Button
                  fullWidth
                  sx={styles.unresolvedButton}
                  variant={resolved === 2 ? 'contained' : 'outlined'}
                  onClick={(e) => setResolved(2)}
                >
                  NO
                </Button>
              </Box>

              <TextField
                variant="outlined"
                multiline
                fullWidth
                placeholder="Description"
                value={text}
                onChange={(e) => setText(e.target.value)}
                sx={styles.textField}
                rows={4}
                InputProps={
                  {
                    // inputProps:{'data-uid':inputUid},
                    // disableUnderline: true,
                    // sx: { padding: '11px 15px', height: 'calc(20vh - 14px)', display: 'block', fontSize: 15, borderRadius: '30px', fontFamily: 'Archivo' }
                  }
                }
              />
            </Box>
          )}

          <Box sx={{}}>
            <Button
              onClick={(e) => props.closeOrReopenTicket(resolved, text)}
              disabled={disabled}
              variant="contained"
              fullWidth
              sx={styles.fullWidthButton}
            >
              {props.status === 2 ? 'Reopen' : 'Close'} Ticket
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
