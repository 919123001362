import { display, maxWidth } from '@mui/system';

const acDrawerSharedStyles = {
  drawerContainer: {
    maxWidth: '538px',
    height: '100vh',
  },
  componentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '506px',
    height: '100%',
  },
  bodyContainer: {
    padding: '10px 26px',
    width: '100%',
  },
  infoContainer: {
    width: '100%',
    padding: '18px 16px 10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    margin: '0 auto',
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '10px 0px',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '30px 0px 20px 0px',
  },
  editInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '15px 0px 20px 0px',
  },
  inputTextField: {
    marginBottom: '12px',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '8px 0px',
  },
  memberinfolinesView: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  sectiontitleView: {
    display: 'flex',
    alignItems: 'center',
    // marginBottom: '5px',
  },
  buttonContainerCancel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '16px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    marginTop: 'auto',
  },
  cancelButtonStyle: {
    width: '100%',
    textTransform: 'uppercase',
  },
  valueView: {
    fontSize: '16px',
  },
  editIconView: {
    marginLeft: '8px',
    fontSize: '24px',
    color: 'primary.main',
    cursor: 'pointer',
    '&:hover': {
      color: '#003d82',
    },
  },
  labelView: {
    fontSize: '16px',
  },
  priceView: {
    fontSize: '24px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: 'primary.main',
  },
  pendingChangesView: {
    margin: '0 0 18px 0',
  },
  laddbenefitAmountWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    margin: 0,
    padding: 0,
  },
  laddbenefitNameView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
  },
  laddbenefitAmountView: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    textAlign: 'right',
  },
  effectiveDateView: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  perMonthText: {
    fontSize: '14px',
    color: 'text.secondary',
    marginLeft: '4px',
  },
  offsetBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
    width: 'auto',
    maxWidth: '450px',
  },
  offsetLabel: {
    fontSize: '16px',
    flexShrink: 0,
    minWidth: '120px',
    textAlign: 'left',
    marginRight: '12px',
  },
  offsetValue: {
    fontSize: '16px',
    textAlign: 'right',
    marginLeft: 'auto',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
};

export default acDrawerSharedStyles;
