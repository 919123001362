import axios from 'axios';
import Settings from '../settings';
import authService, { authHeader } from './auth-service';
import { baseService } from './baseService';

class internalPermissionService extends baseService {
  // Returns all available permissions
  getAvailablePermissions() {
    const url = Settings.API_URL + 'permission';
    return this.get(url);
  }
  // Organizes Permissions by role. Shows state of if it is enabled for that role or not.
  getPermissionsByRole() {
    const url = Settings.API_URL + 'permission/role';
    return this.get(url);
  }
  // Returns an array of the permissions a user has
  getMyPermissions() {
    const url = Settings.API_URL + 'permission/me';
    return this.get(url);
  }
  addPermissionToRole(permissionId, roleId) {
    const url =
      Settings.API_URL + 'permission/' + permissionId + '/role/' + roleId;
    return this.put(url);
  }
  deletePermissionFromRole(permissionId, roleId) {
    const url =
      Settings.API_URL + 'permission/' + permissionId + '/role/' + roleId;
    return this.delete(url);
  }
}

const permissionService = new internalPermissionService();

export default permissionService;
