const activeCoverageStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '8px 18px',
  },
  planTitleRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: ' 16px 0px 10px 0px',
    justifyContent: 'space-between',
  },
  planTitle: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '.15px',
    color: 'primary.main',
  },
  planAmountContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  planAmount: {
    fontFamiily: 'Poppins',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '32px',
    color: 'primary.main',
  },
  perMonth: {
    fontFamily: 'Archivo',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '.17px',
    color: 'text.secondary',
    alignSelf: 'flex-end',
    paddingBottom: '6px',
  },
  planInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '40px',
    marginRight: '8px',
  },
  planInfoText: {
    marginLeft: '8px',
  },
  threeDotMenu: {
    iconButton: {
      padding: '0px',
      marginLeft: '12px',
      borderRadius: '50%',
    },
    MoreVertIcon: { color: 'text.secondary' },
  },
  editButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '20px 0px',
    justifyContent: 'flex-end',
  },
  saveAndCancelEditButtons: {
    fontSize: '14px',
    borderRadius: '8px',
    height: '40px',
    fontWeight: '500!important',
    marginLeft: '10px',
  },
  //EDITING COVERAGE
  editInputContainer: {
    width: '50%',
    maxWidth: '300px',
    // marginBottom: "10px",
  },
  selectDropDown: {
    height: '40px',
    borderRadius: '4px',
    padding: '10px 0px',
    width: '100%',
    '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
      border: '2px solid',
      borderColor: 'primary.main',
    },
    textAlign: 'left',
    marginBottom: '10px',
  },
  selectDownArrowIcon: {
    position: 'absolute',
    right: 7,
  },

  //WARNINGS - CANCEL OR MIDIFIED PLANS
  pendingChangesContainer: {
    // width: "100%",
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 5px 6px 0px',
  },
  pendingChangesChip: (type) => ({
    backgroundColor:
      type === 'cancel'
        ? 'background.notification.critical'
        : 'background.inProgressChip',
    color: type === 'cancel' ? 'error.main' : 'primary.inProgressChip',
    fontFamily: 'Archivo',
    margin: '5px 0px',
    fontWeight: '500',
    alignItems: 'center',
    fontSize: '13px',
  }),
};

export default activeCoverageStyles;
